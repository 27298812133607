<template>
<div id="namespace-tree">

    <div v-if="error" class="alert alert-danger">{{ error }}</div>
    <div class="text-right">
        <div v-if="!submit_loading">
            <button class="btn btn-secondary mr-1" :class="{'btn-sm text-sm': is_mobile}" @click="ready(false)">Cancel</button>
            <button class="btn btn-info text-bold" :class="{'btn-sm text-sm': is_mobile}"  @click="submit()" :disabled="!selected_folder">{{ action.startsWith('copy') ? 'Copy' : 'Move' }} to {{selected_folder ? selected_folder.name : '...'}}</button>
        </div>
        <i v-else class="ion-load-c spin"></i>
    </div>

    <div class="dd mt-2 mb-2">
        <select class="form-control mb-3" v-if="!is_client_space_client && is_move_operation" v-model="selected_namespace">
            <option :value="'team_files'">Team Files</option>
            <option :value="'admin_folder'" v-if="is_user_admin">Admin Folder</option>
            <optgroup label="Client Spaces" v-if="my_client_spaces">
                <option v-for="space in my_client_spaces.filter(s => { return !s.is_archived })" :key="space.key" :value="space">{{ space.client_name }}</option>
            </optgroup>
        </select>
        
        <namespace-tree-row :children="namespace" :clicked="tree_folder_selected"></namespace-tree-row>
    </div>

    <div class="text-right">
        <div v-if="!submit_loading">
            <button class="btn btn-secondary mr-1" :class="{'btn-sm text-sm': is_mobile}" @click="ready(false)">Cancel</button>
            <button class="btn btn-info text-bold" :class="{'btn-sm text-sm': is_mobile}" @click="submit()" :disabled="!selected_folder">{{ action.startsWith('copy') ? 'Copy' : 'Move' }} to {{selected_folder ? 'to '+selected_folder.name : '...'}}</button>
        </div>
        <i v-else class="ion-load-c spin"></i>
    </div>
    <div v-if="error" class="alert alert-danger mt-2">{{ error }}</div>
</div>
</template>
<script>

import { NamespaceService } from '@/services/namespace-service.js'
import { Utils } from '@/helpers/utils.js'

import namespaceTreeRow from "@/components/namespace-tree-row.vue";
import { ClientSpacesService } from '@/services/client_spaces-service';

export default {
    // folderSelected: callback that has to be called when the copy/move was carried out (param: true) or the action is failed/cancelled (param: false)
    // action: 'copy_file', 'move_file' or 'move_folder'
    // targetFile: The file that's being moved
    props: ["ready", "action", "targetFile", "is_user_admin", "is_client_space_client"],
    components: {
        namespaceTreeRow
    },
    data(){
        return {
            selected_namespace: false,
            my_client_spaces: null,

            error: false,
            namespace: null,
            ns_type: null,
            ns_key: null,

            selected_folder: null,


            submit_loading: false
        }
    },

    computed: {
        is_mobile(){
            return Utils.is_mobile()
        },

        is_move_operation(){
            return this.action == "move_file" || this.action == "move_folder"
        }
    },

    watch: {
        selected_namespace(){
            if(!this.selected_namespace){
                return 
            }
            
            if(this.selected_namespace.client_name !== undefined){
                this.load_namespace(NamespaceService.NS_TYPE_CLIENT_SPACE, this.selected_namespace.key)
            }
            else if(this.selected_namespace === "team_files"){
                this.load_namespace(NamespaceService.NS_TYPE_DEFAULT)
            }
            else if(this.selected_namespace === "admin_folder"){
                this.load_namespace(NamespaceService.NS_TYPE_ADMIN_FOLDER)
            }
        },

        my_client_spaces(){
            if(!this.my_client_spaces){
                return
            }
            // When we are in a client space, 'my_client_spaces' is populated too late 
            if(!this.selected_namespace && NamespaceService.ns_type == NamespaceService.NS_TYPE_CLIENT_SPACE){
                this.selected_namespace = this.my_client_spaces.find(space => {
                    return space.key == NamespaceService.ns_key
                })
            }
        }
    },

    created(){

        if(!this.is_client_space_client){
            ClientSpacesService.list().then(res => {
                this.my_client_spaces = res.body
            }).catch(err => {
                console.error("Failed to load client spaces")
            })
        }
        
    },

    mounted(){
        // Load the current namespace contents
        this.load_namespace()

        // Set the selected namespace
        switch(NamespaceService.ns_type){
            case NamespaceService.NS_TYPE_DEFAULT:
                this.selected_namespace = 'team_files';
                break;
            case NamespaceService.NS_TYPE_ADMIN_FOLDER:
                this.selected_namespace = 'admin_folder';
                break;
            case NamespaceService.NS_TYPE_CLIENT_SPACE:
                this.selected_namespace = this.my_client_spaces && this.my_client_spaces.find(space => {
                    return space.key == NamespaceService.ns_key
                })
                break;
            default:
                console.error("Unknown ns type: "+ NamespaceService.ns_type)
        }
    },

    methods: {

        load_namespace(ns_type, ns_key){

            NamespaceService.get_all_namespace(true, ns_type, ns_key).then(res => {
                this.error = false

                let folders = res.body
                    .filter(en => { return en.entity_type === NamespaceService.ENTITY_TYPE_FOLDER })


                folders.forEach(f => {
                    f.color = Utils.string_to_color(f.name)
                })

                const moved_folder_id = this.action === 'move_folder' ? this.targetFile.id : null
                
                this.ns_type = ns_type
                this.ns_key = ns_key

                this.namespace = [
                    {
                        name: "HOME",
                        id: -1,
                        parent_id: null,
                        children: this.flatToHierarchy(folders, moved_folder_id)
                    }
                ]
            })
            .catch(err => {
                console.error(err)
                if(err.status && err.body){
                    this.error = "Failed to list folders: " + err.body
                }
            })
        },

        submit(){
            if(!this.selected_folder || this.targetFile == undefined){ return }
            this.submit_loading = true
            this.error = false
            var promise = null
            switch(this.action){
                case 'copy_file':
                    promise = NamespaceService.copy_file(this.targetFile.id, this.selected_folder.id)
                    break;
                case 'move_file':
                    if(this.is_client_space_client){
                        promise = NamespaceService.move_file(this.targetFile.id, this.selected_folder.id)
                    }
                    else{
                        promise = NamespaceService.move_file(this.targetFile.id, this.selected_folder.id, this.ns_type, this.ns_key)
                    }
                    break;
                case 'move_folder':
                    if(this.is_client_space_client){
                        promise = NamespaceService.move_folder(this.targetFile.id, this.selected_folder.id)
                    }
                    else{
                        promise = NamespaceService.move_folder(this.targetFile.id, this.selected_folder.id, this.ns_type, this.ns_key)
                    }
                    break;
                default:
                    this.error = "Unsupported action: '" + this.action + "'"
                    return;
            }
            promise.then(res => {

                if(this.action == 'copy_file'){
                    // Create a new file version for the new file
                    const new_file_id = res.body.id
                    if(!new_file_id){ console.error("No new file ID"); return }

                    this.selected_folder = null
                    this.ready({
                        action: this.action,
                        targetEntity: this.targetFile,
                        success: true
                    })

                }
                else{
                    // Move file or move folder
                    this.$set(this.targetFile, "parent_id", this.selected_folder.id)

                    this.selected_folder = null
                    this.ready({
                        action: this.action,
                        targetEntity: this.targetFile,
                        success: true
                    })
                }

            }).catch(err => {
                console.error(err)
                this.submit_loading = false
                if(err.body && err.body.message){
                    this.error = "Error: " + err.body.message
                }
                else{
                    this.error = "Error"
                }

            })

        },

        flatToHierarchy: function(flat, moved_folder_id) {
            /* Source: https://stackoverflow.com/questions/31383869/converting-flat-structure-to-hierarchical */

            var roots = [] // things without parent

            // make them accessible by guid on this map
            var all = {}

            flat.forEach(function(item) {
                all[item.id] = item
            })

            // connect childrens to its parent, and split roots apart
            Object.keys(all).forEach(function (id) {
                var item = all[id]

                if(item.id === moved_folder_id){
                    // do not add the moved folder to the tree (so it cannot be moved onto itself)
                    return
                }

                if (item.parent_id === null) {
                    // root
                    roots.push(item)
                } else if (item.parent_id in all) {
                    var p = all[item.parent_id]
                    if (!('children' in p)) {
                        p.children = []
                    }
                    p.children.push(item)
                    p.children.sort( (_a, _b) => {
                        let a = _a.name;
                        let b = _b.name;
                        if(typeof a == "string"){ a = a.toLowerCase(); b = b.toLowerCase(); }
                        if(a < b){ return -1; }
                        if(a > b){ return 1 }
                        return 0;
                    })
                }
            })

            roots.sort( (_a, _b) => {
                    let a = _a.name;
                    let b = _b.name;
                    if(typeof a == "string"){ a = a.toLowerCase(); b = b.toLowerCase(); }
                    if(a < b){ return -1; }
                    if(a > b){ return 1 }
                    return 0;
                })
            // done!
            return roots
        },


        tree_folder_selected: function(folder){
            // cache old val
            const is_selected = folder.selected
            // unselect all folders
            this.unselect_folder(this.namespace[0])
            // invert original value
            folder.selected = !is_selected
            // update 'selected_folder'
            this.selected_folder = folder.selected ? folder : null
        },

        unselect_folder: function(folder){
            this.$set(folder, 'selected', false)
            if(folder.children){
                folder.children.forEach(f => {
                    this.unselect_folder(f)
                })
            }
        }

    }


}
</script>
<style scoped>
    .dd{
        margin: 0px auto;
        font-size: inherit;
        line-height: inherit;
    }
</style>
