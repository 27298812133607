<template>
<div v-if="user && !user.is_admin" class="container pt-5">
    <admin-warning-box></admin-warning-box>
</div>
<section v-else class="section-container">
<div class="container container-lg" id="cloud-selector">

    <div class="row" v-if="false /*first_run*/">
        <div class="col-2 hidden-sm-down"></div>
        <div class="col-12 col-lg-8">
            <div class="cardbox text-white bg-gradient-success b0">
                  <div class="cardbox-body text-center">
                    <h1>Welcome to SkyFlok!</h1>
                    <p class="text-bold" style="font-size: 1.1em">
                        Our unique patented technology allows you to choose where your data is stored.
                        <br/>Keep it close to you or spread it around, it’s up to you!
                    </p>
                    <hr style="width: 60%; background: white"/>
                    <p>
                        <ol style="margin-left: 5%; margin-right: 5%; text-align: justify; font-size:1.1em" class="text-bold">
                            <li class="mb-2">Use the search box to find and add every city on the map where your Team Members are located, or your files are regularly accessed from.</li>
                            <li>Confirm the suggested cloud configuration or customize it as you wish.</li>
                        </ol>
                    </p>
                  </div>
                </div>
        </div>
    </div>

	<div v-if="customer_setup_success" class="row mt-3">
		<div class="col-2 hidden-md-down"></div>
		<div class="col-12 col-xl-8">
			<div class="jumbotron bg-gradient-success text-white text-center" style="border:none">
				<div class="container">
					<h3 style="line-height: 1.3em">Cloud locations are ready, you can start using your storage!</h3>
                    <div class="mt-4">
                        <router-link to="/files" class="btn btn-secondary text-bold">Go to Team Files</router-link>
                    </div>
				</div>
			</div>
		</div>
	</div>

	<div v-if="customer_setup_loading" class="row">
		<div class="col-2 hidden-sm-down"></div>
		<div class="col-12 col-xl-8">
			<div class="jumbotron bg-gradient-primary text-white mt-5">
				
					<h3>Setting up your cloud locations...</h3>
                    <div class="mb-4">Please don't refresh the page</div>

                    <div v-for="backend in selected_backends" :key="backend.id" class="my-2 d-flex align-items-center justify-content-start text-bold">
                        <i class="loader mr-2" style="margin: inherit"></i>
                        <div>
                            {{ backend.provider.name }} {{backend.name}}, {{backend.country }}
                        </div>
                        <img :src="'assets/img/flags/'+backend.countrycode+'.png'" class="ml-2"> 
                    </div>
			</div>
		</div>
	</div>

	<div v-if="!customer_setup_loading">
		<!-- Map -->
		<div class="row" v-if="false" id="map_container">
			<div class="col-12">
				<div class="cardbox">
					<div class="cardbox-body">
                        <div class="alert alert-info mt-0" v-if="!first_run">
							<i class="icon ion-information-circled mr-3"></i>
							<div>
								Add all cities where your Team Members are located, or your files are regularly accessed from.
								<br/>You can select as many locations as you want, we'll suggest a cloud setup that fits them best
							</div>
						</div>

                        <img v-if="first_run" src="@/assets/img/arrow_down.png" id="map_input_arrow" class="hidden-md-down">

                        <div class="hidden-md-down text-right mb-2">
                            <!--
                            <div v-if="geoip_location" class="mr-3 d-inline-block">
                                <div class="">
                                    Add <b><a href="#" class="text-underline" @click.prevent="add_suggested_city()">{{ geoip_location.formatted_address }}</a></b> ?
                                </div>
                            </div>
                            -->

                            <small>Zoom to:</small>
                            <button v-for="loc in map.zoomable_regions" @click="move_map(loc)" :key="loc.name" class="btn btn-secondary btn-gradient btn-sm text-sm ml-1" type="button">{{ loc.name }}</button>
                        </div>

						<div id="map" v-if="backends && backends.length > 0">
							<gmap-map
								style="width: 100%; height: 100%;"
								:center="map.center"
								:zoom="map.zoom"
								:options="{
									styles: map.style,
									minZoom: 2,
                                    maxZoom: 7,
									scaleControl: false,
									fullscreenControl: false,
									streetViewControl: false,
									mapTypeControl: false
								}"
								ref="map">

								<gmap-info-window :options="infoOptions" :position="{ lat: map.center.lat, lng: map.center.lng }" :opened="infoWinOpen = false" @closeclick="infoWinOpen=false">
									info window content
								</gmap-info-window>


								<!-- Backend markers -->
                                <template v-if="backends && backend.mapmarker">
								<gmap-marker
									:key="backend.id"
									v-for="backend in backends"
                                    
									:title="backend.mapmarker ? backend.mapmarker.title + (backend.supported ? '' : ' (coming soon!)') : ''"
									:position="backend.mapmarker.location"
									:icon="backend.mapmarker"
									:clickable="true"
									:draggable="false"
									@mouseover="backend.hovered = true"
									@mouseout="backend.hovered = false"
                                    :opacity="(backend.hovered === true || selected_backends.indexOf(backend) >= 0) ? 1 : .8"
									@click="marker_clicked"
								/>
                                </template>

								<!-- user location markers -->
								<gmap-marker
									:key="user_loc.id"
									v-for="user_loc in user_locations"
									:title="user_loc.name"
									:position="user_loc.location"
									:icon="user_loc.marker_icon"
									:clickable="true"
									:draggable="false"
									@click="marker_clicked"
								></gmap-marker>

								<gmap-polyline
									v-for="line in map_lines"
									:key="line.id"
									:path="line.path"
									:options="{
										geodesic: true,
										strokeOpacity: .75,
										strokeColor: line.color
									}"
									:editable="false"
									:draggable="false"
									:deepWatch="false"
								></gmap-polyline>

							</gmap-map>

							<div id="map_searchbox_container">
                                <gmap-autocomplete
                                    id="searchbox_input"
                                    class="form-control form-control-lg map_searchbox"
                                    v-model="searchbox_model"
                                    placeholder="Add every city where your team members are"
                                    :options="{ types: ['(cities)'] }"
                                    :class="{'heavy_highlight': first_run}"
                                    @place_changed="searchbox_place_selected"
                                ></gmap-autocomplete>

                                <div class="text-center" style="margin-top: -15px" v-if="geoip_location">
                                    <div class="d-inline-block p-2 bg-white" style="border-radius: 5px">
                                        Add <b><a href="#" class="text-underline" @click.prevent="add_suggested_city()">{{ geoip_location.formatted_address }}</a></b> ?
                                        <a class="text-muted ml-1" href="#" @click.prevent="geoip_location = null"><em class="ion-close-round"></em></a>
                                    </div>
                                </div>


							</div>

						</div>



					</div>
				</div>
			</div>
		</div>

        <!-- User locations -->
        <div class="row" v-if="false /* !customize_mode */" id="user_locations_row">
            <div class="col-12 col-sm-6 col-md-4 col-xl-3"  v-for="(location, index) in user_locations" :key="location.id">
            <div class="cardbox">
                <div class="cardbox-body">

                    <div class="d-flex justify-content-start align-items-center">
                        <div class="mr-2">
                            <img :src="'assets/img/flags/'+location.country.code+'.png'">
                        </div>
                        <div>
                            <p class="mb-0">{{ location.name }}</p>
                        </div>
                        <div class="ml-auto"><a class="text-muted" href="#" @click="remove_user_location(index)"><em class="ion-close-round"></em></a></div>
                    </div>

                </div>
            </div>
            </div>
        </div>

        <!-- Service quality table -->
		<div class="row hidden-md-down" v-if="false && customize_mode && user_locations.length > 0">
			<div class="col-12">
				<div class="user_locations">

					<h3>Your locations</h3>
					<div class="cardbox">
						<div>
							<table class="table user-locations-table">
								<thead>
									<th>Location</th>
									<th v-for="backend in selected_backends" :key="backend.id">{{ backend.provider.name + ' ' + backend.name + ", " + backend.country }}</th>
									<!--<th>Estimated service quality</th>-->
									<th class="actions" style="width: 80px"></th>
								</thead>
								<tbody>
									<tr v-for="(location, index) in user_locations" :key="location.id">
										<td><img v-if="location.country != null" :src="'assets/img/flags/'+location.country.code+'.png'" style="margin-right: 5px" /> {{ location.name }}</td>
										<td v-for="(d, index) in location.distances" :key="index" :style="{color: classify_distance(d).color}">{{d | km}}</td>
										<!--
										<td>
											<div v-service-quality="location.avg_dist"></div>
										</td>
										-->
										<td class="actions">
											<button class="btn btn-outline-danger file-action" type="button" @click="remove_user_location(index)" title="Remove this location">
												<i class="icon ion-close"></i>
											</button>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>


				</div>
			</div>
		</div>

		<!-- Cloud configuration -->
		<div class="row" v-if="user_locations.length > 0">
			<div class="col-12">

                <h3 class="text-center">Welcome to SkyFlok!</h3>
                <div class="d-flex justify-content-center mt-3 mx-3 text-bold">
                    <span style="max-width: 40em; text-align: justify">
                    SkyFlok distributes your files to multiple locations across different cloud providers,
                    so you are protected from service outages and prying eyes.
                    <br/><br/>
                    Our unique patented technology allows you to choose where your data is stored.
                    Keep it close to you or spread it around, it’s up to you!
                    </span>
                </div>

                <!--
                <div class="d-flex align-items-center justify-content-center mb-2">
                    <span>This set of cloud storages provide the fastest and most reliable service to your team <b>in {{ user_locations[0].name }}</b></span> <img :src="'assets/img/flags/'+user_locations[0].country.code.toUpperCase()+'.png'" class="ml-1">
                    <a href="#" class="ml-1">Not here?</a>
                </div>
                -->

				<div class="mt-4">
                    <h3 class="d-inline-block mr-1">Recommended locations</h3>
                    <span :class="{'text-sm': is_mobile}">(based on your IP location: <b>{{ user_locations[0].name }}</b> <img :src="'assets/img/flags/'+user_locations[0].country.code.toUpperCase()+'.png'" class="ml-1" style="vertical-align: middle">)</span>
                </div>

                <div class="row">
                    <div class="col-6 col-sm-6 col-md-4 mb-4"  v-for="i in range(max_locations)" :key="i" :class="{'px-0': is_mobile}">
                        <div class="cardbox selected-backend-box fh mb-0" :class="{'mx-2': is_mobile, 'mx-4 mt-2': !is_mobile}">
                            <div class="cardbox-body" v-if="selected_backends[i]">
                                <div class="selected_backend">
                                    <div class="text-center mb-2">
                                        <img
                                            v-if="selected_backends[i].provider"
                                            class="cloud-logo"
                                            :src="'assets/img/cloud_logos/350X150/'+selected_backends[i].provider.logo_path"
                                            :height="is_mobile ? 35 : 70"
                                        />
                                    </div>
                                    <div class="mb-2">
                                        <b>Provider: </b>
                                        <br/>
                                        <span>{{ selected_backends[i].provider.service_name }}
                                            <a
                                                v-if="selected_backends[i].provider.cloud_platform_url != ''"
                                                :href="selected_backends[i].provider.cloud_platform_url"
                                                target="_BLANK"
                                                rel="noopener"
                                                class="btn btn-sm btn-secondary ml-1"
                                                role="button"
                                                title="Open platform website in a new tab"
                                            ><i class="ion-android-open"></i></a>
                                        </span>
                                    </div>
                                    <div class="mb-2">
                                        <b>Location: </b>
                                        <div>
                                            <img v-if="selected_backends[i].countrycode && selected_backends[i].countrycode != ''" :src="'assets/img/flags/'+selected_backends[i].countrycode+'.png'" class="ml-1" />
                                            {{ selected_backends[i].name }}, {{ selected_backends[i].country }}
                                        </div>
                                        <div class="text-center mt-3">
                                            <gdpr-badge :is_gdpr="selected_backends[i].is_gdpr" data-container="body" data-toggle="popover" data-placement="top" data-html="true" data-trigger="hover" 
                                                data-content="This cloud location is compliant with the EU General Data Protection Regulation (GDPR).">></gdpr-badge>
                                            
                                        </div>
                                    </div>

                                    <div class="fw text-center" v-if="customize_mode && company_buckets.length == 0">
                                        <button class="btn btn-outline-danger" role="button" v-on:click="remove_backend(i)" :class="{'btn-sm': is_mobile}">
                                            <i class="ion-close-round mr-2"></i> Remove
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div class="selected-backend-empty" v-if="!selected_backends[i]" role="button">
                                <a href="#all-cloud-locations"><i class="ion-plus-round text-gray-light"></i></a>
                            </div>
                        </div>
                    </div>
				</div>

                <!-- 'Use this config' button -->
                <div class="row mb-4">

                    <div v-if="company_buckets.length > 0" class="col-12 text-center">
                        <i>It's currently not possible to change cloud configuration</i>
                    </div>
                    <div v-else class="col-12 text-center">
                        <button
                                class="btn btn-success btn-gradient text-bold p-3"
                                :class="{'btn-xl px-5 mild_highlight': !is_mobile}"
                                role="button"
                                :disabled="!(selected_backends && selected_backends.length >= min_locations && selected_backends.length <= max_locations)"
                                :title="selected_backends.length < min_locations ? ('At least ' + min_locations + ' locations are required') : ''"
                                @click="company_setup(selected_backends)"
                                >Set up my Team with these locations!
                        </button>
                        <div v-if="!selected_backends || selected_backends.length < min_locations" class="text-danger mt-2">At least {{ min_locations }} locations are required.</div>
                        <div v-if="selected_backends.length > max_locations" class="text-danger mt-2">Maximum {{ max_locations }} locations are allowed.</div>
                    </div>
                </div>

                <div v-if="!customize_mode" class="text-center my-3" style="">
                    <a href="#" @click.prevent="customize_mode = true">Customize data locations</a>
                </div>
                <!--
                <div v-if="!no_ip_location" class="my-3 text-center text-gray-light text-sm">
                    Cloud Configuration recommendation is based on your IP location.
                </div>
                -->
                
            </div>
		</div>

        <div class="row" v-if="no_ip_location && user_locations.length == 0">
            <div class="col-2 hidden-sm-down"></div>
            <div class="col-12 col-lg-8">
                <div class="cardbox text-white bg-gradient-success b0">
                    <div class="cardbox-body text-center">
                        <h1>Welcome to SkyFlok!</h1>
                        <p class="text-bold" style="font-size: 1.1em">
                            Our unique patented technology allows you to choose where your data is stored.
                            <br/>Keep it close to you or spread it around, it’s up to you!

                        </p>

                        <hr style="width: 60%; background: white"/>
                        <p class="text-bold text-italic">
                            Please set where your team is located, so we can calculate<br/>the best combination of storage locations!
                            <!--
                            <ol style="margin-left: 5%; margin-right: 5%; text-align: justify; font-size:1.1em" class="text-bold">
                                <li class="mb-2">Add the city where your Team Members are located, or your files are regularly accessed from.</li>
                                <li>Confirm the suggested cloud configuration or customize it as you wish.</li>
                            </ol>
                            -->
                        </p>
                    </div>
                </div>

                <div class="mt-3 text-center">
                    <h3 class="mb-3">Where is your team located?</h3>
                    <gmap-autocomplete
                        id="searchbox_input"
                        class="form-control form-control-lg map_searchbox mild_highlight"
                        v-model="searchbox_model"
                        placeholder="Search city..."
                        :options="{ types: ['(cities)'] }"
                        @place_changed="searchbox_place_selected"
                    ></gmap-autocomplete>
                </div>

            </div>
        </div>


		<!-- All cloud locations -->
		<div class="row mt-4" v-if="user_locations.length > 0 && customize_mode">
			<div class="col-12" id="all-cloud-locations">
				<h3>All Locations <small>({{ unused_backends.length }})</small></h3>

				<div class="cardbox">
					<div class="cardbox-body">

						<!-- Filters -->
						<!--
						<div class="row" style="margin: 20px 0px">
							<div class="col-3 text-center">FILTER 1</div>
							<div class="col-3 text-center">FILTER 2</div>
							<div class="col-3 text-center">FILTER 3</div>
							<div class="col-3 text-center">FILTER 4</div>
						</div>
						-->
                        <!--
                        <div class="float-left hidden-md-down" style="margin: 20px 0px">
                            <div class="d-flex align-items-center justify-items-center">
                            <span class="mr-2">Show only available locations:</span><button class="btn btn-sm text-sm" :class="{'btn-outline-secondary': !backends_list_filters.hide_unsupported, 'btn-primary': backends_list_filters.hide_unsupported}" @click="backends_list_filters.hide_unsupported = !backends_list_filters.hide_unsupported">
                                {{ backends_list_filters.hide_unsupported ? 'ON' : 'OFF' }}
                            </button>
                            </div>
                        </div>
                        -->

						<div class="pagination-container">
							<ul class="pagination">
								<li class="page-item">
                                    <a class="btn btn-sm btn-primary btn-flat" href="#"
                                    :class="{'btn-secondary disabled': backends_list_filters.current_page === 1}"
                                    :disabled="backends_list_filters.current_page === 1"
                                    @click.prevent="backends_list_filters.current_page > 1 ? --backends_list_filters.current_page : ''"><i class="ion-chevron-left"></i></a></li>

								<li v-for="page in backends_list_page_numbers" :key="page" class="page-item"  :class="{'active': page === backends_list_filters.current_page}" @click.prevent="backends_list_filters.current_page = page">
									<a class="btn btn-sm btn-primary" :class="{'btn-flat': page !== backends_list_filters.current_page}" href="#">{{ page }}</a>
								</li>
								<li class="page-item">
                                    <a
                                        class="btn btn-sm btn-primary btn-flat" href="#"
                                        :class="{'btn-secondary disabled': backends_list_filters.current_page === backends_list_last_page_idx}"
                                        :disabled="backends_list_filters.current_page === backends_list_last_page_idx"
                                        @click.prevent="backends_list_filters.current_page >= backends_list_last_page_idx ? false : ++backends_list_filters.current_page"
                                    >
                                    <i class="ion-chevron-right"></i></a>
                                </li>
							</ul>
						</div>

						<table class="table table-hover" id="backends_table">

                            <thead>
								<th class="hidden-sm-down" @click="backends_sort_change('provider_name')" role="button">Cloud Provider <small v-if="backends_list_filters.orderby == 'provider_name'"><i v-if="!backends_list_filters.orderby_desc" class="ion-chevron-up"></i><i v-if="backends_list_filters.orderby_desc" class="ion-chevron-down"></i></small></th>
								<th class="hidden-sm-down" @click="backends_sort_change('name')" role="button">Location <small v-if="backends_list_filters.orderby == 'name'"><i v-if="!backends_list_filters.orderby_desc" class="ion-chevron-up"></i><i v-if="backends_list_filters.orderby_desc" class="ion-chevron-down"></i></small></th>
                                <th class="hidden-sm-up">Data Location</th>
                                <!--
                                <th class="hidden-sm-down" @click="backends_sort_change('avg_dist')" role="button">Distance <i class="mr-2 icon ion-help-circled text-gray-light" title="Average distance from your user locations. Smaller distance results in faster access of your files."></i><small v-if="backends_list_filters.orderby == 'avg_dist'"><i v-if="!backends_list_filters.orderby_desc" class="ion-chevron-up"></i><i v-if="backends_list_filters.orderby_desc" class="ion-chevron-down"></i></small></th>
                                <th>Complience</th>
                                <th @click="backends_sort_change('provider_jurisdiction')" role="button">Jurisdiction <span v-if="backends_list_filters.orderby == 'provider_jurisdiction'"><i v-if="!backends_list_filters.orderby_desc" class="ion-chevron-up"></i><i v-if="backends_list_filters.orderby_desc" class="ion-chevron-down"></i></span></th>
								<th @click="backends_sort_change('latency')" role="button">Estimated latency <span v-if="backends_list_filters.orderby == 'latency'"><i v-if="!backends_list_filters.orderby_desc" class="ion-chevron-up"></i><i v-if="backends_list_filters.orderby_desc" class="ion-chevron-down"></i></span></th>
								<th @click="backends_sort_change('price')" role="button">Price level <span v-if="backends_list_filters.orderby == 'price'"><i v-if="!backends_list_filters.orderby_desc" class="ion-chevron-up"></i><i v-if="backends_list_filters.orderby_desc" class="ion-chevron-down"></i></span></th>
								-->
                                <th class="hidden-sm-down">GDPR</th>
								<th v-if="company_buckets.length == 0" class="actions text-right width-0" :class="{'text-muted': selected_backends.length == 6}">Add</th>
							</thead>

							<tbody>
								<tr v-for="backend in unused_backends_paginated" :key="backend.id">
									<td class="hidden-sm-down">
                                        <div class="d-flex align-items-center">
                                        <div class="mr-2 hidden-sm-down">
                                            <img v-if="backend.provider" :src="'assets/img/cloud_logos/150h/' + backend.provider.logo_path" style="max-width: 120px; max-height: 25px" />
                                        </div>
                                        <div>
                                            <div>{{ backend.provider.service_name }}</div>
                                            <div v-if="!backend.supported" class="badge badge-success">Coming Soon!</div>
                                        </div>
                                        </div>
                                    </td>
									<td class="hidden-sm-down">
                                        <div class="d-flex align-items-center">
                                            <img v-if="backend.countrycode && backend.countrycode != ''" :src="'assets/img/flags/'+backend.countrycode+'.png'" height="20" />
										    <div class="ml-2">
                                                {{ backend.name }}, {{ backend.country }}
                                            </div>
                                        </div>
									</td>
                                    <td class="hidden-sm-up">
                                        <!-- Provider + location on mobile  -->
                                        {{ backend.provider.service_name }}<br/>
                                        <img v-if="backend.countrycode && backend.countrycode != ''" :src="'assets/img/flags/'+backend.countrycode+'.png'" height="20" class="mr-1" />
                                        {{ backend.name }}, {{ backend.country }}

                                        <div>
                                            <gdpr-badge :is_gdpr="backend.is_gdpr"></gdpr-badge>
                                        </div>
                                    </td>
                                    <!--
                                    <td  class="hidden-sm-down"><span :style="{color: classify_distance(backend.avg_dist).color}">{{ backend.avg_dist | km }}</span></td>
									-->
                                    <td class="hidden-sm-down">
                                        <gdpr-badge :is_gdpr="backend.is_gdpr"></gdpr-badge>
									</td>

									<!--

									<td>
										<div v-latency-indicator="backend.avg_dist"></div>
									</td>
									<td>
										<div v-price-level-indicator="backend.special_price"></div>
									</td>
									-->
									<td v-if="company_buckets.length == 0" class="actions text-right" >

										<button 
                                            v-if="selected_backends.length < max_locations" 
                                            :disabled="!backend.supported" 
                                            :title="backend.supported ? 'Use this location' : 'This location is not supported yet.' " 
                                            class="btn" 
                                            :class="{'btn-outline-primary': backend.supported, 'btn-outline-secondary': !backend.supported, 'btn-sm': is_mobile }" 
                                            type="button" 
                                            @click="add_backend(backend)"
                                        >
											<i class="icon ion-plus-round"></i>
										</button>

                                        <button v-else @click="show_error('To add a new location, first you need to remove one')" title="Remove a location first" class="btn btn-outline-secondary" :class="{'btn-sm': is_mobile}">
                                            <i class="icon ion-plus-round"></i>
                                        </button>
									</td>
								</tr>
							</tbody>
						</table>

						<div class="row pagination-container">
							<ul class="pagination">
								<li class="page-item">
                                    <a class="btn btn-sm btn-primary btn-flat" href="#"
                                    :class="{'btn-secondary disabled': backends_list_filters.current_page === 1}"
                                    :disabled="backends_list_filters.current_page === 1"
                                    @click.prevent="backends_list_filters.current_page > 1 ? --backends_list_filters.current_page : ''"><i class="ion-chevron-left"></i></a></li>

								<li v-for="page in backends_list_page_numbers" :key="page" class="page-item"  :class="{'active': page === backends_list_filters.current_page}" @click.prevent="backends_list_filters.current_page = page">
									<a class="btn btn-sm btn-primary" :class="{'btn-flat': page !== backends_list_filters.current_page}" href="#">{{ page }}</a>
								</li>
								<li class="page-item">
                                    <a
                                        class="btn btn-sm btn-primary btn-flat" href="#"
                                        :class="{'btn-secondary disabled': backends_list_filters.current_page === backends_list_last_page_idx}"
                                        :disabled="backends_list_filters.current_page === backends_list_last_page_idx"
                                        @click.prevent="backends_list_filters.current_page >= backends_list_last_page_idx ? false : ++backends_list_filters.current_page"
                                    >
                                    <i class="ion-chevron-right"></i></a>
                                </li>
							</ul>
						</div>

					</div>
				</div>
			</div>
		</div>

	</div>

</div>
</section>
</template>

<script>


const google = null

import { CloudProviderService, StorageLocationService, MapMarkerService, StorageBackendService } from '@/services/storage_backend-service.js'
import { UserService } from '@/services/user-service.js'
import { AuthService } from '@/services/auth-service.js'
import { map_style } from '@/helpers/constants.js'
import { Utils } from '@/helpers/utils.js'
import { bus, events } from '@/helpers/event_bus.js'

import { get_distance, get_best_backends } from '@/helpers/geo_scripts.js'
import adminWarningBox from '@/components/admin_feature_warning_box.vue'
import gdprBadge from '@/components/gdpr_badge'

import { countrycodes } from '@//assets/static/countrycodes.js'

const zoomable_regions = [
    {
        name: "Earth",
        center: { lat: 25.0, lng: 0 },
        zoom: 2
    },
    {
        name: "Europe",
        center: { lat: 50.06419173665908, lng: 11.77734375 },
        zoom: 5
    },
    {
        name: "USA",
        center: { lat: 42.065606754057136, lng: -96.74560546875 },
        zoom: 4
    },
    {
        name: "South America",
        center: { lat: -17.350638376048856, lng: -58.77685546875 },
        zoom: 4
    },
    {
        name: "Asia",
        center: { lat: 22.350075806124863, lng: 98.5693359375 },
        zoom: 4
    },
    {
        name: "Australia",
        center: { lat: -27.916766641249065, lng: 136.2744140625 },
        zoom: 4
    }
]

export default {

	props: ['user'],
    components: { 
        adminWarningBox,
        gdprBadge
    },
    data() {
        return {
			company_buckets: [],

            providers: [],
            backends: [],

            map: {
                center: { lat: 30.0, lng: 0.0 },
                zoom: 2,
                style: map_style,
                zoomable_regions: zoomable_regions
            },
            map_lines: [],
            midpoint: {},

            coding_config: {
                symbols: 5,
                redundant: 1
            },

            searchbox_place: null,
            geoip_location: null,
            user_locations: [],
            selected_backends: [],

            backends_list_filters:{
                items_per_page: 10,
                current_page: 1,
                orderby: "avg_dist",
                orderby_desc: false,
                hide_unsupported: true
			},

			customer_setup_loading: false,
            customer_setup_success: false,

            customize_mode: false,
            no_ip_location: false
        }
    },

    watch: {

        user_locations_length(){
            if (this.user_locations.length === 0) {
                this.selected_backends.splice(0)
                this.map_lines = []
                return;
            }

            // For each backend, calculate distance from the midpoint of the user locations
            let lats = [], lngs = [];
            for (var i = 0; i < this.user_locations.length; i++) {
                var loc = this.user_locations[i]
                lats.push(loc.location.lat)
                lngs.push(loc.location.lng)
            }
            const midpoint = {
                lat: this.avg(lats),
                lng: this.avg(lngs)
            }
            // Calcualte distance using a service
            for (var i = 0; i < this.backends.length; i++) {
                var backend = this.backends[i]
                this.$set(backend, "avg_dist", get_distance(midpoint, backend))
            }

            // Find the best combination of backends
            const total_backends_num = this.coding_config.symbols + this.coding_config.redundant;
            this.selected_backends = []

            // Add the 3 closest Azure locations
            /*
            let available_azure_backends = this.backends.filter(b => { return b.supported && b.provider_id === 3 })
            available_azure_backends.sort( (a, b) => { return a.avg_dist - b.avg_dist  } )
            const AZURE_BACKENDS_NUM = 3
            for(var i=0 ; i<Math.min(AZURE_BACKENDS_NUM, available_azure_backends.length) ; ++i){
                this.selected_backends.push(available_azure_backends[i])
            }
            */

            // Fill up the rest with the next closest backends which are not yet in the suggested list
            let additional_backends = this.backends
                .filter(b => b.supported )
                .filter(b => !this.selected_backends.find(sel => sel.id === b.id ))

            additional_backends.sort( (a, b) => {
                if(Math.abs(a.avg_dist - b.avg_dist) < 500*1E3){
                    // When locations are close to each other (less than 500km), prefer the cheaper one
                    return a.download_weight - b.download_weight
                }
                return a.avg_dist - b.avg_dist
            })

            const already_selected_backends_num = this.selected_backends.length
            for(var i=0; i<total_backends_num-already_selected_backends_num ; ++i){
                this.selected_backends.push(additional_backends[i])
            }

            // Sort all recommendations by distance
            Utils.sort_objects(this.selected_backends, 'provider.name')
            //this.selected_backends.sort( (a, b) => { return a.avg_dist - b.avg_dist  } )

            // Init popover
            setTimeout(function(){
                $('[data-toggle="popover"]').popover();
            }, 200)

            /*
            let available_backends = this.backends.filter(b => { return b.supported })
            available_backends.sort( (a, b) => { return a.avg_dist - b.avg_dist  } )

            for (var i = 0 ; i < total_backends_num ; i++) {
                selected_backends.push(available_backends[i])
            }
            // Publish selected backends
            var old_length = this.selected_backends.length
            this.selected_backends = selected_backends;

            if(selected_backends.length === old_length){
                // Manually trigger recalculation of average distances
                this.recalculate_avg_dist_from_customer()
            }
            */
            this.recalculate_avg_dist_from_customer()

            this.redraw_map_lines()
        },

        selected_backends_length: function(){

            for (var i = 0; i < this.selected_backends.length; i++) {
                var backend = this.selected_backends[i];
                //backend.mapmarker.opacity = 1
            }

            this.recalculate_avg_dist_from_customer()
            this.redraw_map_lines()

            setTimeout(function(){
                $('[data-toggle="popover"]').popover();
            }, 400)
        },

        customize_mode(){
            if(this.customize_mode){
                UserService.save_event("Customize locations mode turned on.")
            }

        }
    },

    computed: {

        first_run(){
            return !this.customer_setup_success && !this.customer_setup_loading && this.user && this.user.buckets && this.user.buckets.length == 0
        },

        is_mobile(){
            return Utils.is_mobile()
        },

        user_locations_length: function(){ return this.user_locations.length },
        selected_backends_length: function(){ return this.selected_backends.length },

        unused_backends: function(){
            // Collect the backends which are not in selected_backends
            let backends = this.backends.filter(b => {
                return !this.selected_backends.find( sel => { return sel.id === b.id })
            })
            backends.sort( (a,b) => { return a.download_weight - b.download_weight } )

            // Apply filters
            const filters = this.backends_list_filters

            if(filters.hide_unsupported){
                backends = backends.filter(b => { return b.supported })
            }
            /*
            // Sort
            function asc(a, b){
                if( (typeof a === 'string' || a instanceof String) && (typeof b === 'string' || b instanceof String)){
                    const _a = a.toUpperCase(), _b = b.toUpperCase();
                    return (_a < _b) ? -1 : ((_a > _b) ? 1 : 0);
                }
                // numeric compare
                return a - b
            }

            function desc(a, b){ return -1 * asc(a, b) }
            const compare = filters.orderby_desc ? desc : asc;

            backends.sort( (a, b) => {

                // Handle special cases first
                if(filters.orderby === 'provider_name'){
                    return compare(a.provider.service_name, b.provider.service_name)
                }
                if(filters.orderby === 'provider_jurisdiction'){
                    return compare(a.provider.jurisdiction, b.provider.jurisdiction)
                }
                if(filters.orderby === 'price'){
                    const a_price = (!a.special_price) ? 2 : (a.special_price < 0) ? 1 : 3
                    const b_price = (!b.special_price) ? 2 : (b.special_price < 0) ? 1 : 3
                    return compare(a_price, b_price)
                }
                if(filters.orderby === 'latency'){
                    return compare(a.avg_dist, b.avg_dist)
                }
                // Order by direct attribute of backend
                return compare(a[filters.orderby], b[filters.orderby])
            } )
            */

            return backends
        },

        unused_backends_paginated: function(){
            const filters = this.backends_list_filters
            const first_idx = (filters.current_page - 1) * filters.items_per_page;

            let shown_backends = []
            for(var i=first_idx ; i<Math.min(first_idx+filters.items_per_page, this.unused_backends.length) ; ++i){
                shown_backends.push(this.unused_backends[i])
            }
            return shown_backends
        },

        backends_list_last_page_idx: function(){
            return Math.ceil(this.unused_backends.length / this.backends_list_filters.items_per_page)
        },

        backends_list_page_numbers: function(){
            const filters = this.backends_list_filters
            let pages = [];

            if(filters.current_page === 1){
                // First page
                pages = [1, 2, 3]
            }
            else if(filters.current_page === this.backends_list_last_page_idx){
                // Last page
                pages = [filters.current_page-2, filters.current_page-1, filters.current_page]
            }
            else{
                // Default
                pages = [ filters.current_page-1, filters.current_page, filters.current_page+1 ]
            }
            return pages
        },

        min_locations(){
            return (this.user && this.user.team_settings) ? this.user.team_settings.config.cloud_selector_min_locations : 0
        },

        max_locations(){
            return (this.user && this.user.team_settings) ? this.user.team_settings.config.cloud_selector_max_locations : 0
        }
    },

    created() {

        let backends = []
		let self = this
		let company_buckets = []

        Promise.all([
			StorageBackendService.get_buckets().then(res => { company_buckets = res.body }),
            CloudProviderService.list().then(res => { this.providers = res.body }),
            StorageLocationService.list().then(res => { backends = res.body }),
            /*MapMarkerService.list().then(res => { mapmarkers = res.body })*/
        ]).then(function() {

            // Patch the provider and the mapmarker to each backend
            for (var i = 0; i < backends.length; ++i) {
                let backend = backends[i]

                // Find the provider
                const provider = self.providers.find(p => { return p.id === backend.provider_id })
                if (provider != undefined) {
                    backend.provider = provider
                }

                // Find the mapmarker
                /*
                const marker = mapmarkers.find(marker => { return marker.id === backend.mapmarker_id })
                if (marker !== undefined) {
                    backend.mapmarker = {
                        title: backend.provider.service_name + " in " + backend.name+", "+backend.country,
                        url: 'assets/img/mapmarkers/' + marker.image_filename,
                        size: { width: marker.size_x, height: marker.size_y },
                        origin: { x: marker.origin_x, y: marker.origin_y },
                        anchor: { x: marker.anchor_x, y: marker.anchor_y },
                        location: { lat: backend.lat, lng: backend.lng },
                        opacity: .2
                    }
                }
                */
                backend.hovered = false
			}

			company_buckets.forEach( bucket => {
				bucket.backend = backends.find( b => { return b.id === bucket.backend_id } )
			}, self)

			// Publish company_buckets
			self.company_buckets = company_buckets

            // Publish backends
            self.backends = backends.filter(b => { return b.supported })

            // Get IP location
            AuthService.get_ip_location().then(res => {
                const location = res.body

                if(location.country.toUpperCase() === 'ZZ' || location.city === '?' || !location.coords){
                    // AppEngine couldn't figure out the location
                    self.no_ip_location = true
                    return
                }

                const country = countrycodes.find(c => { return c.code.toLowerCase() === location.country.toLowerCase() })
                const coords = location.coords.split(',')
                if(coords.length !== 2){
                    // Without the coords it won't work
                    self.no_ip_location = true
                    return
                }

                self.geoip_location = {
                    id: Math.random() * 1000,
                    formatted_address: Utils.capitalize(location.city).trim()+", "+Utils.capitalize(country.name).trim(),
                    country: {
                        name: Utils.capitalize(country.name),
                        code: location.country
                    },
                    lat: coords ? coords[0]-0 : null,
                    lng:  coords ? coords[1]-0 : null
                }
                //UserService.save_event("Suggested location: " + self.geoip_location.formatted_address)
                self.add_suggested_city()
            }).catch(err => {
                console.error("IP location error: ", err)
                self.no_ip_location = true
            })
        })
    },

    mounted(){
        this.focus_searchbox()

    },

    methods: {

        focus_searchbox(){
            const searchbox = document.getElementById("searchbox_input")
            if(searchbox){
                searchbox.focus()
            }
            else{
                var self = this
                setTimeout(function(){
                    self.focus_searchbox()
                }, 200)
            }

        },

        show_error(msg){ Utils.show_error(msg) },

        recalculate_avg_dist_from_customer: function(){


            // Calculate the average distance between each actual location from the 'symbols' best backends
            // Used to display a "service quality" indicator at each user location
            for (var i = 0; i < this.user_locations.length; i++) {
                const loc = this.user_locations[i];

                var distances = []
                for(var j=0 ; j<(this.selected_backends.length - this.coding_config.redundant) ; ++j){
                    distances.push(get_distance(loc.location, this.selected_backends[j]))
                }
                for(;j<this.selected_backends.length ; ++j){
                    distances.push(get_distance(loc.location, this.selected_backends[j]))
                }
                // Calculate average and patch
                this.$set(loc, 'avg_dist', this.avg(distances))
                this.$set(loc, 'distances', distances)
            }
        },

        redraw_map_lines: function(){
            return
            // Draw lines between user locations and selected backends
            let map_lines = []
            let boundingbox = new google.maps.LatLngBounds();

            for (var i = 0; i < this.user_locations.length; i++) {
                var user_loc = this.user_locations[i];
                boundingbox.extend(user_loc.location)

                for (var j = 0; j < this.selected_backends.length; j++) {
                    var backend = this.selected_backends[j];
                    boundingbox.extend(backend.mapmarker.location)

                    const tmp = this.classify_distance(get_distance(user_loc.location, backend.mapmarker.location))

                    map_lines.push({
                        id: user_loc.id + ' ' + backend.id,
                        path: [user_loc.location, backend.mapmarker.location],
                        color: tmp.color
                    })
                }
            }

            // Pan and zoom the map to fit everything
            if(this.user_locations.length > 0){

                const zoom = this.$refs.map.zoom
                this.$refs.map.fitBounds(boundingbox)
                this.map.zoom = zoom

			}

            this.map_lines = map_lines
        },

        backends_sort_change: function(key){
            let filters = this.backends_list_filters;
            if(key === filters.orderby){
                // change direction
                filters.orderby_desc = !filters.orderby_desc
            }
            else{
                filters.orderby = key
                filters.orderby_desc = false
            }
            filters.current_page = 1
        },

        scrollTo: function(id){
            return;
        },

        get_country_from_place: function(place) {
            // Parse the country (name+code) from a Place returned by Google Maps API
            if (place.address_components && place.address_components.length > 0) {
                // find the component
                for (var i = 0; i < place.address_components.length; ++i) {
                    const component = place.address_components[i];
                    if (component.types.indexOf("country") >= 0) {
                        // Found!
                        return {
                            name: component.long_name,
                            code: component.short_name
                        }
                    }
                }
            }
            // not found :(
            return null
        },

        add_suggested_city(){
            this.searchbox_place_selected(this.geoip_location)
            this.geoip_location = false
        },

        remove_user_location(location_idx){
            //UserService.save_event("User location removed: " + this.user_locations[location_idx].name)
            this.user_locations.splice(location_idx, 1)
        },

        searchbox_place_selected(place) {
            if (!place || !place.id) {
                Utils.show_warning("Please select the city from the suggestions list!")
                return;
            }

            if(this.user_locations.find(loc => { return loc.id === place.id })){
                // this location is already added
                document.getElementById("searchbox_input").value = ""
                return;
            }

            // Add location
            this.user_locations.push({
                id: place.id,
                name: place.formatted_address,
                country: place.country ? place.country : this.get_country_from_place(place),
                location: {
                    lat: place.lat ? place.lat : place.geometry.location.lat(),
                    lng: place.lng ? place.lng :place.geometry.location.lng()
                },
                marker_icon: {
                    path: google ? google.maps.SymbolPath.CIRCLE : null,
                    scale: 8
                }
            })

            //UserService.save_event("User location added: " + place.formatted_address)

            if(this.user_locations.length === 1){
                this.scrollTo('user_locations_row')
                /*
                $('.main-container').animate({
                    scrollTop: 100
                }, 500);
                */

            }

            // Move the map there
            /*
            this.move_map({
                center: {
                    lat: place.lat ? place.lat : place.geometry.location.lat(),
                    lng: place.lng ? place.lng :place.geometry.location.lng()
                },
                zoom: 5
            })
            document.getElementById("searchbox_input").value = ""
            */
        },

        add_backend(backend){
            if(this.selected_backends.length < this.max_locations){
                this.selected_backends.push(backend)
                UserService.save_event("Backend added manually: " + backend.provider.service_name + ", " + backend.name + ", " + backend.country)
            }
        },

        remove_backend(index){
            const backend = this.selected_backends[index]
            UserService.save_event("Backend removed manually: " + backend.provider.service_name + ", " + backend.name + ", " + backend.country)
            this.selected_backends.splice(index, 1)
        },

		company_setup: function(selected_backends){

            //UserService.save_event("Company setup clicked, selected locations: " + selected_backends.length)

			let backend_ids = []
			selected_backends.forEach(b => {
				backend_ids.push(b.id)
			})

            if(this.min_locations > 0 && backend_ids.length < this.min_locations){
                Utils.show_error("At least " + this.min_locations + " must be selected!")
                return
            }

            if(this.max_locations > 0 && backend_ids.length > this.max_locations){
                Utils.show_error("Maximum " + this.max_locations + " can be selected!")
                return
            }




			this.customer_setup_loading = true;
			StorageBackendService.team_backend_setup(backend_ids).then(res => {
                this.customer_setup_loading = false;

                UserService.save_event("Company setup ready")

                bus.$emit(events.RELOAD_BUCKETS)
                // Go to 'Files' and show 'ready' box
                localStorage["storage_setup_ready"] = true
                bus.$emit(events.NAVIGATE_TO, "/files")

                /*
                this.customer_setup_success = true

				StorageBackendService.get_buckets().then(res => {
					let buckets = res.body;
					// TODO this is the same process as in 'created()', extact to common function
					buckets.forEach( bucket => {
						bucket.backend = this.backends.find( b => { return b.id === bucket.backend_id } )
					}, self)
					this.company_buckets = buckets
				})*/

			}).catch(err => {
                this.customer_setup_loading = false;
                UserService.save_event("Error in company setup: " + JSON.stringify(err))
                Utils.show_error("There was a problem setting up your cloud locations!<br/>Our technical team is notified about this error. <b>We will contact you within 24 hours and resolve this together!</b>")
            })
		},


        classify_distance: function(dist_meters){
            const dist_km = dist_meters / 1000;
            let text, color, stars;

            if (dist_km < 2000) {
                text = "Excellent"
                color = "green"
                stars = 5
            }
            else if (dist_km < 4000) {
                text = "Very good"
                color = "MEDIUMSEAGREEN"
                stars = 4
            }
            else if (dist_km < 6000) {
                text = "Good"
                color = "YELLOWGREEN"
                stars = 3
            }
            else if (dist_km < 8000) {
                text = "Average"
                color = "#FF9800"
                stars = 2
            }
            else {
                text = "Poor"
                color = "CRIMSON"
                stars = 1
            }

            return {
                text: text,
                color: color,
                stars: stars
            }

        },

        move_map: function(loc) {
            this.$refs.map.panTo(loc.center)
            this.map.zoom = loc.zoom
        },
        marker_clicked: function(marker) {
        },
        marker_hovered: function(marker) {
        },
        range: function(n) {
            let a = [];
            for (var i = 0; i < n; ++i) { a.push(i) }
            return a
        },
        avg: function(arr) {
            let sum = 0.0;
            for (var i = 0; i < arr.length; ++i) {
                sum += arr[i]
            }
            return sum / arr.length
        }
    },

    filters: {

        km: function(meters){
            if(!meters){ return 0 + " km" }
            return Math.round(meters/1000) + " km"
        },

        jurisdiction_help_text: function(backend){
            const provider = backend.provider
            // Use "the United States"
            const jur_country = (provider.jurisdiction_countrycode == 'US') ? ('the ' + provider.jurisdiction) : provider.jurisdiction;

            return 'The parent company of ' +
                    provider.service_name + ', ' +
                    provider.name +
                    ' is registered in ' +
                    jur_country +
                    '. Courts of this country can order ' +
                    provider.name +
                    ' to hand over data stored in any of their locations, even in different countries.'
        }

    },

    directives: {

        serviceQuality: {

            bind: function(el, binding, vnode){

                // Shows a 5-star rating of the 'estimated service quality' at each
                // selected user location.
                // We classify the average distance from the location to the 'symbols' closest backends
                // and display 1-5 stars based on this
                //
                // Output: <i v-for="i in range(location.quality_stars)" :key="i" class="ion-star rating active" />

                if(!binding.value){ el.innerHTML = "-"; return }
                const dist_props = vnode.context.classify_distance(binding.value)
                const total_stars = 5

                var html = "<div class='star-rating-container'>"
                for(var i=0 ; i < dist_props.stars ; ++i){
                    html += '<i class="ion-star rating active"></i>'
                }
                for(var i=0 ; i < (total_stars - dist_props.stars) ; ++i){
                    html += '<i class="ion-star rating"></i>'
                }
                html += "</div>"

                el.innerHTML = html
            },

            update: function(el, binding, vnode){

                // Shows a 5-star rating of the 'estimated service quality' at each
                // selected user location.
                // We classify the average distance from the location to the 'symbols' closest backends
                // and display 1-5 stars based on this
                //
                // Output: <i v-for="i in range(location.quality_stars)" :key="i" class="ion-star rating active" />

                if(!binding.value){ el.innerHTML = "-"; return }
                const dist_props = vnode.context.classify_distance(binding.value)
                const total_stars = 5

                var html = "<div class='star-rating-container'>"
                for(var i=0 ; i < dist_props.stars ; ++i){
                    html += '<i class="ion-star rating active"></i>'
                }
                for(var i=0 ; i < (total_stars - dist_props.stars) ; ++i){
                    html += '<i class="ion-star rating"></i>'
                }
                html += "</div>"

                el.innerHTML = html
            },
        },

        priceLevelIndicator: {
            bind: function(el, binding, vnode){

                // Price levels:
                //  null/undefined/zero -> default price level ($$)
                //  negative number -> 'cheap' price level ($)
                //  positive number -> 'expensive' price level ($$$)
                //
                // Output:
                // <i class="price_icon ion-social-usd" :class="{ 'cheap': backend.price_level == 1, 'normal': backend.price_level == 2, 'expensive': backend.price_level == 3 }"></i><i v-for="i in range(3 - backend.price_level)" :key="i" class="price_icon ion-social-usd"

                let icon_class = ""
                let colored_icons = 0
                const total_icons = 3

                if(!binding || !binding.value){
                    // normal
                    icon_class = "normal"
                    colored_icons = 2
                }
                else if(binding.value > 0){
                    // expensive
                    icon_class = "expensive"
                    colored_icons = 3
                }
                else{
                    // cheap
                    icon_class = "cheap"
                    colored_icons = 1
                }

                let html = "<div class='price-icons-container'>"
                // Colored icons go first
                for (var i = 0; i < colored_icons; i++) {
                    html += "<i class='price_icon ion-social-usd "+icon_class+"'></i>"
                }
                // fill in the rest with non-colored icons
                for(var i=0 ; i < (total_icons-colored_icons) ; ++i ){
                    html += "<i class='price_icon ion-social-usd'></i>"
                }
                html += "</div>"

                el.innerHTML = html;
            },

            update: function(el, binding, vnode){

                // Price levels:
                //  null/undefined/zero -> default price level ($$)
                //  negative number -> 'cheap' price level ($)
                //  positive number -> 'expensive' price level ($$$)
                //
                // Output:
                // <i class="price_icon ion-social-usd" :class="{ 'cheap': backend.price_level == 1, 'normal': backend.price_level == 2, 'expensive': backend.price_level == 3 }"></i><i v-for="i in range(3 - backend.price_level)" :key="i" class="price_icon ion-social-usd"

                let icon_class = ""
                let colored_icons = 0
                const total_icons = 3

                if(!binding || !binding.value){
                    // normal
                    icon_class = "normal"
                    colored_icons = 2
                }
                else if(binding.value > 0){
                    // expensive
                    icon_class = "expensive"
                    colored_icons = 3
                }
                else{
                    // cheap
                    icon_class = "cheap"
                    colored_icons = 1
                }

                let html = "<div class='price-icons-container'>"
                // Colored icons go first
                for (var i = 0; i < colored_icons; i++) {
                    html += "<i class='price_icon ion-social-usd "+icon_class+"'></i>"
                }
                // fill in the rest with non-colored icons
                for(var i=0 ; i < (total_icons-colored_icons) ; ++i ){
                    html += "<i class='price_icon ion-social-usd'></i>"
                }
                html += "</div>"

                el.innerHTML = html;
            }
        },

        latencyIndicator: {

            bind: function(el, binding, vnode){
                if (binding.value === undefined || binding.value === null){
                    el.innerHTML = "-"; return;
                }

                const dist_props = vnode.context.classify_distance(binding.value)
                el.innerHTML = "<div class='latency-indicator' style='color: "+dist_props.color+"'>"+dist_props.text+"</div>"
            },
            update: function(el, binding, vnode){
                //console.log("latency indicator binding: ", binding)
                if (binding.value === undefined || binding.value === null){
                    el.innerHTML = "-"; return;
                }

                const dist_props = vnode.context.classify_distance(binding.value)
                el.innerHTML = "<div class='latency-indicator' style='color: "+dist_props.color+"'>"+dist_props.text+"</div>"
            }
        }
    }
}

</script>

<style lang="css" scoped>

#cloud-selector{
	margin-top: 20px;
}

#map {
    width: 100%;
    height: 600px;
}

#map_input_arrow{
    position: absolute;
    left: 86px;
    top: -50px;
    width: 100px;
    opacity: .8;
    z-index: 100;
}
.map_location_buttons button {
    margin-left: 10px
}

#map_searchbox_container {
    position: relative;
    top: -600px
}

.map_searchbox{
	width: 80%;
	margin: 10px auto;
	border: 0px;
	box-shadow: 0 0 5px 0 #ccc;
}
.heavy_highlight{
    box-shadow: 0 0 30px 0 #000;
}
.mild_highlight{
    box-shadow: 0 0 15px 0 #444;
}
.map_searchbox:hover,.map_searchbox:focus{
	border: inherit
}


.region_orig_name{
    font-family: monospace;
    color: #666;
    background: #ebebeb;
    padding: 2px 4px
}

.theme-dark .region_orig_name{
    color: white;
    background: rgba(255, 255, 255, .3)
}

.actions button {
    cursor: pointer;
}

.alert {
    display: flex;
    align-items: center;
}

.latency-indicator{
    display: inline-block;
    text-transform: uppercase;
    font-weight: bold;
}

i.rating {
    font-size: 18pt;
    margin: auto 3px;
}

i.rating.active {
    color: #FFD700
}
.badge{
    font-size: 80%
}

.pagination-container{
    display: flex;
    justify-content: flex-end;
    padding: 0px 10px;
}

.pagination-container .page-item,
.pagination-container .page-link{
    border: none;
    z-index: auto
}
.pagination-container .page-item{
    margin: 0px 2px
}

.price-icons-container{
    display: inline-block;
    white-space: nowrap;
}
i.price_icon{
    font-size: 12pt;
    color: #ccc;
    margin: auto 2px;
}
i.price_icon.normal{
    color: #1E88E5
}
i.price_icon.cheap{
    color: green
}
i.price_icon.expensive{
    color: CRIMSON
}
/*
.selected-backend-box {
    margin: 2%;
    width: 29%;
    padding: 16px;
    min-height: 300px;
    margin-bottom: 24px;
}

.selected-backend-box .cardbox-body,
.selected-backend-box .selected_backend{
    height: 100%;
}
.selected-backend-box .selected_backend{
    align-items: initial
}

.selected-backend-box .remove-cloud-container{
    text-align: center
}
*/

.selected_backend .help-icon{
    font-size: 12pt;
    color: #ccc;
}

.selected_backend .help-icon:hover{
    color: inherit;
}

.selected-backend-empty{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ccc
}

.selected-backend-empty:hover{
    color: #aaa
}

.selected-backend-empty i{
    font-size: 60pt
}

#backends_table thead th{
    white-space: nowrap;
}


.loader,
    .loader:after {
        border-radius: 50%;
        width: 1em;
        height: 1em;
    }
    .loader {
        display: inline-block;
        font-size: 10px;
        border-top: 1.1em solid rgba(255, 255, 255, 0.2);
        border-right: 1.1em solid rgba(255, 255, 255, 0.2);
        border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
        border-left: 1.1em solid #ffffff;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-animation: load8 1.1s infinite linear;
        animation: load8 1.1s infinite linear;
    }

    @-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
        }
    }
    @keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
        }
    }

    .theme-dark #searchbox_input{
        background: white;
        color: black
    }

    .theme-dark .user-locations-table td{
        color: white !important
    }
</style>
