<template>
    <span class="badge badge-success" :class="{'badge-success': is_gdpr, 'badge-warning': !is_gdpr}">
        <span v-if="is_gdpr">GDPR<i class="ion-checkmark ml-2"></i></span>
        <span v-else>Not GDPR compliant</span>
    </span>
</template>
<script>
export default {
    props: ['is_gdpr']
}
</script>