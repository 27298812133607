<template>
  <!-- This page is embedded on skyflok.com, it should have no styling -->
<div class="layout-container">
<div class="page-container">
  <div class="container-fluid">
    <!-- Month selector stays on the top -->
    <div class="fw py-3 text-bold" style="position: fixed; top: 0px; z-index: 1000; background: #f6fbfe;" >
      <div class="d-flex align-items-center justify-content-center gap-2">
        <button class="btn btn-flat text-gray ion-chevron-left" :class="{'hidden': !nav_back_enabled}" :disabled="!nav_back_enabled" @click="move_date_range('back')">
        </button>
        <div class="text-12">{{ date_range.year }} {{ selected_month_name }}</div>
        <button class="btn btn-flat text-gray"  :class="{'hidden': !nav_fwd_enabled}" :disabled="!nav_fwd_enabled"  @click="move_date_range('fwd')">
          <i class="ion-chevron-right" data-pack="default"></i>
        </button>
      </div>
    </div>
    
    <div class="row mt-5">
      <div class="col-1"></div>
      <div class="col-12 col-lg-10">
        
        <div v-if="!selected_month_msmts || !backends_with_data.length">Loading performance data...</div>
        <div v-else>
          
          <div class="text-bold text-gray-darker px-4">Average Provider Speed</div>
          <div class="text-muted px-4 text-sm">Download and upload speed across all locations of the provider.</div>
          <div style="overflow-x: scroll; overflow-y: hidden; white-space: nowrap;" class="px-2">
            <div class="col-11 col-sm-5 col-lg-4 col-xl-3 d-inline-block pt-3 pb-5" v-for="(p, idx) in providers_with_speeds" :key="idx">
              <div class="cardbox mb-0">
                <div class="cardbox-body text-center">
                    <div v-if="p.logo" class="d-flex align-items-center justify-content-center" style="height: 50px">
                      <img :src="Utils.cloud_provider_logo_src(p.logo)" style="max-width: 150px; max-height: 40px;">
                    </div>
                    
                    <div class="fw pt-3">
                      <p v-if="false" class="mb-0 text-center text-bold">{{ p.name }}</p>
                      <div class="d-flex align-items-center justify-content-between">
                          <div>{{ Math.round(p.downloads_avg) }} Mbps <i class="ion-arrow-down-a text-primary"/></div>
                          <div>{{ Math.round(p.uploads_avg) }} Mbps <i class="ion-arrow-up-a text-success"/></div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>

          <div class="">
            <div class="text-bold text-gray-darker">Speed Map</div>
            
            <div class="fw py-3 d-flex justify-content-start">
              <div style="width: 30%; min-width: 300px">
                <div :style="{'height': '6px', 'border-radius': '3px', 'background': `linear-gradient(to right, rgb(${colorscale.min.join(',')}), rgb(${colorscale.max.join(',')}))`}"></div>
                <div class="d-flex align-items-center justify-content-between text-sm">
                  <span>{{ speedscale.min }} Mbps</span>
                  <span>{{ speedscale.max }} Mbps</span>
                </div>
              </div>
            </div>
            <gmap-map
              style="width: 100%; height: 550px"
              :center="mapCenter"
              :zoom="mapZoom"
              :disableDefaultUI="true"
              :options="{ styles: map_style, disableDefaultUI: true }"
              ref="map">
                <gmap-info-window 
                  :options="infoOptions" 
                  :position="infoWindowPos" 
                  :opened="infoWinOpen" 
                  @closeclick="infoWinOpen=false"/>
                <gmap-marker
                    v-for="(b, index) in backends_with_data"
                    :key="index"
                    :title="`${b.provider} ${b.name}`"
                    :position="b"
                    :icon="{
                        path: 'M 100, 100m-75, 0a 75,75 0 1,0 150,0a 75,75 0 1,0 -150,0',
                        fillColor: `rgb(${pickHex(scaleDownloadSpeed(Utils.avg(b.download_speeds))).join(',')})`,
                        fillOpacity: 1,
                        strokeWeight: 0,
                        rotation: 0,
                        scale: 0.1,
                      }"
                    :draggable="false"
                    :clickable="true"
                    @click="toggleInfoWindow(b)"
                />
            </gmap-map>
          </div>
          
          <div class="mt-5">
            
            <div class="mb-4 d-flex align-items-center justify-content-between">
              <div>
                <input type="search" placeholder="Provider or location"  class="form-control" style="width: 300px" v-model="search" />
              </div>

              <div class=" d-flex align-items-center justify-content-center gap-3">
                <button v-for="(cat, idx) in backends_categories" :key="idx" class="btn" :class="{'btn-skyflok': backend_filter == cat.value, 'btn-skyflok-outline': backend_filter != cat.value}" @click="backend_filter = cat.value">{{ cat.label }}</button>
              </div>
              <div style="width: 300px"></div>
            </div>

            <div v-for="(b, idx) in backends_with_data_filtered" :key="idx" class="cardbox" :style="{'display': `${b.provider} ${b.name}`.toLowerCase().search(search.toLowerCase()) < 0 ? 'none' : 'block'}">
              <div class="cardbox-body">
                
                <p class="text-bold">
                  <span v-html="Utils.highlight_search(`${b.provider} ${b.name}`, search)"/>
                  <img :src="Utils.flag_img_src(b.countrycode)" class="ml-1" />
                </p>

                <div v-if="!eu_countrycodes.includes(b.countrycode)" class="text-warning text-sm mb-3">
                  <i class="ion-alert-circled mr-1"/> Note: Transfer speeds and latency are currently measured from Europe and are <u>not representative</u> of the performance of this storage location.
                </div>

                <div :id="`flotchart-speed-${b.id}-legend`" class="ml-5"></div>
                <div class="flot-chart" :id="`flotchart-speed-${b.id}`"></div>

                <div :id="`flotchart-latency-${b.id}-legend`" class="mt-4 ml-5"></div>
                <div class="flot-chart latency" :id="`flotchart-latency-${b.id}`"></div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    
    <div v-if="false" class="row">
      <div class="col">
        Cached: 
        <div v-for="[date, msmts] in Object.entries(cache)" :key="date">
          {{ date }}: {{ msmts.length }}x
        </div>
      </div>
    </div>
    <div v-if="false" class="row">
      <div class="col">
        {{ selected_month_msmts }}
      </div>
    </div>
  </div>
</div>
</div>
</template>

<script>
import { Utils } from '@/helpers/utils';
import { map_style } from "../helpers/map_style.js";
import '@/assets/vendor/flot/jquery.flot.js'
//import '@/assets/vendor/flot/jquery.flot.time.js'
import '@/assets/vendor/flot/jquery.flot.categories.js'
//import '@/assets/vendor/flot-spline/js/jquery.flot.spline.js'
import '@/assets/vendor/flot.tooltip/js/jquery.flot.tooltip.js'
import '@/assets/js/app.js' // for 'Colors'

const now = new Date()
const current_year = now.getFullYear()
const current_month = now.getMonth()
const first_data_year = 2023
const first_data_month = 5 // June (months are indexed from 0)

const SPEED_CHART_OPTIONS = {
  legend: {
    show: true,
    position: "ne",
    noColumns: 2,
    margin: [10, 20]
  },
  series: {
    
      lines: {
        show: true,
        fill: true,
        zero: false,
        fillColor: {
          colors: [{ opacity: 0.1 }, { opacity: 0.5 }]
        }
      },
      points: {
        show: true
      }
    },
    grid: {
      borderColor: 'rgba(162,162,162,.26)',
      borderWidth: 0,
      hoverable: true,
      backgroundColor: 'transparent',
      labelMargin: 10
    },
    tooltip: {
      show: true,
      content: (label, xval, yval) => `${label}: ${yval.toFixed(2)} Mbps`
    },
    xaxis: {
      tickColor: 'rgba(162,162,162,.26)',
      font: {color: "#607d8b"},
      mode: 'categories',
      ticks: (axis) => {
        const cats = Object.entries(axis.categories)
        // Return every second label if the month has over 20 daily measurements
        // (otherwise the labels break into multiple rows to avoid overlapping)
        let labels = []
        for(const [label, idx] of cats){
          if(cats.length < 20 || idx % 2 == 0){
            labels.push([idx, label])
          }
        }
        return labels
      }
    },
    yaxis: { 
      position: "left",  
      tickColor: 'rgba(162,162,162,.26)',
      ticks: 3,
      font: {color: "#607d8b"},
      tickFormatter: val => `${val} Mbps`,
    },
    shadowSize: 0
};

const LATENCY_CHART_OPTIONS = $.extend(true, SPEED_CHART_OPTIONS)
LATENCY_CHART_OPTIONS.yaxis.tickFormatter = val => `${Math.round(val)} ms`
LATENCY_CHART_OPTIONS.tooltip.content = "Latency: %y"
LATENCY_CHART_OPTIONS.yaxis.ticks = 2

const MAP_CENTER_GLOBAL = { lat: 40.0, lng: -30.0 }
const MAP_CENTER_EU = { lat: 50.0, lng: 15.0 }
const MAP_CENTER_US = { lat: 40.0, lng: -100.0 }


export default { 
  
  data() {
    return {
      Utils: Utils,
      map_style: map_style,

      date_range: {
        year: current_year,
        month: current_month
      },
      loading: false,
      error: false,

      // Cache: already loaded measurements per month
      // Key: '2023-9' (where the month is the month index [0-11])
      // Value: array of measurements: { date: '2023-9-1', measurements: [{'backend_id': 1, 'upload_speed_mbps': ...}] }
      cache: {},

      backends: [],
      measurements: [],

      colorscale: {
        min: [255, 97, 97],
        max: [62, 178, 33]
      },

      speedscale: {
        min: 0,
        max: 100
      },
      
      mapCenter: MAP_CENTER_EU,
      mapZoom: 4,

      infoWindowPos: null,
      infoWinOpen: false,
      infoOptions: {
        content: '',
      },
      infoWindowBackendId: null,

      backends_categories: [
        { label: "Europe", value: "eu", exclude_countrycodes: ["us", 'ca', 'jp', 'br']},
        { label: "North America", value: "us", countrycodes: ["us", 'ca']},
        { label: "All", value: "all"},
      ],
      backend_filter: 'eu',

      eu_countrycodes: [ "BE", "GB", "IE", "DE", "NL", "FR", "FI", "SE", "NO", "CH", "PL", "AT", "BG" ],
      search: ""
    }
  },

  computed: {
    
    selected_month_name(){ return Utils.monthNames[this.date_range.month] },

    nav_back_enabled(){ return this.date_range.year > first_data_year || ((this.date_range.year == first_data_year) && this.date_range.month > first_data_month) },
    nav_fwd_enabled(){ return this.date_range.year < current_year ||  ((this.date_range.year == current_year) && this.date_range.month < current_month) },

    selected_month_msmts(){
      const cache_key = `${this.date_range.year}-${this.date_range.month}`
      if(!this.cache[cache_key]){ 
        return []
      }
      return this.cache[`${this.date_range.year}-${this.date_range.month}`] 
    },

    providers(){
      let providers = [] 
      this.backends.forEach(b => {
        if(!providers.find(p => p.name == b.provider)){
          providers.push({
            name: b.provider,
            logo: b.provider_logo 
          })
        }
      })
      return providers
    },

    providers_with_speeds() {
      // Clear the measurements of providers
      let providers_speeds = this.providers.map(p => {
        if(p.downloads){ p.downloads.splice(0) }
        if(p.uploads){ p.uploads.splice(0) }
        if(p.latencies){ p.latencies.splice(0) }
        return p
      })
      // Add measurements of currently selected month
      this.selected_month_msmts.map(d => d.measurements).flat().forEach(msmt => {
        const b = this.backends.find(b => b.id == msmt.backend_id)
        const p = providers_speeds.find(p => p.name == b.provider)
        if(!p.downloads){ 
          p['downloads'] = [] 
          p['uploads'] = [] 
          p['latencies'] = [] 
        }
        p['downloads'].push(msmt.download_speed_mbps)
        p['uploads'].push(msmt.upload_speed_mbps)
        p['latencies'].push(msmt.download_latency_ms)
      })

      // Calculate averages
      providers_speeds.forEach(p => {
        if(!p.downloads){ console.error(`No downloads for ${p.name} in ${this.date_range.year}-${this.date_range.month}`)}
        p['downloads_avg'] = p.downloads ? Utils.avg(p['downloads'].filter(d => !!d)) : 0
        p['uploads_avg'] = p.uploads ? Utils.avg(p['uploads'].filter(d => !!d)) : 0
        p['latencies_avg'] = p.latencies ? Utils.avg(p['latencies'].filter(d => !!d)) : 0
      })
      return Utils.sort_objects(providers_speeds, 'downloads_avg', false)
    },

    download_speed_range(){
      // Finds the min and max download speed of the current month

      if(!this.selected_month_msmts) return {
        min: 0, max: 0
      }

      
      const download_speeds = this.selected_month_msmts
        .map(d => d.measurements)
        .flat()
        .map(m => m.download_speed_mbps)
        .filter(speed => speed) // filter out undefined and null
      
      return {
        min: Math.min(...download_speeds),
        max: Math.max(...download_speeds)
      }
    },
    /*
    download_speed_range_monthly_avg(){
      // Min and max download speed across the monthly average speeds of each backned
      const download_speeds = this.backends_with_data.map(b => Utils.avg(b.download_speeds))
      return {
        min: Math.min(...download_speeds),
        max: Math.max(...download_speeds)
      }
    },
    */

    backends_with_data(){
      if (!this.selected_month_msmts || this.selected_month_msmts.length == 0){ return [] }
      return this.backends.map(b => {
        // Filter the measurements to this backend only
        const data = this.selected_month_msmts.map(msmts => {
          const perfs = msmts.measurements.filter(m => m.backend_id == b.id)
          // Add date
          perfs.forEach(p => p.date = msmts.date)
          return perfs
        }).flat()
        
        // Add download speeds
        b['download_speeds'] = data.map(m => m.download_speed_mbps).filter(speed => speed)

        // Add a bit of noise to the coords so backends in the same location (e.g. Paris) are not drawn over each other
        if(this.backends.filter(bb => bb.lat == b.lat && bb.lng == b.lng).length > 1){
          const noisy_coords = Utils.add_gps_noise(b)
          b.lat = noisy_coords.lat
          b.lng = noisy_coords.lng
        }

        // Colors is a global function declared in assets/js/app.js and loaded in index.html
        const speed_chart_data = [
          {
            label: "Download Speed",
            color: Colors.byName('blue-400'), 
            data: data.map(m => [this.date_to_day(m.date), m.download_speed_mbps])
          },
          {
            label: "Upload Speed",
            color: Colors.byName('green-400'),
            data: data.map(m => [this.date_to_day(m.date), m.upload_speed_mbps])
          }
        ]
        /*
        let download_speeds = speed_chart_data[0].data.map(d => d[1])
        download_speeds = download_speeds.concat(speed_chart_data[1].data.map(d => d[1]))
        SPEED_CHART_OPTIONS.yaxis.min = Math.min(...download_speeds)*0.99
        SPEED_CHART_OPTIONS.yaxis.max = Math.max(...download_speeds)*1.01
        */
        
        SPEED_CHART_OPTIONS.legend.container = $(`#flotchart-speed-${b.id}-legend`)
        $(`#flotchart-speed-${b.id}`).plot(speed_chart_data, SPEED_CHART_OPTIONS);
        
        // --- LATENCY CHART -----
        const latency_chart_data = [
          {
            label: "Latency",
            color: Colors.byName('grey-600'),
            data: data.map(m => [this.date_to_day(m.date), m.download_latency_ms])
          },
        ]
        
        /*
        // Add a bit of margin to the y axis so the spline does not leave the chart area
        const latencies = latency_chart_data[0].data.map(d => d[1])
        LATENCY_CHART_OPTIONS.yaxis.min = Math.min(...latencies)*0.99
        LATENCY_CHART_OPTIONS.yaxis.max = Math.max(...latencies)*1.01
        LATENCY_CHART_OPTIONS.yaxis.autoScale = false
        */
        LATENCY_CHART_OPTIONS.legend.container = $(`#flotchart-latency-${b.id}-legend`)
        $(`#flotchart-latency-${b.id}`).plot(latency_chart_data, LATENCY_CHART_OPTIONS);

        return b
      })
    },

    backends_with_data_filtered(){
      const backends_category = this.backends_categories.find(c => c. value == this.backend_filter)
      /*
      const backends_filtered = this.backends_with_data.filter(b => {
        const label = `${b.provider} ${b.name}`.toLowerCase()

        return this.search.length ? label.search(this.search.toLowerCase()) >= 0 : true
      })
      */

      if(backends_category.exclude_countrycodes){
        return this.backends_with_data.filter(b => !backends_category.exclude_countrycodes.includes(b.countrycode.toLowerCase()))
      }
      else if(backends_category.countrycodes){
        return this.backends_with_data.filter(b => backends_category.countrycodes.includes(b.countrycode.toLowerCase()))
      }
      return this.backends_with_data
    },

  },

  mounted() {
    // Load current month
    this.load_measurements()
    // Preload the previous month
    this.load_measurements(-1)
  },


  watch: {
    // 
    date_range: {
      deep: true,
      handler() {
        // preload previous month 
        this.load_measurements(-1)
      }
    },

    backend_filter(newval){
      if(newval == 'all'){ this.mapCenter = MAP_CENTER_GLOBAL; this.mapZoom = 2 }
      else if(newval == 'eu'){ this.mapCenter = MAP_CENTER_EU; this.mapZoom = 4 }
      else if(newval == 'us'){ this.mapCenter = MAP_CENTER_US; this.mapZoom = 4 }
    }

  },

  methods: {

    load_measurements(month_offset) {
      // moving multiple years not implemented
      if(month_offset > 11 || month_offset < -11){
        return null
      }
      this.loading = true

      let year_to_load = this.date_range.year
      let month_to_load = this.date_range.month
      if(month_offset){
        // Adjust year if needed
        if(month_offset > 0 && month_to_load + month_offset > 11){
          year_to_load++
        }
        else if(month_offset < 0 && month_to_load + month_offset < 0){
          year_to_load--
        }
        // Adjust month
        month_to_load += month_offset
        if(month_to_load > 0) month_to_load = (month_to_load % 11)
        else if (month_to_load < 0) month_to_load = 12+month_offset
      }
      const date_key = `${year_to_load}-${month_to_load}`
      if(date_key in this.cache){
        return
      }

      // From: beginning of the current month
      let from_date = new Date(0)
      from_date.setUTCFullYear(year_to_load)
      from_date.setUTCMonth(month_to_load)
      from_date.setUTCDate(1)
      from_date.setUTCHours(0)
      from_date.setUTCMinutes(0)
      // To: beginning of the next month
      let to_date = new Date(from_date)
      if(month_to_load == 11){
        to_date.setUTCMonth(0)
        to_date.setUTCFullYear(year_to_load+1)
      }
      else{
        to_date.setUTCMonth(month_to_load + 1)
        to_date.setUTCFullYear(year_to_load)
      }
      //console.log(`${from_date.toISOString()} - ${to_date.toISOString()}`)

      fetch(`https://api.sky-flok.com/storage-backend/performance?from=${from_date.getTime()/1000}&to=${to_date.getTime()/1000}`)
        .then(res => res.json())
        .then(res => {
          this.loading = false
          this.error = null

          // Add new backends, don't remove anything
          res.backends.forEach(b => {
            if(!this.backends.find(backend => backend.id == b.id)){
              this.backends.push(b)
            }
          })
          // Add measurements to cache
          this.$set(this.cache, date_key, [])
          for(const [date, msmts] of Object.entries(res.daily_perf_measurements)){
            
            msmts.forEach(record => {
              if(record.download_speed_adj_mbps > 0) {
                record.download_speed_mbps = record.download_speed_adj_mbps
              }
              
              if(record.upload_speed_adj_mbps > 0){
                record.upload_speed_mbps = record.upload_speed_adj_mbps
              }
            })
            
            this.cache[date_key].push({
              date: date, 
              measurements: msmts
            })
          }
        })
        .catch(err => {
          console.error(err)
          this.loading = false
          this.error = "Error loading measurements, please try again later!"
        })
    },
    

    move_date_range(direction) {
      if(direction === 'back'){
        if(this.date_range.month == 0){
          this.date_range.month = 11
          this.date_range.year--
        }
        else{
          this.date_range.month--
        }
      }
      else{
        if(this.date_range.month == 11){
          this.date_range.month = 0
          this.date_range.year++
        }
        else{
          this.date_range.month++
        }
      }
    },

    date_to_day(datestr){
      const day = datestr.split('-')[2]
      const last_digit = day[day.length-1]
      const end = (day == 11 || last_digit == 1) ? 'st' : 
                    (last_digit == 2) ? "nd" : 
                    "th"
      return `${Utils.monthNamesShort[this.date_range.month]} ${day}<sup>${end}</sup>`
    },

    scaleDownloadSpeed(speed) {
      if(speed <= this.speedscale.min) return 0
      if(speed >= this.speedscale.max) return 1

      // Returns the percentage (0-1) of the given speed in the current speed range
      const weight = (speed - this.speedscale.min) / (this.speedscale.max - this.speedscale.min)
      return weight
    },

    pickHex(weight, color1, color2) {
      color1 = color1 ? color1 : this.colorscale.max
      color2 = color2 ? color2 : this.colorscale.min
      // Returns the color ([r,g,b]) which is at the given weight (0-1) between color1 and color2
      var p = weight;
      var w = p * 2 - 1;
      var w1 = (w/1+1) / 2;
      var w2 = 1 - w1;
      var rgb = [
        Math.round(color1[0] * w1 + color2[0] * w2),
        Math.round(color1[1] * w1 + color2[1] * w2),
        Math.round(color1[2] * w1 + color2[2] * w2)
      ];
      return rgb;
    },

    toggleInfoWindow(backend) {
      if(this.infoWindowBackendId == backend.id){
        // close
        this.infoWinOpen = !this.infoWinOpen
        return
      }
      this.infoWindowPos = {lat: backend.lat, lng: backend.lng};
      this.infoOptions.content = `Average download speed of ${backend.provider} ${backend.name} <br/>in ${this.date_range.year} ${this.selected_month_name}: ${Math.round(Utils.avg(backend.download_speeds))} Mbps`;
      this.infoWinOpen = true
      this.infoWindowBackendId = backend.id
      this.mapCenter = {lat: backend.lat, lng: backend.lng}

    }
  }
}
</script>
<style scoped>
.flot-chart{
    display: block;
    width: 100%;
    height: 180px !important;
    padding: 0px;
    position: relative;
}

.flot-chart.latency{
  height: 150px;
}
.hidden{
  visibility: hidden;
}

input[type="search"] {
  margin: 0;
  padding: 7px 8px 7px 40px;
  background: white url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 13px center;
}

input[type="search"]::placeholder {
  color: #bbb;
}


</style>
<style>
td.legendLabel{
  padding-right: 1em;
  padding-left: .3em
}
.legendColorBox div {
  border-radius: 50%;
}
</style>