<template>
<section v-if="mfa_content=='status'" class="fw text-center my-2">
    <div v-if="mfa_status">
        <section v-if="mfa_status.has_2fa && mfa_status.verified" class="text-success" :class="{'text-14': is_client_space}">
            <i class="ion-locked mr-2"></i> Enabled, using {{ mfa_status.kind == 1 ? "mobile authenticator app" : "SMS" }}
        </section>
        <section v-else-if="mfa_status.has_2fa && !mfa_status.verified" class="text-warning">
            <i class="ion-alert-circled mr-1"></i> Enabled using {{ mfa_status.kind == 1 ? "mobile authenticator app" : "SMS" }}, but not verified!
            <br/><a href="#" @click.prevent="()=>{ $emit('mfa_content_change', mfa_status.kind == 1 ? 'show_qr' : 'change_phone_number') }" class="ml-1">Verify now</a>
        </section>
        <section v-else>
            Your account is not protected by two factor authentication.
            <button class="btn btn-success mt-3" @click="() => { $emit('mfa_content_change', 'setup'); setup_mfa_step = 1 }">
                <i class="ion-locked mr-2"></i>Enable Two-Factor Authentication
            </button>
        </section>
    </div>
    <div v-else>
        Loading... <i class="ion-load-c spin ml-1"></i>
    </div>
</section>
<div v-else-if="mfa_content == 'setup'" class="fw text-center">
    <!-- Select MFA kind (app/SMS) -->
    <section v-if="setup_mfa_step == 1">
        <div class="alert alert-secondary">
            You can use either an Authenticator app on your phone, 
            or SMS to receive one time passcodes when you are
            logging in from a new device or browser. This 
            significantly improves security of your account.
        </div>
        
        <div class="fw d-flex justify-content-center mt-3">
            <div class="mr-1">
                <button class="btn btn-success" @click="setup_mfa_step='app'">Use an app on your phone</button>
                <br/><small class="text-success"><i class="ion-locked mr-1"></i>This is the more secure option</small>
            </div>
            <div class="ml-1">
                <button class="btn" @click="setup_mfa_step='sms'" :disabled="true" title="Coming soon">Receive codes in SMS</button>
            </div>
        </div>
    </section>

    <!-- App kind selected, show QR code, verifier input and recovery codes -->
    <mfa-setup-app 
        v-else-if="setup_mfa_step=='app'" 
        :user_email="user_email"
        :show_error="error" 
        @completed="()=>{ mfa_setup_ready() }">
    </mfa-setup-app>

    <section v-else-if="setup_mfa_step == 'sms'" class="my-3">
        Two factor authentication with SMS is not supported yet
    </section>
</div>

<div v-else-if="mfa_content == 'delete'">
    <mfa-disable 
        :show_success="success"
        :hash_password="is_client_space === undefined ? false : is_client_space"
        @disabled="()=>{ mfa_disabled() }" 
        @cancelled="()=>{ $emit('mfa_content_change', 'status') }"
    />
</div>

<div v-else-if="mfa_content == 'lost_phone'" class="mt-2">
    <div class="alert alert-secondary">
        <p>
            If you lost the phone that was set up for {{ mfa_status.kind == 1 ? "generating codes" : "receiving codes in SMS" }}, do the following:
        </p>
        <ol class="lost_phone_instructions">
            <li>First <a href="#" @click.prevent="()=>{ $emit('mfa_content_change', 'delete') } " >disable two-factor authentication</a> so the lost phone won't be able to {{ mfa_status.kind == 1 ? "generate codes" : "receive codes" }} for your account</li>
            <li>Then, set up two-factor auth again using your new phone.</li>
            <li>Finally, review your active SkyFlok sessions below and delete the ones from the lost phone.</li>
            <li>(it's good practice to change all of your passwords when you lose a personal device, just to be safe)</li>
        </ol>
    </div>
    <div class="fw text-center mt-2">
        <button class="btn btn-secondary " @click="()=>{ $emit('mfa_content_change', 'status') }">
            <i class="ion-arrow-left-c mr-2"></i>Back
        </button>
    </div>
</div>

<div v-else-if="mfa_content == 'show_qr'">
    <show-verify-qr 
        :url="mfa_app_url"
        :show_error="error" 
        @verified="()=>{ mfa_setup_ready() }">
    </show-verify-qr>
</div>

<div v-else-if="mfa_content == 'recovery_codes'">
    <mfa-recovery-codes 
        :user_email="user_email"
        :show_success="success"
        :show_error="error">
    </mfa-recovery-codes>
    <div class="fw text-center mt-2">
        <button class="btn btn-secondary " @click="()=>{ $emit('mfa_content_change', 'status') }">
            <i class="ion-arrow-left-c mr-2"></i>Back
        </button>
    </div>
</div>
<div v-else-if="mfa_content == 'history'">
    <mfa-history
        class="mt-3"
        :is_client_space="is_client_space"
        :show_success="success"
        :show_error="error">
    </mfa-history>
    <div class="fw text-center">
        <button class="btn btn-secondary " @click="()=>{ $emit('mfa_content_change', 'status') }">
            <i class="ion-arrow-left-c mr-2"></i>Back
        </button>
    </div>
</div>

</template>

<script>

import { AuthService } from '@/services/auth-service.js'
import { bus, events } from '@/helpers/event_bus.js'
import { Utils } from '@/helpers/utils.js'

import showVerifyQr from './show_verify_qrcode.vue'
import mfaRecoveryCodes from './recovery_codes.vue'
import mfaDisable from './disable_mfa.vue'
import mfaSetupApp from './setup_app'
import mfaHistory from './history'

export default {

    props: ["email_address", "mfa_content", 'is_client_space', 'show_success', 'show_error'],

    components: {
        showVerifyQr,
        mfaRecoveryCodes,
        mfaDisable,
        mfaSetupApp,
        mfaHistory
    },

    data(){
        return {
            mfa_status: null,
            setup_mfa_step: 0,
            mfa_app_url: null,
            mfa_recovery_codes: null,
        }
    },

    computed: {
        success(){
            return this.show_success ? this.show_success : Utils.show_success
        },
        error(){
            return this.show_error ? this.show_error : Utils.show_error
        },
        
    },

    created(){
        this.load_mfa_status()
    },

    mounted(){
        this.load_mfa_status()
    },

    methods: {
        load_mfa_status(){
            return AuthService.get_2fa_status().then(res => {
                this.mfa_status = res.body
                this.$emit("mfa_status_loaded", this.mfa_status)

            }).catch(err => {
                console.error(err)
                this.error("Failed to load Two-Factor Authentication information!")
            })
        },

        mfa_setup_ready(){
            this.success("Two-Factor Authentication set up successfully. You will have to provide the code when you log in.")
            this.load_mfa_status().then(()=>{
                this.$emit("mfa_content_change", 'status')
            })
        },

        mfa_disabled(){
            bus.$emit(events.RELOAD_2FA_STATUS)
            this.load_mfa_status().then(()=>{
                this.$emit("mfa_content_change", 'status')
            })
        },
    }

}
</script>

<style scoped>

.lost_phone_instructions{
    list-style: none;
    counter-reset: ctr;
}
.lost_phone_instructions li{
    line-height: 1.5em;
    margin-bottom: .6em;
    counter-increment: ctr;
}

.lost_phone_instructions li::before{
    content: counter(ctr) '. ';
    font-weight: 800;
    margin-right: .1em
}
</style>