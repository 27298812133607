<template>

<section
    class="section-container"
    id="files"
    @dragover.prevent="something_dragged($event)"
    @dragleave.prevent="are_files_dragged=false"
    @drop.prevent="something_dropped($event)"
    :class="{'drag_valid': !disable_upload && are_files_dragged}"
    @click="remove_file_highlight()">

<div v-if="!disable_upload" id="dropzone" class="align-items-center justify-content-center">
    <div class="jumbotron bg-gradient-info text-white ">
        <div class="container">
            <h1 class="text-center mt-5 mb-5">Drop your file(s) here to upload</h1>
        </div>
    </div>
</div>

<!-- No buckets -->
<div v-if="user && !user.is_client_space_client && buckets && buckets.length == 0" class="container mt-3" style="top: 15px">
    <div class="row">
        <div class="col-2 d-none d-md-table-cell"></div>
        <div class="col-12 col-lg-8">
            <div class="jumbotron text-white" :class="{'bg-gradient-info': user.is_admin, 'bg-gradient-warning': !user.is_admin}">
                <div class="container">
                    <section v-if="user.is_admin">
                        <h1>Hello and welcome to SkyFlok!</h1>
                        <h4 class="mt-3">To get started, first you need to set where your data will be stored.</h4>
                        <h4 class="mt-3">Please go to <u><router-link to="/cloud-selector" class="text-white">Cloud Selector</router-link></u> and set up your account now!</h4>
                    </section>
                    <section v-else>
                        <h4>This Team account is not ready to upload files, please ask one of the Team Administrators to complete the initial setup!</h4>
                    </section>
                </div>
            </div>
        </div>
    </div>
</div>

<div v-else id="file_list" class="container-fluid">

    <div v-if="show_clouds_ready_box" class="row">
        <div class="col-2"></div>
        <div class="col-12 col-lg-8">
            <div class="cardbox text-white bg-gradient-success b0">
                <div class="cardbox-body text-center text-bold text-12">
                    Congratulations, your SkyFlok account is fully set up!
                    <br/>Upload some files by clicking on the large blue button below,
                    <br/>or drag and drop them into the window!
                </div>
            </div>
        </div>
    </div>

    <!-- Admin Folder description -->
    <div class="row" v-if="false && is_admin_folder">
        <div class="col-2"></div>
        <div class="col-12 col-lg-8">
            <div class="cardbox text-white bg-gradient-warning b0">
                <div class="cardbox-body text-center text-bold text-12">
                    This is a safe folder that is only visible and accessible for Team Administrators.
                    <br/>
                    It's an ideal place for things you don't want to share with the whole team, like passwords, invoices, contracts, Non-Disclosure Agreements and similar files.
                </div>
            </div>
        </div>
    </div>


    <!-- Breadcrumbs -->
    <div class="row" v-if="nav_stack.length > 1">
        <div class="col-12 breadcrumb">
            <div class="d-inline-block nowrap" v-for="(folder, index) in nav_stack.slice(0, nav_stack.length - 1)" :key="index" @dragover.prevent="folder.dragover=true" @dragleave.prevent="folder.dragover=false" @drop.prevent="drop($event, folder)" >
                <button class="btn btn-flat btn-info btn-sm" type="button" :title="'Go back to ' + (folder.id == -1 ? 'the root folder' : folder.name + ' directory')" :class="{'bg-success': folder.dragover}" @click="change_folder_to(folder)">
                    <i v-if="folder.id === -1" class="icon ion-home"></i>
                    <span v-else class="d-flex align-items-center">
                        <i class="ion-folder mr-2"></i> {{folder.name}}
                    </span>
                </button>
                <i class="ion-chevron-right v-middle" v-if="index < nav_stack.length-1"/>
            </div>

            <div class="d-inline-block nowrap">
                <div class="btn btn-flat btn-sm btn-secondary c-default text-bold" :title="'You are in ' + nav_stack[nav_stack.length-1].name + ' directory'" :disabled="true">
                    <span class="d-flex align-items-center">
                        <i class="ion-folder mr-2"></i>{{ nav_stack[nav_stack.length-1].name }}
                    </span>
                </div>
            </div>
        </div>
    </div>


    <!-- Folders -->
    <div class="row" id="folders">

        <div class="folder-box col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3"
            v-for="folder in folders" :key="folder.id"
            :class="{'dragover': folder.dragover}"
            :id="`skyflok-folder-${folder.id}`"
            @click="folder.rename ? false : change_folder_to(folder)">
            <!-- only navigate into folder if it's not being renamed -->

            <div
                :draggable="!disable_namespace_ops"
                @dragstart="drag($event, folder)"
                @drop.prevent="drop($event, folder)"
                @dragend="dragend($event, folder)"
                @dragover.prevent="folder.dragover=true"
                @dragleave.prevent="folder.dragover=false"
                class="cardbox"
                :class="get_folderbox_class(folder)"

                style="border-left: 4px solid;">

                <div class="cardbox-body" :title="folder.name">
                    <div v-if="!folder.rename" class="d-flex justify-content-start align-items-center">

                        <i class="ion-folder mr-2"></i>
                        <span class="mb-0 foldername">{{ folder.name }}</span>

                        <div v-if="!disable_share || !disable_namespace_ops" class="ml-auto" @click.prevent.stop="folderbox_dropdown('folder_'+folder.id+'_dropdown')" title="Show folder options">
                            <div class="dropdown btn-group">
                                <button class="btn btn-sm btn-secondary btn-flat mb-0 mt-0 folder-overflow-btn" :id="'folder_'+folder.id+'_dropdown'" type="button" data-toggle="dropdown" aria-expanded="false" ><i class="ion-more text-gray-light"></i>
                                </button>
                                <div class="dropdown-menu dropdown-menu-right mt-2" role="menu">
                                    <a v-if="!disable_share && folder_shares_enabled" class="dropdown-item" href="#" @click.prevent="open_share_form(folder)"><i class="ion-share icon-lg mr-3"></i>Share Folder</a>
                                    <a v-if="!disable_namespace_ops" class="dropdown-item" href="#" @click.prevent="rename_file(folder)"><i class="ion-edit icon-lg mr-3"></i>Rename</a>
                                    <a v-if="!disable_namespace_ops"  class="dropdown-item" href="#" @click.prevent="copy_move_file(folder, 'move_folder')"><i class="ion-forward icon-lg mr-3"></i>Move</a>
                                    <div v-if="!disable_namespace_ops" class="dropdown-divider"></div>
                                    <a v-if="!disable_namespace_ops" class="dropdown-item text-danger" href="#" @click.prevent="delete_file(folder)">
                                        <i v-if="deleteAction" :class="[deleteAction.icon, 'icon-lg mr-3']"></i>
                                        <i v-else class="ion-trash-a icon-lg mr-3"></i>{{ deleteAction ? deleteAction.label : 'Delete'}}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="nowrap">
                        <!-- Folder rename -->
                        <form @submit.prevent="rename_submit(folder)">
                        <div class="input-group" ><!-- height: 37px -->
                            <input :id="'file-rename-'+folder.id" class="form-control form-control-sm" type="text" v-model="folder.rename.name"/>
                            <span v-if="!folder.rename.loading">
                                <button class="btn btn-sm btn-success btn-gradient ml-1 fh" type="submit" ><i class="ion-checkmark-round"></i></button>
                                <button class="btn btn-sm btn-warning btn-gradient fh" type="button" @click.prevent.stop="folder.rename = false"><i class="ion-close-round"></i></button>
                            </span>
                            <i v-else class="ion-load-c spin"></i>
                        </div>
                        </form>
                        <div v-if="folder.rename.error" class="text-sm text-danger">{{ folder.rename.error }}</div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!disable_namespace_ops" class="folder-box add_folder col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3">
            <div class="cardbox text-white bg-gradient-secondary">
                <div class="cardbox-body" v-if="!new_folder_mode">
                    <div class="d-flex justify-content-start align-items-center nowrap" @click="new_folder_form(true)"><!-- height: 37px -->
                        <i class="ion-plus mr-2"></i>
                        <p class="mb-0">Create new folder</p>
                    </div>
                </div>

                <div class="cardbox-body" v-if="new_folder_mode">
                    <form @submit.prevent="create_folder(new_folder_name)">
                    <div class="input-group" ><!-- height: 37px -->
                        <input class="form-control form-control-sm" type="text" v-model="new_folder_name" id="new_folder_name_input"/>

                        <button class="btn btn-sm btn-success btn-gradient ml-1" type="submit" :disabled="new_folder_loading"><i :class="{'ion-checkmark-round': !new_folder_loading, 'ion-load-c spin': new_folder_loading}"></i></button>
                        <button class="btn btn-sm btn-warning btn-gradient ml-1" type="button" @click="new_folder_form(false)"><i class="ion-close-round"></i></button>
                    </div>
                    </form>
                </div>

            </div>
        </div>
    </div>


    <!-- Files -->
    <div class="row" id="files" >
        <div class="col-12" v-if="files.length > 0">

            <div class="cardbox">
                
                <!-- Top bar -->
                <div class="cardbox-heading">

                    <!-- Active filters -->
                    <div class="float-left filters">
                        <span class="btn btn-oval bg-blue-500 mx-1 my-1" v-if="list_filters.types.length > 0">Type: {{ list_filters.types | join }} <i class="ion-close-round ml-2" @click="list_filters.types = []"></i></span>
                    </div>

                    <!-- Action buttons -->
                    <div class="float-right text-right action-buttons">

                        <button 
                            v-if="false"
                            class="btn btn-secondary btn-gradient mx-1 my-1 d-none d-xl-inline-block" 
                            data-toggle="modal" data-target="#filter-modal" data-backdrop=""
                            ><i class="ion-funnel mr-1 icon-lg"></i> 
                            <!-- Overwrite this when merging multiple-clients-per-space, but move to the right  -->
                            Filters
                        </button>

                        <span v-if="!(disable_download || is_mobile)" >
                            <a href="#" v-if="files_multiselect_on" @click.prevent="files_multiselect_on=false" class="mr-2 text-sm">Cancel</a>
                            <!-- Default button when multiselect is off -->
                            <button 
                                v-if="!files_multiselect_on"
                                class="btn btn-success btn-gradient mx-1 my-1" 
                                @click="files_multiselect_on = true"
                                >
                                <i class="ion-arrow-down-a mr-1 icon-lg"></i> Files
                            </button>
                            <!-- Multiselect on but download not started -->
                            <button
                                v-else-if="!files_multidownload_in_progress"
                                class="btn btn-skyflok mx-1 my-1" 
                                @click="download_all_selected()" 
                                >
                                <i class="ion-arrow-down-a mr-1 icon-lg"></i> 
                                Download {{selected_files.length}} files
                            </button>
                            <button
                                v-else
                                class="btn btn-danger mx-1 my-1"
                                @click="download_all_cancel()"
                                >
                                <i class="ion-close mr-1 icon-lg"></i> 
                                Cancel
                            </button>
                        </span>

                        <button v-if="!disable_upload" class="btn btn-primary btn-gradient mx-1 my-1" @click="file_upload_el.click()"><i class="ion-arrow-up-a mr-1 icon-lg"></i> {{ is_mobile ? 'Upload' : 'Files' }}</button>
                        <button v-if="!disable_upload && !is_mobile" class="btn btn-info btn-gradient mx-1 my-1" @click="folder_upload_el.click()"><i class="ion-arrow-up-a mr-1 icon-lg"></i> Folder</button>
                    </div>
                </div>

                <div class="file-list">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th class="width-0" v-if="files_multiselect_on">
                                    <checkbox 
                                        :value="files_select_all" 
                                        @changed="(newval) => files_select_all=newval"
                                        class="mr-0" />
                                </th>
                                <th class="width-0"></th>
                                <th class="text-left nowrap"><span class="sort_header" @click="sort_by('name')">Filename <i class="text-gray-light ml-1" v-if="list_sort.attr==='name'" :class="{'ion-arrow-up-c': list_sort.asc, 'ion-arrow-down-c': !list_sort.asc}"></i></span></th>
                                <th class="d-none d-md-table-cell nowrap"><span class="sort_header" @click="sort_by('extension')">Type <i class="text-gray-light ml-1" v-if="list_sort.attr==='extension'" :class="{'ion-arrow-up-c': list_sort.asc, 'ion-arrow-down-c': !list_sort.asc}"></i></span></th>
                                <th class="d-none d-xl-table-cell nowrap"><span class="sort_header" @click="sort_by('size')">Size <i class="text-gray-light ml-1" v-if="list_sort.attr==='size'" :class="{'ion-arrow-up-c': list_sort.asc, 'ion-arrow-down-c': !list_sort.asc}"></i></span></th>
                                <th class="d-none d-md-table-cell nowrap"><span class="sort_header" @click="sort_by('last_modified')">Uploaded <i class="text-gray-light ml-1" v-if="list_sort.attr==='last_modified'" :class="{'ion-arrow-up-c': list_sort.asc, 'ion-arrow-down-c': !list_sort.asc}"></i></span></th>
                                <th class="d-none d-xl-table-cell nowrap"><span class="sort_header" @click="sort_by('versions_num')" title="Number of previous versions">Versions <i class="text-gray-light ml-1" v-if="list_sort.attr==='versions_num'" :class="{'ion-arrow-up-c': list_sort.asc, 'ion-arrow-down-c': !list_sort.asc}"></i></span></th>
                                <th v-if="!disable_share" class="d-none d-xl-table-cell nowrap"><span class="sort_header" @click="sort_by('shares_num')">Shares <i class="text-gray-light ml-1" v-if="list_sort.attr==='shares_num'" :class="{'ion-arrow-up-c': list_sort.asc, 'ion-arrow-down-c': !list_sort.asc}"></i></span></th>
                                <th class="d-none d-md-table-cell" v-if="!disable_download"></th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody v-for="file in files" :key="file.id" :class="{ open: file.show_details }" :id="`skyflok-file-${file.id}`">
                            <tr 
                                :id="'file-row-'+file.id" 
                                class="file-row" 
                                :class="{'text-gray-light': file.loading, 'highlighted': file.highlighted, 'bg-cyan-50': file.selected}" 
                                @click="(evt) => { if(files_multiselect_on){file.selected=!file.selected; evt.stopPropagation()}  }"
                                :draggable="!disable_namespace_ops"
                                @dragstart="drag($event, file)" 
                                @dragend="dragend($event, file)"
                            >
                                <td v-if="files_multiselect_on" class="width-0 ">
                                    <checkbox  
                                        :value="file.selected" 
                                        @changed="newval => file.selected=newval"
                                        class="mr-0" />
                                </td>
                                <td>
                                    <img :src="file.extension | filetype_img_src" class="file-icon" />
                                </td>
                                <td class="text-left">
                                    <span v-if="!disable_download">
                                        <span v-if="!file.rename" class="text-bold filename" :title="'Click to download ' + file.name">
                                            <span class="file_download_link" @click="download_file(file)">{{ file.name }}<i class="file-download-icon icon ion-arrow-down-a" v-if="!file.loading"></i>
                                                <span v-if="file.loading" class="ml-2">({{ Math.round(file.loading) }}%)</span>
                                            </span>
                                        </span>
                                        <div v-else class="nowrap">
                                            <form @submit.prevent="rename_submit(file)">
                                                <input type="text" :id="'file-rename-'+file.id" class="form-control form-control-sm d-inline-block" style="width: calc(100% - 60px)" v-model="file.rename.name" />
                                                <span v-if="!file.rename.loading">
                                                    <button class="btn btn-sm btn-success btn-gradient" type="submit"><i class="ion-checkmark-round"></i></button>
                                                    <button class="btn btn-sm btn-warning btn-gradient" type="button" @click="file.rename = false"><i class="ion-close-round"></i></button>
                                                </span>
                                                <i v-else class="ion-load-c spin"></i>
                                            </form>
                                            <div v-if="file.rename.error" class="text-sm text-danger">{{ file.rename.error }}</div>
                                        </div>
                                    </span>
                                    <span v-else class="text-muted text-bold" :title="file.name" style="cursor: not-allowed">
                                        {{ file.name }}
                                    </span>
                                </td>
                                <td class="d-none d-md-table-cell"><span v-if="file.extension" class="text-sm text-upper">{{ file.extension }}</span><span v-else class="text-muted">-</span></td>
                                <td class="d-none d-xl-table-cell">
                                    <div v-if="!file.size"><i class="ion-load-c spin text-muted"></i></div>
                                    <span v-else class="text-muted text-sm">{{ file.size | format_bytes }}</span>
                                </td>
                                <td class="d-none d-md-table-cell width-0">
                                    <div v-if="file.last_modified_by === undefined"><i class="ion-load-c spin text-muted"></i></div>
                                    <span v-else class="text-sm"><modified-box v-if="user && user.team" :user-id="file.last_modified_by" :team="user.team" :timestamp="file.last_modified" :pullRight="true" :external-actor="modifiedBoxExternal"></modified-box></span>
                                </td>
                                <td class="d-none d-xl-table-cell">
                                 <div v-if="file.versions_num === undefined"><i class="ion-load-c spin text-muted"></i></div>
                                 <a v-else href="#" @click.prevent="file.show_details == 'versions' ? file.show_details = false : file.show_details = 'versions'" style="color:inherit" title="List all versions">
                                    <span v-if="file.versions_num > 1" data-toggle="popover" data-placement="top" :data-content="'<center>There '+(file.versions_num > 2 ? 'are' : 'is')+' <b>' + (file.versions_num-1)+' previous version'+(file.versions_num > 2 ? 's' : '')+'</b> of <i>' + file.name + '</i><br/>Click to see details.</center>'" data-html="true" data-trigger="hover"><i class="ion-ios-copy-outline icon-lg mr-2"></i>{{ file.versions_num-1 }}</span>
                                    <span v-else class="text-muted">-</span>
                                 </a>
                             </td>
                             <td v-if="!disable_share" class="d-none d-xl-table-cell">
                                 <div v-if="file.shares_num === undefined"><i class="ion-load-c spin text-muted"></i></div>

                                 <a v-else-if="file.shares_num > 0" href="#" @click.prevent="file.show_details == 'shares' ? file.show_details = false : file.show_details = 'shares'" style="color:inherit" title="List shares of this file">
                                    <span v-if="file.shares_num > 0" data-toggle="popover" data-placement="top" :data-content="file.shares_num + ' active share'+(file.shares_num === 1 ? '' : 's')+'<br/>(click to see details)'" data-html="true" data-trigger="hover"><i class="ion-link icon-lg mr-2"></i>{{ file.shares_num }}</span>
                                 </a>
                                 <a v-else @click="open_share_form(file)" class="c-pointer text-muted" title="Share this file">
                                     <i class="ion-plus-round"></i>
                                 </a>
                             </td>
                                <td class="d-none d-md-table-cell" v-if="!disable_download">
                                    <button 
                                        v-if="file.cancel" 
                                        @click="file.cancel()" 
                                        class="btn text-sm btn-danger btn-flat"
                                    >
                                        CANCEL <i class="ion-close-round ml-1"></i>
                                    </button>
                                    <section v-else>
                                        <button
                                            v-if="file.viewer"
                                            class="btn text-sm btn-flat"
                                            :class="[file.viewer.btn_class]"
                                            @click.prevent="open_file(file)"
                                            :title="'Open with ' + file.viewer.label">{{ file.viewer.verb }} <i class="icon-lg ml-2" :class="file.viewer.icon_class"></i></button>
                                        <button v-else class="btn btn-secondary btn-flat disabled" disabled :title="'Cannot preview ' + (file.extension ? file.extension.toUpperCase()  : 'these types of ') + ' files yet :('">-</button>
                                    </section>
                                </td>

                                <td>
                                    <div v-if="!files_multiselect_on" class="dropdown btn-group">
                                        <button class="btn btn-secondary btn-flat" type="button" data-toggle="dropdown" aria-expanded="false" style="font-size: 1rem"><i class="ion-more icon-lg text-muted" style="vertical-align: initial"></i>
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-right" role="menu">
                                            <a v-if="!disable_share" class="dropdown-item" href="#" @click.prevent="open_share_form(file)"><i class="ion-share icon-lg mr-3"></i>Share</a>
                                            <a v-if="!disable_download" class="dropdown-item" href="#" @click.prevent="download_file(file)"><i class="ion-arrow-down-a icon-lg mr-3"></i>Download</a>
                                            <a v-if="!disable_namespace_ops" class="dropdown-item" href="#" @click.prevent="rename_file(file)"><i class="ion-edit icon-lg mr-3"></i>Rename</a>
                                            <a v-if="!disable_namespace_ops" class="dropdown-item" href="#" @click.prevent="copy_move_file(file, 'move_file')"><i class="ion-forward icon-lg mr-3"></i>Move</a>
                                            <a v-if="!disable_namespace_ops" class="dropdown-item" href="#" @click.prevent="copy_move_file(file, 'copy_file')"><i class="ion-plus-circled icon-lg mr-3"></i>Duplicate</a>
                                            <a class="dropdown-item" href="#" @click.prevent="is_mobile ? open_file_info_modal(file) : file.show_details = 'info'"><i class="ion-information-circled icon-lg mr-3"></i>File info</a>
                                            <div v-if="!disable_namespace_ops" class="dropdown-divider"></div>
                                            <a v-if="!disable_namespace_ops" class="dropdown-item text-danger" href="#" @click.prevent="delete_file(file)">
                                                <i v-if="deleteAction && deleteAction.icon" :class="[deleteAction.icon, 'icon-lg mr-3']"></i>
                                                <i v-else class="ion-trash-a icon-lg mr-3" />{{ deleteAction ? deleteAction.label : 'Delete'}}
                                            </a>
                                        </div>
                                    </div>
                                </td>
                            </tr>

                            <tr class="file-loading" v-if="file.loading">
                                <td colspan="9">
                                    <div class="progress">
                                        <div class="progress-bar progress-bar-striped progress-bar-animated"
                                            role="progressbar" :aria-valuenow="file.loading" aria-valuemin="0" aria-valuemax="100" :style="{'width': Math.round(file.loading)+'%'}"></div>
                                    </div>
                                </td>
                            </tr>
                            <tr class="file-details-row" :class="{ open: file.show_details }">
                                <td colspan="9" class="file-details-cell">
                                    <div class="float-right">
                                        <a
                                            class="btn btn-secondary btn-flat btn-sm text-sm"
                                            @click="file.show_details = false"
                                            title="Close info panel"                                        >
                                            Close <i class="ion-close-round ml-2"></i>
                                        </a>
                                    </div>


                                    <ul class="nav nav-pills mb-4 justify-content-center" role="tablist">
                                        <li class="nav-item btn">
                                            <a class="nav-link" :class="{'active': file.show_details === 'versions'}" data-toggle="tab" :href="'#file_' + file.id + '_versions'" @click="file.show_details = 'versions'" role="tab">Version History</a>
                                        </li>
                                        <li class="nav-item btn" v-if="!disable_share">
                                            <a class="nav-link" :class="{'active': file.show_details === 'shares'}" data-toggle="tab" :href="'#file_' + file.id + '_shares'" @click="file.show_details = 'shares'" role="tab" aria-expanded="true">Sharing Status</a>
                                        </li>
                                        <li class="nav-item btn">
                                            <a class="nav-link" :class="{'active': file.show_details === 'info'}" data-toggle="tab" :href="'#file_' + file.id + '_info'" @click="file.show_details = 'info'" role="tab">File Information</a>
                                        </li>
                                    </ul>

                                    <div class="tab-content">

                                        <!-- Shares tab -->
                                        <div v-if="!disable_share" class="tab-pane " :class="{'show active': file.show_details === 'shares'}" :id="'file_' + file.id + '_shares'" role="tabpanel">
                                            <div class="text-center">

                                                <file-sharing-status
                                                    v-if="file.show_details === 'shares'"
                                                    :user="user"
                                                    :file="file"
                                                    :shares_updated="(shares) => { file.shares = shares }">
                                                </file-sharing-status>
                                            </div>
                                        </div>

                                        <!-- Versions tab -->
                                        <div class="tab-pane" :class="{'show active': file.show_details === 'versions'}" :id="'file_' + file.id + '_versions'" role="tabpanel">
                                            <div class="fw text-center">
                                                <file-versions-table
                                                    v-if="file.show_details === 'versions'"
                                                    :file="file"
                                                    :user="user"
                                                    :modifiedBoxExternal="modifiedBoxExternal"
                                                    :open_file_fn="disable_download ? null : open_file"
                                                    :download_file_fn="disable_download ? null : download_file"
                                                    :versions_num_changed="(num) => { file.versions_num = num }">
                                                </file-versions-table>
                                            </div>

                                        </div>

                                        <!-- Info tab -->
                                        <div class="tab-pane" :class="{'show active': file.show_details === 'info'}" :id="'file_' + file.id + '_info'" role="tabpanel">
                                            <table class="table file-details-table"  style="width: auto; margin: 0px auto">
                                                <tbody>
                                                    <tr>
                                                        <td class="text-bold">Name</td>
                                                        <td>{{ file.name }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-bold">Size</td>
                                                        <td>{{ file.size | format_bytes }} <small>({{ Number(file.size).toLocaleString() }} bytes)</small></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-bold">Type</td>
                                                        <td>{{ file.mime_type }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <!-- 'Empty' box -->
    <section v-if="files && files.length == 0 && !loading_filelist">
        <div v-if="disable_upload" class="row">
            <div class="col-4"></div>
            <div class="col-12 col-lg-4">
                <hero-box :bg-class="'bg-gradient-secondary'" :icon="'ion-qr-scanner'" :text="'Empty directory'"></hero-box>
            </div>
        </div>

        <div v-else class="row">
            <div class="col-1"></div>
            <div class="col-12 col-lg-5 ">
                <hero-box :bg-class="'bg-gradient-primary'" :icon="'ion-ios-copy-outline'" :text="'Upload files'" :clicked="()=>{ file_upload_el.click() }"></hero-box>
            </div>
            <div class="col-12 col-lg-5">
                <hero-box :bg-class="'bg-gradient-info'" :icon="'ion-ios-folder-outline'" :text="'Upload a complete folder'" :clicked="()=>{ folder_upload_el.click() }"></hero-box>
            </div>
        </div>

    </section>

    <!-- 'Loading' box -->
    <div class="row" v-if="namespace && namespace.length == 0 && loading_filelist">
        <div class="col-3"></div>
        <div class="col-12 col-lg-6">
            <hero-box :bg-class="'bg-gradient-primary'" :icon="'loader d-block'"></hero-box>
        </div>
    </div>

</div>

<!-- Create Share -->
<div class="modal share-modal" id="share-modal">
    <div class="modal-dialog modal-lg" v-if="!disable_share && entities_to_share && entities_to_share.length > 0">
    <div class="modal-content">
        <div class="modal-header bg-gradient-info text-white">
            <h5 class="mt-0 modal-title">Create Share</h5>
            <button class="close text-white" type="button" @click="close_modal('share-modal')" aria-label="Close"><span>&times;</span></button>
        </div>
        <div class="modal-body">
            <share-form
                :entities="entities_to_share"
                :teamSettings="user && user.team_settings"
                :share-created="share_created"
                :close="create_share_ready"
            />
        </div>
    </div>
    </div>
</div>


<!-- File list filter -->
<div class="modal modal-right fade" id="filter-modal">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="mt-0 modal-title"><span>Filter file list</span></h4>
                <div class="float-right clickable" data-dismiss="modal"><em class="ion-close-round text-soft"></em></div>
            </div>
            <div class="modal-body">

                <p>File types</p>
                <div class="setting-color">
                    <span
                        class="btn btn-sm multi_select_btn rounded d-inline-block"
                        :class="{ 'bg-blue-500': list_filters.types.indexOf(ext) >= 0 }"
                        v-for="(ext, idx) in file_types" :key="idx"
                        @click="toggle_filter('types', ext)">{{ ext }}</span>
                </div>

            </div>
        </div>
    </div>
</div>

<!-- Viewer -->
<div class="modal" id="viewer-modal">
    <div class="modal-dialog modal-lg" style="max-width: 80%" v-if="view_file">
        <div class="modal-content">
            <div class="modal-header bg-gradient-primary text-white">
                <h5 class="mt-0 modal-title"><b>{{view_file.file.name}}</b></h5>
                <button class="close text-white" type="button" @click="()=>{ if(view_file.file && view_file.file.cancel){view_file.file.cancel()} view_file.file.loading = false; view_file = null; close_modal('viewer-modal') }" aria-label="Close"><span>&times;</span></button>
            </div>
            <div class="modal-body">
                <file-preview 
                    :file="view_file.file" 
                    :version_id="view_file.version_id" 
                    :file_list="view_file.file_list"
                    @close="()=>{ if(view_file.file && view_file.file.cancel){view_file.file.cancel()};  view_file.file.loading = false; view_file = null; close_modal('viewer-modal') }"></file-preview>
            </div>
        </div>
    </div>
</div>

<!-- Namespace Tree -->
<div class="modal" id="move-copy-modal">
    <div class="modal-dialog modal-lg" style="max-height: 90%; overflow: auto" v-if="show_folder_tree">
        <div class="modal-content" style="border: none">
            <div class="modal-header bg-gradient-info text-white">
                <h5 class="mt-0 modal-title">{{ show_folder_tree.action.startsWith('copy') ? "Copy" : "Move" }} <b>{{ show_folder_tree.file.name }}</b></h5>
                <button class="close text-white" type="button" @click="show_folder_tree = null; close_modal('move-copy-modal')" aria-label="Close"><span>&times;</span></button>
            </div>
            <div class="modal-body">
                <namespace-tree 
                    :action="show_folder_tree.action" 
                    :target-file="show_folder_tree.file" 
                    :ready="copy_move_ready"
                    :is_user_admin="user.is_admin"
                    :is_client_space_client="user.is_client_space_client">
                </namespace-tree>
            </div>
        </div>
    </div>
</div>

<!-- File info (on mobile) -->
<div class="modal" id="file-info-modal">
    <div class="modal-dialog modal-lg" style="max-height: 90%; overflow: auto" v-if="info_modal_file">
        <div class="modal-content" style="border: none">
            <div class="modal-header bg-gradient-info text-white">
                <h5 class="mt-0 modal-title">
                    <img :src="info_modal_file.extension | filetype_img_src" class="file-icon mr-1" />
                    <b>{{ info_modal_file.name }}</b>
                </h5>
                <button class="close text-white" type="button" @click="close_modal('file-info-modal')" aria-label="Close"><span>&times;</span></button>
            </div>
            <div class="modal-body">
                <file-info-modal
                    :file="info_modal_file"
                    :hide_shares="disable_share"
                    :modifiedBoxExternal="modifiedBoxExternal"
                    :download_file_fn="disable_download ? null : download_file"
                    :user="user">
                </file-info-modal>
            </div>
        </div>
    </div>
</div>

<input type="file" id="file_upload" style="display:none" multiple @change="files_selected" />
<input type="file" id="folder_upload" style="display:none" webkitdirectory @change="folder_selected" />

</section>
</template>


<script>


import { NamespaceService } from '@/services/namespace-service.js'
import { FileVersionService } from '@/services/fileversion-service.js'
import { StorageBackendService } from '@/services/storage_backend-service.js'
import { ShareService } from '@/services/share-service.js'
import { Utils } from '@/helpers/utils.js'
import { bus, events } from '@/helpers/event_bus.js'

import modifiedBox from '@/components/modified-box.vue'
import filePreview from '@/components/file-viewer.vue'
import namespaceTree from '@/components/namespace-tree.vue'
import fileSharingStatus from '@/components/file-sharing-status.vue'
import fileVersionsTable from '@/components/file-versions-table.vue'
import shareForm from '@/components/share-form.vue'
import { FileUploader } from '@/helpers/file-uploader.js'
import heroBox from '@/components/hero-box.vue'
import checkbox from '@/components/checkbox.vue'
import fileInfoModal from '@/components/modals/file-info.vue'

import platform from "platform"
import md5 from 'md5'

// Global limit on the number of parallel file uploads and downloads
const MAX_PARALLEL_UPLOADS = 4
const MAX_PARALLEL_DOWNLOADS = 4

const MAX_FOLDERS_AND_FILES_UPLOAD = 1000
const MAX_PATH_LENGTH = 4096

const NAMESPACE_RELOAD_PERIOD_MS = 10000 // 10 sec
const ACTIVITY_SYNC_PERIOD_MS = 20000 // 20 sec

let ENABLE_NAMESPACE_REFRESH = true
const ENABLE_ACTIVTIY_SYNC = false

export default {

    components: {
        modifiedBox,
        filePreview,
        namespaceTree,
        fileSharingStatus,
        fileVersionsTable,
        shareForm,
        heroBox,
        fileInfoModal,
        checkbox
	},

    props: [
        "user",
        'share-allowed',
        'upload-allowed',
        'download-allowed',
        'namespace-ops-allowed',
        'delete-action',
        'modified-box-external',
        'is_admin_folder',
        'file_uploaded_callback',
        'file_download_started',
        'file_download_succeeded',
        'file_download_error'
    ],

    data() {
        return {
            show_clouds_ready_box: false,
            window: window,
            new_folder_mode: false,
            new_folder_loading: false,
            new_folder_name: null,

            nav_stack: [],
            ignore_hashchange: false,
            loading_filelist: false,

            list_sort: { attr: 'name', asc: true },
            list_filters: { types: [] },
            file_upload_el: null,
            folder_upload_el: null,

            buckets: null,

            uploading_files_ctr: 0,
            upload_queue: [],
            download_queue: [],

            entities_to_share: null,

            view_file: null,

            show_folder_tree: null,

            highlight_file_id: null,
            are_files_dragged: false,

            namespace: [],
            ns_reload_handler: null,
            activity_sync_handler: null,

            is_view_destroyed: false,

            ENABLE_ACTIVTIY_SYNC: ENABLE_ACTIVTIY_SYNC,
            team_members_here: [],

            info_modal_file: null,

            files_multiselect_on: false,
            files_select_all: false,
            files_multidownload_in_progress: false,
        }
    },

    computed: {

        user_team: function(){
            // for watch user_team
            if(!this.user){ return null }
            return this.user.team
        },

        file_types: function(){
            // Collect all different file extensions
            let types = this.files.map(f => f.extension)
            types = Utils.unique_array(types).filter(ext => ext && ext.length).map(ext => ext.toLowerCase())
            types.sort()
            return types
        },

        current_dir: function(){
            // Last item of the nav stack
            return this.nav_stack.length > 0 ? this.nav_stack[this.nav_stack.length-1] : {}
        },

        current_folder_id: function(){
            // Note that the root folder should be 'null' here, but it's represented by '-1' in the namespace
            return (!this.current_dir || this.current_dir.id === -1) ? null : this.current_dir.id
        },

        contents(){
            // Contents of the current folder (both files and folders)
            if(!this.namespace || !this.current_dir){ return [] }
            return this.namespace.filter(en => { return en.parent_id === this.current_folder_id })
        },

        folders: function(){
            // Folders of 'contents', sorted by name
            let folders = this.contents.filter(item => item.entity_type == NamespaceService.ENTITY_TYPE_FOLDER)
            
            return Utils.sort_objects(folders, 'name')
        },

        files(){
            // Files of 'contents', filtered and sorted
            let files = this.contents.filter(file => file.entity_type == NamespaceService.ENTITY_TYPE_FILE && file.no_versions !== true)
            
            if(this.list_filters.types.length){
                files = files.filter(file => file.extension && this.list_filters.types.indexOf(file.extension) >= 0)
            }
            
            return Utils.sort_objects(files, this.list_sort.attr, this.list_sort.asc)
        },

        selected_files(){
            return this.files.filter(f => f.selected)
        },

        is_mobile(){
            return Utils.is_mobile()
        },

        upload_queue_length(){ return this.upload_queue.length },
        download_queue_length(){ return this.download_queue.length },

        disable_upload(){ return this.uploadAllowed === false },
        disable_download(){ return this.downloadAllowed === false },
        disable_share(){ return this.shareAllowed === false || (this.user && this.user.team_settings && this.user.team_settings.config.share_enabled === false) },
        disable_namespace_ops(){ return this.namespaceOpsAllowed === false },

        folder_shares_enabled(){
            return this.user && this.user.team_settings && this.user.team_settings.config.share_enabled
        },

        cached_ns_key(){
            // For namespaces without password: 'ns-default', 'ns-admin_folder'
            // Namespaces with password: 'ns-client_space-42g3uiz423hv' (last section is the md5 of the pass)
            return "ns-" + (NamespaceService.ns_type ? NamespaceService.ns_type : 'default') + (NamespaceService.ns_key ? '-'+md5(NamespaceService.ns_key) : "" )
        },

        is_desktop_safari(){

            return platform &&
                    platform.os &&
                    platform.os.family &&
                    platform.os.family.toLowerCase() === "os x" &&
                    platform.name &&
                    platform.name.toLowerCase() === "safari"

        },

        is_client_space_client(){
            if(!this.user){
                return null
            }
            if(this.user.is_client_space_client === undefined){
                return false
            }
            return this.user.is_client_space_client
        }

    },

    watch: {

        upload_queue_length(newVal, oldVal){
            if(newVal > 0){
                window.onbeforeunload = function(){ return "Some files are still uploading, please wait before you leave!" }
            }
            if(oldVal > 0 && newVal === 0){
                //Utils.show_success("All files uploaded successfully.")
                window.onbeforeunload = null
            }

            // Count how many files are uploading right now
            const uploading_num = this.upload_queue.filter(el => { return el.is_uploading === true }).length

            // Find out if there are theoratical free slots
            const free_slots = MAX_PARALLEL_UPLOADS - uploading_num

            // Start the upload until there are non-uploading files left
            for(var i=0 ; i<Math.min(free_slots, this.upload_queue.length-uploading_num) ; ++i){
                let files_waiting = this.upload_queue.filter(el => { return el.is_uploading === false })
                if(files_waiting.length === 0){ break }
                let new_file_to_start = files_waiting[0]
                new_file_to_start.is_uploading = true
                this.upload_file(new_file_to_start.file, new_file_to_start.userfile)
            }
        },

        download_queue_length(newVal, oldVal){
            if(newVal > 0){
                window.onbeforeunload = function(){ return "Some files are still downloading, please wait before you leave!" }
            }

            if(oldVal > 0 && newVal === 0){
                Utils.show_success("All files downloaded successfully.")
                window.onbeforeunload = null

                if(this.files_multiselect_on && this.files_multidownload_in_progress){
                    // close multiselect when all files completed downloading
                    this.files_multiselect_on = false
                    this.files_multidownload_in_progress = false
                }
            }

            // Count how many files are uploading right now
            const downloading_num = this.download_queue.filter(el => { return el.is_downloading === true }).length

            // Find out if there are theoratical free slots
            const free_slots = MAX_PARALLEL_DOWNLOADS - downloading_num

            // Start the download until there are non-downloading files left
            for(var i=0 ; i<Math.min(free_slots, this.download_queue.length-downloading_num) ; ++i){
                let files_waiting = this.download_queue.filter(el => { return el.is_downloading === false })
                if(files_waiting.length === 0){ break }

                let new_file_to_start = files_waiting[0]
                new_file_to_start.is_downloading = true
                this.download_file(new_file_to_start.file)
            }
        },

        'current_folder_id': function(){
            this.load_folder_contents()
        },

        disable_upload(){
            if(this.disable_upload){
                // Disable auto-refresh if the namespace cannot change
                ENABLE_NAMESPACE_REFRESH = false
            }
        },

        is_client_space_client(){
            // Load the buckets if we know for sure that the user is not a client
            // (before the user object is set, this.is_client_space_client is NULL)
            if(this.is_client_space_client === false){
                StorageBackendService.get_buckets().then( res => {
                    this.buckets = res.body
                }).catch(err_resp => {
                    console.error("Failed to retrieve buckets: ", err_resp)
                })
            }
        },

        selected_files(){
            return this.files.filter(f => f.selected)
        },

        files_select_all(newval){
            // 'Select All' checkbox is changed
            this.files.forEach(f => f.selected = newval)
        },

        files_multiselect_on(newval){
            // Multi-select turned on or off
            this.files_select_all = newval
        }
    },

    created(){

        // Load namespace from local cache
        this.load_cached_namespace()
        this.load_folder_from_hash()

        // Show warning for outdated Safari users
        if(this.is_desktop_safari && !localStorage.getItem('safari_warning')){
            const version_string = platform.version.toLowerCase()
            if(version_string.startsWith("10.") || version_string.startsWith("11.")){
                const sticky = true
                Utils.show_warning("<b>Warning!</b> Other SkyFlok users reported file download problems in this browser (Safari 10 & 11).<br/>If you cannot download files, please try to upgrade Safari to the latest version, or use Chrome, Firefox or Opera!", sticky)
                localStorage['safari_warning'] = 1
            }
        }

        // Set up event listeners
        var self = this
        window.onhashchange = function(){
            if(self.ignore_hashchange){
                // The hash change was triggered by our code, it should not be acted upon.
                // Reset the flag
                self.ignore_hashchange = false
            }
            else{
                self.load_folder_from_hash()
            }
        }
        window.onblur = this.stop_periodic_refresh
        window.onclose = this.stop_periodic_refresh

        // Event listeners
        bus.$on(events.HIGHLIGHT_FILE, function(file_id){
            self.highlight_file_id = file_id
            self.highlight_file()
            /*
            setTimeout(()=>{
                const highlighted_file = self.namespace.find(f => f.id == file_id)
                if(!highlighted_file){ return }
                highlighted_file.highlighted = false; 
                self.highlight_file_id=null
            }, 1000)
            */
        })

        bus.$on(events.REMOVE_FROM_FILELIST, function(el){
            var idx = self.namespace.findIndex(c => { return el.id === c.id })
            if(idx < 0){ return }
            self.namespace.splice(idx, 1)
            self.cache_namespace()
        })

        bus.$on(events.FILELIST_CHANGE_FOLDER_TO, function(location_hash){
            self.ignore_hashchange = true
            window.location.hash = location_hash
            self.load_folder_from_hash()
        })

        bus.$on(events.FILELIST_CHANGE_FOLDER_TO_ID, function(folder_id, path){
            self.change_folder_to(folder_id ? self.namespace.find(f => f.id == folder_id) : {id: -1})
            self.ignore_hashchange = true
            window.location.hash = path
        })

    },

    mounted(){
        var self = this
        this.file_upload_el = document.getElementById("file_upload")
        this.folder_upload_el = document.getElementById("folder_upload")

        this.reload_namespace()

        // Check if just came from cloud selector
        if(localStorage.getItem("storage_setup_ready") !== null){
            this.show_clouds_ready_box = true
            localStorage.removeItem("storage_setup_ready")
        }

        // Move the modals directly to the Body, so they are above the backdrop
        /*
        console.log("Trying to move modals to body")
        const modals = ["share-modal", "filter-modal", "viewer-modal", "move-copy-modal", "file-info-modal"]
        modals.forEach(m => {
            const modal_el = document.getElementById(m)
            if(modal_el){
                document.body.appendChild(modal_el)
            }
            else{
                console.warn("Modal not found: ", m)
            }

        })
        */

        if(ENABLE_ACTIVTIY_SYNC){
            this.activity_sync()
        }

        if(ENABLE_NAMESPACE_REFRESH && !this.ns_reload_handler){
            this.ns_reload_handler = setInterval(this.reload_namespace, NAMESPACE_RELOAD_PERIOD_MS)
        }

        if(ENABLE_ACTIVTIY_SYNC && !this.activity_sync_handler){
            this.activity_sync_handler = setInterval(this.activity_sync, ACTIVITY_SYNC_PERIOD_MS)
        }

        window.onfocus = function(){

            if(self.is_view_destroyed){ return }

            // Load the namespace fresh when the user comes back to this tab
            if(ENABLE_NAMESPACE_REFRESH){
                self.reload_namespace()
            }

            if(ENABLE_NAMESPACE_REFRESH && !self.ns_reload_handler){
                self.ns_reload_handler = setInterval(self.reload_namespace, NAMESPACE_RELOAD_PERIOD_MS)
            }
            if(ENABLE_ACTIVTIY_SYNC && !self.activity_sync_handler){
                self.activity_sync_handler = setInterval(self.sync_activity, ACTIVITY_SYNC_PERIOD_MS)
            }
        }
        window.onblur = this.stop_periodic_refresh
        window.onclose = this.stop_periodic_refresh

        this.$nextTick(() => {
            $('[data-toggle="popover"]').popover();
        })
    },


    destroyed(){
        this.is_view_destroyed = true
        this.stop_periodic_refresh()

        // Clear the hashchange listener
        window.onhashchange = null
    },

    methods: {

        stop_periodic_refresh(){
            // Stop periodically updating namespace & sync activity when the tab loses focus
            if(this.ns_reload_handler){
                clearInterval(this.ns_reload_handler)
                this.ns_reload_handler = null
            }

            if(ENABLE_ACTIVTIY_SYNC){
                // Signal that user is still here but inactive
                NamespaceService.sync_activity(this.current_folder_id, false)
            }

            if(this.activity_sync_handler){
                clearInterval(this.activity_sync_handler)
                this.activity_sync_handler = null
            }
        },

        get_folderbox_class(folder){
            const is_empty = this.namespace.filter(en => { return en.parent_id === folder.id }).length == 0
            this.$set(folder, 'is_empty', is_empty)
            const color_class = 'color-' + Utils.string_to_color(folder.name) + (is_empty ? '-200' : '-400')
            let classObj = {}
            classObj[color_class] = true
            return classObj
        },

        cache_namespace(){
            if(!this.namespace){ return }
            const namespace_sanitized = this.namespace.map(el => {
                let entity_type = 0;
                switch(el.entity_type){
                    case NamespaceService.ENTITY_TYPE_FOLDER: entity_type = 0; break;
                    case NamespaceService.ENTITY_TYPE_FILE: entity_type = 1; break;
                    default: entity_type = el.entity_type
                }
                // Serialize namespace to a much more compact representation
                return [el.id, el.name, entity_type, el.parent_id]
            })

            try{
                localStorage.setItem(this.cached_ns_key, JSON.stringify(namespace_sanitized))
            }catch(ex){
                console.error("Error caching namespace: ", ex)
                throw ex
            }
        },

        load_cached_namespace(){
            const ns_string = localStorage.getItem(this.cached_ns_key, null)
            if(!ns_string){ return }

            try {
                let namespace = []
                JSON.parse(ns_string).forEach(row => {
                    if(row.length !== 4){
                        console.error("Error parsing cached namespace: array should be 4 long, found " + row.length, row)
                        return
                    }

                    // Do not load pending stuck files
                    // if(row[0] < 0){ return }

                    let entity_type = 0;
                    switch(row[2]){
                        case 0: entity_type = NamespaceService.ENTITY_TYPE_FOLDER; break;
                        case 1: entity_type = NamespaceService.ENTITY_TYPE_FILE; break;
                        default: entity_type = row[2]
                    }
                    namespace.push({
                        id: row[0],
                        name: row[1],
                        entity_type: entity_type,
                        parent_id: row[3]
                    })
                })

                this.namespace = namespace

            } catch (error) {
                console.error("Cached namespace is corrupt, removing")
                localStorage.removeItem("ns-" + NamespaceService.ns_type)
            }


        },

        reload_namespace(){
            if(!ENABLE_NAMESPACE_REFRESH){ return }

            // Reloads the fresh contents of the whole namespace
            this.loading_filelist = true

            NamespaceService.get_all_namespace().then(res => {
                this.loading_filelist = false

                let fresh_contents = res.body
                fresh_contents.forEach(c => {
                    const existing_el = this.namespace.find(file => { return file.id === c.id })

                    if(!existing_el){
                        // New file/folder!
                        //this.init_contents([c])
                        this.namespace.push(c)
                        return
                    }

                    // Existing file/folder, update all attributes of 'existing_el' from 'c' (e.g. name, )
                    for(var key in c){
                        if(key != 'id' && Object.prototype.hasOwnProperty(c, key) && Object.prototype.hasOwnProperty(existing_el, key)){
                            existing_el[key] = c[key]
                        }
                    }

                })

                // Find the files & folders which are in the cached namespace but not present
                // in the fresh list (e.g. has been deleted recently)
                // Except for files which are being uploaded at the moment (negative id)
                this.namespace.filter(en => { return en.id > 0 }).forEach(el => {
                    const idx = fresh_contents.findIndex(c => { return c.id === el.id })
                    if(el.id >= 0 && idx < 0){
                        // 'el' should be deleted
                        const ns_idx = this.namespace.findIndex(c => { return c.id === el.id })
                        this.namespace.splice(ns_idx, 1)
                    }
                })

                this.cache_namespace()
                this.load_folder_contents()
            }).catch(err => {
                console.error("Error in NamespaceService.get_all_namespace: ", err)
                Utils.show_error("Error reloading files & folders from server! Please refresh the page in a few minutes.")
                this.loading_filelist = false
            })

        },

        init_contents(arr){
            if(arr === undefined){
                arr = this.contents
            }
            arr.forEach(el => {
                switch(el.entity_type){
                    case NamespaceService.ENTITY_TYPE_FILE:
                        this.init_file(el)
                        break
                    case NamespaceService.ENTITY_TYPE_FOLDER:
                        this.init_folder(el)
                        break;
                }
            })
        },

        init_file: function(basic_file, force_update){
            // When force_update is true, file extension, mime type and viewer are updated even if they were set before
            if(force_update === undefined){ force_update = false  }
            if(basic_file.show_details === undefined){ this.$set(basic_file, 'show_details', false) }
            if(basic_file.loading === undefined){ this.$set(basic_file, 'loading', false) }
            if(basic_file.selected === undefined){ this.$set(basic_file, 'selected', false) }
            if(force_update || !basic_file.extension){ this.$set(basic_file, 'extension', Utils.get_file_extension(basic_file.name)) }
            if(force_update || !basic_file.mime_type){ this.$set(basic_file, 'mime_type', Utils.get_mime_type(basic_file.extension)) }
            if(force_update || !basic_file.viewer){ this.$set(basic_file, 'viewer', Utils.get_file_viewer(basic_file, this.is_mobile, platform.name.toLowerCase())) }
        },

        init_folder: function(basic_folder, force_update){
            // When force_update is true, folder color is updated even if they were set before
            if(force_update === undefined){ force_update = false  }
            if(basic_folder.dragover === undefined){ this.$set(basic_folder, 'dragover', false) }
            basic_folder.color = Utils.string_to_color(basic_folder.name)
        },

        load_folder_contents: function(){
            // When contents change, reload the displayed file details
            this.init_contents()
            this.load_file_versions()
            this.load_shares_num()
        },

        load_file_versions(){
            // Loads the versions of the currently displayed files
            let file_ids = this.files
                .filter(f => { return f.id > 0 })
                .map(file => { return file.id })

            if(file_ids.length === 0){ return }

            FileVersionService.get_latest_info_with_count(file_ids).then(res => {
                const latest_with_count = res.body
                if(!latest_with_count){ return }

                this.files.forEach(file => {
                    const version = latest_with_count.find(i => { return i.file_id === file.id })
                    if(!version){ return }

                    this.$set(file, 'versions_num', version.versions_num)
                    this.$set(file, 'size', version.size)
                    this.$set(file, 'last_modified_by', version.user_id)
                    this.$set(file, 'last_modified', version.timestamp)
                })

                this.$nextTick(()=>{ $('[data-toggle="popover"]').popover() })

                // Mark files without versions
                this.files.filter(f => { return f.id > 0 }).filter(f => { return !f.versions_num }).forEach(file => {
                    this.$set(file, 'no_versions', true)
                    console.info("File "+file.name+" (ID: "+file.id+") has no versions", file)
                })
            }).catch(err => {
                console.error("Error loading latest with count: ", err)
            })
        },

        load_shares_num(){
            // Loads the number of active shares of the currently displayed files
            if(this.disable_share){ return }

            let file_ids = this.files
                .filter(f => { return f.id > 0 })
                .map(file => { return file.id })
            if(file_ids.length === 0){ return }

            ShareService.get_shares_num(file_ids).then(res => {
                const share_nums = res.body

                // Set the found shares num or 0
                this.files.forEach(file => {
                    const shares = share_nums.find(record => { return file.id === record.file_id })
                    this.$set(file, 'shares_num', shares ? shares.active_shares_num : 0)
                })
                this.$nextTick(()=>{ $('[data-toggle="popover"]').popover() })

            }).catch(err => {
                console.error("Error loading shares num: ", err)
            })
        },

        change_folder_to: function(new_folder){

            // If the new folder is already on the top of the stack (=we are standing there), ignore
            if(this.nav_stack[this.nav_stack.length-1].id === new_folder.id){
                return
            }
            // Close new folder box if empty
            this.new_folder_mode = false
            // Close multiselect (do not cancel ongoing downloads)
            this.files_multiselect_on = false

            // Handle nav stack
            // check if the new folder is on the stack (= jump back)
            const el_idx = this.nav_stack.findIndex(el => { return el.id === new_folder.id })
            if(el_idx >= 0){
                // A folder on the stack is selected, clear everything after the current el
                // E.g. when the stack is this: [home] -> Folder1 -> Folder2 -> Folder3
                // and the user clicks on Folder1, then delete Folder2 and Folder3
                this.nav_stack.splice(el_idx+1)
            }
            else{
                // A subfolder of the previous folder is selected, add it to the stack
                this.nav_stack.push(new_folder)
            }

            // Set the hash to the path string
            let new_path = ""
            this.nav_stack.forEach(f => { if(f.id && f.id > 0){ new_path += "/"+ encodeURIComponent(f.name) } })

            this.ignore_hashchange = true
            window.location.hash = new_path
        },

        load_folder_from_hash(){
            // Read path from location hash (part of the URL after hashmark, e.g. '.../files#//Test/Lorem')
            let initial_path = window.location.hash.substring(1, window.location.hash.length).split("/").map(part => {return decodeURIComponent(part)} ).join("/").trim()
            // cut beginning '/'s
            while(initial_path.length > 0 && initial_path[0] === '/'){
                initial_path = initial_path.substr(1)
            }


            if(initial_path === '' || initial_path === '/'){
                // Root
                this.nav_stack = [{ id: -1, path: "", parent_id: null, name: "" }]
                return;
            }

            // TODO resolve path from this.namespace
            NamespaceService.resolve_path(initial_path).then(res => {
                const folders_in_path = res.body
                // Check if we couldn't resolve the whole path (e.g. folder(s) in the path had been renamed or deleted)
                if(initial_path.split('/').length !== folders_in_path.length){
                    const valid_path = folders_in_path.map(f => { return f.name }).join('/')
                    Utils.show_error("Path '<b>"+initial_path+"</b>' doesn't exist any more, loading path <b>" + valid_path + "</b>")
                    // Change hash to the valid path
                    this.ignore_hashchange = true
                    window.location.hash = valid_path

                }
                // Construct nav stack
                let nav_stack = [{ id: -1, path: "", parent_id: null, name: "" }]
                folders_in_path.forEach(folder => { nav_stack.push(folder) })
                this.nav_stack = nav_stack

            }).catch(err => {
                Utils.show_error("Error loading "+initial_path+", redirecting to Home")
                window.location.hash = ""
                this.nav_stack = [{ id: -1, path: "", parent_id: null, name: "" }]
                console.error(err)
            })
        },

        activity_sync(){
            // TODO do NOT call this when the client is watching a client space!
            if(ENABLE_ACTIVTIY_SYNC && this.user && this.user.user_id !== undefined && this.user.team && this.user.team.length > 1){
                NamespaceService.sync_activity(this.current_folder_id, true).then(res => {
                    this.team_members_here = res.body
                })
            }
        },

        folderbox_dropdown(trigger_el_id){
            $('#'+trigger_el_id).dropdown('toggle')
        },

        something_dragged(evt){
            if(this.disable_upload){ return; }
            // Disable drag&drop when the account is not set up yet
            if(this.buckets && this.buckets.length == 0){ return; }

            this.are_files_dragged =
                evt.dataTransfer &&
                evt.dataTransfer.types &&
                evt.dataTransfer.types.length === 1 &&
                evt.dataTransfer.types[0].toLowerCase() == 'files'
        },

        something_dropped(evt){
            if(this.disable_upload){ return; }
            this.are_files_dragged = false
            this.files_selected(evt)
        },

        drag(evt, entity){
            if(this.disable_namespace_ops){ return }
            evt.dataTransfer.setData("text/plain", JSON.stringify(entity))
            evt.dataTransfer.setData("application/vnd.skyflok", JSON.stringify({is_skyflok_entiy: true}))
            evt.dataTransfer.dropEffect = "move"
            bus.$emit(events.FILE_FOLDER_DRAG_START)
        },

        dragend(){
            if(this.disable_namespace_ops){ return }
            bus.$emit(events.FILE_FOLDER_DRAG_END)
        },

        drop(evt, folder){
            if(this.disable_namespace_ops){ return }

            folder.dragover = false
            if(!evt.dataTransfer || !evt.dataTransfer.getData("text")){ return }
            const dropped = JSON.parse(evt.dataTransfer.getData("text"))
            if(!dropped || !dropped.id || !dropped.entity_type || !folder){ return }
            // Prevent moving into the already parent or itself
            if(dropped.parent_id === folder.id || dropped.id === folder.id){ return }

            var dropped_entity = this.contents.find(el => { return el.id === dropped.id })
            this.$set(dropped_entity, 'loading', true)

            var self = this
            NamespaceService.move_entity(dropped.id, folder.id).then(() => {
                bus.$emit(events.SHOW_ALERT, {message: dropped.name + " moved successfully to " + (folder.name || "HOME")})
                dropped_entity.loading = false
                // find file or folder and remove it
                var idx = self.namespace.findIndex(el => { return el.entity_type === dropped.entity_type && el.id === dropped.id })
                if(idx < 0){ console.error("Cannot find moved entity ", dropped); return }
                self.namespace.splice(idx, 1)
                self.cache_namespace()
            }).catch(err => {
                console.error(err)
                dropped_entity.loading = false
                bus.$emit(events.SHOW_ALERT, {message: "Failed to move " + dropped.name + " to " + (folder.name || "HOME") + ": " + err.body.message, type: "danger"})
            })
        },

        highlight_file(){
            if(!this.highlight_file_id){ return; }
            var file = this.files.find(f => { return f.id == this.highlight_file_id })
            if(file){
                // un-highlight all files
                this.files.forEach(f => { this.$set(f, 'highlighted', false) })
                this.$set(file, 'highlighted', true)

                $(".main-container")[0].scrollTop = 0
                $(".main-container").animate({
                    scrollTop: ($("#file-row-"+file.id).offset().top - 200)
                }, 500)
            }
        },

        remove_file_highlight(){
            this.namespace.forEach(f => {
                this.$delete(f, 'highlighted')
            })
        },

        copy_move_ready: function(result){

            if(result.success){
                // In a few cases we need to refresh the current file list:
                // - a file/folder was copied here (add new file/folder)
                // - a file/folder was moved away from here (remove file/folder)

                let success_msg = ""
                switch(result.action){
                    case "copy_file":
                        success_msg = result.targetEntity.name + " copied successfully";
                        break;
                    case "move_file":
                        success_msg = result.targetEntity.name + " moved successfully";
                        break;
                    case "copy_folder": success_msg = result.targetEntity.name + " copied successfully"; break;
                    case "move_folder":
                        success_msg = result.targetEntity.name + " moved successfully";
                        break;

                    default: break;
                }
                Utils.show_success(success_msg)
            }

            this.show_folder_tree = false
            $("#move-copy-modal").modal('hide')
        },

        copy_move_file: function(file, action){
            if(this.disable_namespace_ops){ return }
            this.show_folder_tree = {
                action: action,
                file: file
            }
            $("#move-copy-modal").modal('show')
        },

        copy_move_folder: function(folder, action){
            if(this.disable_namespace_ops){ return }
            this.show_folder_tree = {
                action: action,
                folder: folder
            }
            $("#move-copy-modal").modal('show')
        },

        rename_file: function(file){
            if(this.disable_namespace_ops){ return }
            this.$set(file, 'rename', {
                name: file.name,
                id: file.id,
                loading: false,
                error: false
            })

            // Select the name part (before extension)
            this.$nextTick(()=>{
                this.select_file_name("file-rename-"+file.id, file.rename.name)
            })
        },

        rename_submit: function(file){
            // 'file' can be either a File or a Folder!
            if(this.disable_namespace_ops){ return }

            file.rename.loading = true
            file.rename.error = false
            var self = this

            NamespaceService.rename_file(file.id, file.rename.name).then(res => {
                // apply change
                file.name = res.body.name;
                // hide form
                file.rename = false

                if(file.entity_type === NamespaceService.ENTITY_TYPE_FILE){
                    this.init_file(file, true)
                }
                else if(file.entity_type === NamespaceService.ENTITY_TYPE_FOLDER){
                    this.init_folder(file, true)
                }
            })
            .catch(err => {
                console.error(err)
                file.rename.loading = false;
                file.rename.error = err.body.message
                self.select_file_name("file-rename-"+file.id, file.rename.name)
            })
        },

        select_file_name: function(el_id, filename){
            let field = document.getElementById(el_id)
            if(!field){ return }
            const start = 0;
            const tmp = filename.lastIndexOf(".")
            const end = tmp < 0 ? filename.length : tmp
            if(field.createTextRange){
                var selRange = field.createTextRange();
                selRange.collapse(true);
                selRange.moveStart('character', start);
                selRange.moveEnd('character', end);
                selRange.select();
                field.focus();
            } else if( field.setSelectionRange ) {
                field.focus();
                field.setSelectionRange(start, end);
            } else if( typeof field.selectionStart != 'undefined' ) {
                field.selectionStart = start;
                field.selectionEnd = end;
                field.focus();
            }

        },

        close_modal: function(el_id){
            $("#"+el_id).modal("hide")
        },

        toggle_filter: function(key, ext){
            switch(key){
                case 'types':
                    {
                        const arr = this.list_filters.types
                        const tmp = arr.indexOf(ext);
                        tmp < 0 ? arr.push(ext) : arr.splice(tmp, 1)
                        break;
                    }
            }
        },

        sort_by: function(attr){
            if(attr === this.list_sort.attr){ this.list_sort.asc = !this.list_sort.asc }
            else{ this.list_sort.attr = attr; this.list_sort.asc = true }
        },

		delete_file: function(file){
            if(this.disable_namespace_ops){ return }
            var self = this

            file.loading = true
            if(!this.disable_share){
                // Check if the file is shared and display warning before deleting it
                ShareService.get_shares_num(file.id).then(res => {
                    if(res.body && res.body.length > 0){
                        let record = res.body.find(rec => { return rec.file_id === file.id})
                        if(record == null || record.active_shares_num == 0 || confirm(file.name + " is shared, if you delete it people will lose access. Do you want to delete?")){
                            NamespaceService.delete_entity(file.id).then(function(){
                                const idx = self.namespace.findIndex(el => { return el.id === file.id })
                                if(idx < 0){ console.error("Cannot find the file/folder to remove from local array", file) }
                                self.namespace.splice(idx, 1)
                                Utils.show_success(file.name + " moved to Recycle Bin")
                                self.cache_namespace()
                            })
                            .catch(err => {
                                console.error(err)
                                Utils.show_error("Error deleting " + file.name)
                            })
                        }
                        else{
                            // User cancelled deleting shared entity
                            file.loading = false
                        }
                    }
                    else{
                        throw "Error getting number of shares of " + file.name + " (id: "+file.id+")"
                    }

                }).catch(err => {
                    console.error(err)
                })
            }
            else{
                // When sharing is not allowed, the actor might not be a SkyFlok user (e.g. Client Spaces),
                // therefore Share Service would never return a HTTP 200. This 'else' branch is a dirty workaround
                // to avoid this from happening.
                NamespaceService.delete_entity(file.id).then(function(){
                    const idx = self.namespace.findIndex(el => { return el.id === file.id })
                    if(idx < 0){ console.error("Cannot find the file/folder to remove from local array", file) }
                    self.namespace.splice(idx, 1)
                    Utils.show_success(file.name + " moved to Recycle Bin")
                    self.cache_namespace()
                })
                .catch(err => {
                    console.error(err)
                    Utils.show_error("Error deleting " + file.name)
                })
            }
		},

        files_selected: function(evt, files, parent_folder_id){
            // Called when files are picked via the system file picker dialog or dropped to the File List view
            // Adds valid files (not folders or other dropped shit) to the upload queue

            if(this.disable_upload){ return }

            files = files ? files : evt.target.files || evt.dataTransfer.files;

            if (!files || !files.length){ return; }

            var self = this

            for(var i=0 ; i<files.length ; ++i){
                const userfile = files[i]

                if(!userfile.name || userfile.name === ''){
                    Utils.show_error("File '"+userfile.name+"' has no name!")
                    continue
                }
                if(!userfile.size || userfile.size === 0){
                    Utils.show_error("Empty file '"+userfile.name+"' cannot be uploaded!")
                    continue
                }

                // Try to read the file so we can reject dropped folders
                var reader = new FileReader()
                reader.onloadend = function(evt){
                    // Finished reading generation
                    const buffer = evt.target.result

                    if(!buffer || buffer.byteLength === 0){
                        // This is a folder!
                        Utils.show_warning("<b>Please drop files here, not a folder.</b> Folder upload is coming soon!")
                        return;
                    }

                    // Try to find existing FileList item
                    let file_record = self.files.find( f => { return f.name.toLowerCase() === userfile.name.toLowerCase() } )
                    if(!file_record){
                        // New file upload, we need to construct the file list item manually
                        const temp_file_id =  (++self.uploading_files_ctr) * -1
                        file_record = {
                            id: temp_file_id,
                            entity_type: NamespaceService.ENTITY_TYPE_FILE,
                            name: userfile.name,
                            size: userfile.size,
                            parent_id: parent_folder_id ? parent_folder_id : self.current_folder_id,
                            loading: true,
                            last_modified_by: self.user.id,
                            versions_num: 0,
                            shares_num: 0
                        }
                        self.init_file(file_record)

                        // Add to file list
                        self.namespace.push(file_record)
                    }
                    else{
                        // it's an existing File from the list, just update its size
                        file_record.loading = true
                        file_record.size = userfile.size
                    }

                    self.upload_queue.push({
                        file: file_record,
                        userfile: userfile,
                        is_uploading: false
                    })
                }
                reader.readAsArrayBuffer(userfile.slice(0, 10))
            }
            this.file_upload_el.value = ""
        },

        folder_selected(evt) {
            //Called when folders are picked via the system folder picker dialog.
            if(this.disable_upload){ return }

            const files = Array.from(evt.target.files)

            if(!files || !files.length){ return; }

            /**
             * folder_paths[] contains the paths that the files from the evt has. I.E ["rootfolder", rootfolder/subfolder", "rootfolder/subfolder/subsubfolder"] if
             * every folder contains one or more files. If for example "subfolder" wouldn't contain file(s), folder_paths would be ["rootfolder", "rootfolder/subfolder/subsubfolder"].
             * Note that if any folder name has an illegal character in it, the backend will automatically replace it with an underscore '_'
             */
            const paths = {}
            for (var i=0 ; i<files.length ; ++i) {
                const filepath = files[i].webkitRelativePath
                const folderpath = filepath.substring(0, filepath.lastIndexOf('/'))
                if(folderpath.length > MAX_PATH_LENGTH){
                    Utils.show_error(`Paths with more than ${MAX_PATH_LENGTH} characters are not supported! Problematic folder: ${folderpath}`)
                    return;
                }
                paths[folderpath] = true
            }
            // Replace ':' with '_'. This is necessary because macOS allows '/' to be in file and folder names,
            // but the browsers replace it with ':' before giving access to the file or folder to the webapp.
            // Namespace Service converts ':' to '_' automatically, so the returned folder names will have '_' in them
            // and therefore not found later whne 
            const folder_paths = Object.keys(paths).map(path => path.replace(/:/g, '_'))

            if(files.length + folder_paths.length > MAX_FOLDERS_AND_FILES_UPLOAD){
                Utils.show_error(`Folder uploads are currently limited to ${MAX_FOLDERS_AND_FILES_UPLOAD}. Folder ${folder_paths[0]} contains ${files.length + folder_paths.length} files and folders.`)
                return; 
            }

            // Create folders first
            const parent_id = this.current_dir.id
            NamespaceService.create_folders(folder_paths, parent_id).then(res => {
                let new_folders = res.body

                // Create dict that stores ids of all created directory paths
                let folder_paths = {}

                // Loops over all folders in response and adds the paths to our directory of folder paths
                for (var new_folder of new_folders) {
                    let new_folder_path = new_folder["requested_path"]
                    let new_folder_id = new_folder["id"]
                    folder_paths[new_folder_path] = new_folder_id

                    // Add to the namespace
                    this.init_folder(new_folder)
                    this.namespace.push(new_folder)
                }

                // Step into the first folder
                if(new_folders.length > 0){
                    this.change_folder_to(new_folders[0])
                }

                // Uploads the files from all the folders, to the correct paths.
                files
                    .filter(f => f.name && f.size > 0)
                    .forEach(userfile => {

                        var self = this

                        // Look for the parent path with '_' instead of ':' (relevant only when the folder name had a '/' in it, macOS only)
                        const path = userfile.webkitRelativePath.replace(/:/g, '_')
                        const parent_path = path.substring(0, path.lastIndexOf("/"))
                        const parent_id = folder_paths[parent_path]

                        //if folder_paths[parent_path] is undefined skip file, log error
                        if(parent_id === undefined) {
                            Utils.show_error("File '"+userfile.name+"' has no path!")
                            console.error("File '"+userfile.name+"': parent_id was undefined", userfile, folder_paths)
                            return
                        }

                        // Construct the file list item manually
                        const temp_file_id =  (++this.uploading_files_ctr) * -1
                        let file_record = {
                            id: temp_file_id,
                            entity_type: NamespaceService.ENTITY_TYPE_FILE,
                            name: userfile.name,
                            size: userfile.size,
                            parent_id: parent_id,
                            loading: true,
                            last_modified_by: self.user.id,
                            versions_num: 0,
                            shares_num: 0
                        }

                        // Add the files to the namespace
                        this.init_file(file_record)
                        this.namespace.push(file_record)

                        this.upload_queue.push({
                            file: file_record,
                            userfile: userfile,
                            is_uploading: false
                        })
                    })
                Utils.show_success("Folder"+(folder_paths.length > 1 ? 's' : '')+" created successfully, starting file upload...")
                this.folder_upload_el.value=""
            }).catch(err_resp => {
                Utils.show_error("Error creating folders: " + err_resp.body.message)
                console.error(err_resp)
            })
        },

        upload_file(file, userfile){
            if(this.disable_upload){ return }

            // Upload using the new FileUploader
            const redundant_packets = this.user.team_settings && this.user.team_settings.config ?  this.user.team_settings.config.cloud_selector_redundant_packets : 1
            const config = {
                redundant_num: redundant_packets,
                encrypt_data: true
            }
            let uploader = new FileUploader()
            this.$set(file, 'cancel', {})
            
            uploader.uploadFile(file, userfile, config).then(uploaded_version => {

                Utils.show_success(`<b>${file.name}</b> uploaded.`)

                if(file.cancel !== undefined){
                    delete file.cancel
                }
                file.loading = false
                file.size = uploaded_version.size
                file.last_modified_by = uploaded_version.user_id
                file.last_modified = uploaded_version.timestamp
                // TODO the new number of versions depends on the 'versions_to_kept'
                // setting of the Team Config! It should be provided by the backend and set here
                file.versions_num++

                if(this.file_uploaded_callback){
                    this.file_uploaded_callback(file)
                }
                bus.$emit(events.USED_STORAGE_CHANGED)

                this.cache_namespace()

                // Remove from upload queue
                const queue_index = this.upload_queue.findIndex(item => { return item.file.id == file.id })
                if(queue_index >= 0){
                    // File found in the current upload queue, remove
                    this.upload_queue.splice(queue_index, 1)
                }

            }).catch(err => {
                file.loading = false
                if(file.cancel){
                    delete file.cancel
                    //this.cache_namespace()
                }

                // If would have been a new file, remove from cached namespace (and file list)
                if(file.id < 0){
                    const idx = this.namespace.findIndex(f => f.id === file.id )
                    if(idx){ 
                        this.namespace.splice(idx, 1) 
                        //console.log("Removed from namespace")
                    }
                    this.cache_namespace()
                }

                // Remove from upload queue
                const queue_index = this.upload_queue.findIndex(item => { return item.file.id == file.id })
                if(queue_index >= 0){
                    // File found in the current upload queue, remove
                    this.upload_queue.splice(queue_index, 1)
                }

                if(!(err && err.cancelled)){
                    console.error("Error uploading file: ", err)

                    let error_msg = "Error uploading <b>"+file.name+"</b>. "
                    if(err && err.body && err.body.message){
                        error_msg += err.body.message
                    }
                    else{
                        error_msg += "Please try again later!"
                    }
                    Utils.show_error(error_msg)
                }
            })
        },


        open_file(file, version_id){
            if(this.disable_download){ return }
            this.view_file = {
                file: file,
                version_id: version_id,
                file_list: this.files.filter(f => { return f.viewer })
            }

            // Open modal
            $("#viewer-modal").modal('show')

            // Destroy file blob when the modal is hidden or closed
            var self = this;
            $("#viewer-modal").on('hidden.bs.modal', function () {
                self.view_file = null
            })
        },

        download_file: function(file, version_id){
            if(this.disable_download){ return }

            // On mobile we'll open the file
            this.$set(file, 'open_when_ready', this.is_mobile ? true : false)
            this._do_download_file(file, version_id)
        },

        download_all_selected(){
            if(this.disable_download || !this.selected_files.length){ return }

            // Push all selected files to download queue
            this.files_multidownload_in_progress = true
            this.selected_files.forEach(file => {
                //file.loading = true
                this.download_queue.push({
                    file: file,
                    is_downloading: false
                })
            })
        },

        download_all_cancel(){
            // Clear download queue and cancel any files currently downloading
            this.download_queue.splice(-1)
            this.files.filter(f => f.cancel).forEach(f => { f.cancel() })
            this.files_multidownload_in_progress = false
        },


        get_preview_url(blob_url, viewer_name, file_name, mime_type){
            return "preview?url=" + encodeURIComponent(blob_url) +
                    "&viewer=" + viewer_name +
                    "&name=" + encodeURIComponent(file_name) +
                    "&type=" + encodeURIComponent(mime_type)
        },

        _do_download_file(file, version_id){
            if(this.disable_download){ return }

            if(this.file_download_started){
                this.file_download_started(file)
            }
            this.$set(file, 'cancel', {})
            this.$set(file, 'is_mobile_download', this.is_mobile)
            Utils.download_or_open_file(file, version_id, (downloaded_file) => {
                
                if(this.file_download_succeeded){
                    this.file_download_succeeded(file)
                }

                if(this.is_mobile){
                    // Try to open or download the file on mobile
                    Utils.force_download_file(file, downloaded_file.download_url, null, true, true)
                    return
                    /*
                    try{
                        if(file.viewer){
                            // We have a chance of opening this in the browser
                            if(platform && platform.name && platform.name.toLowerCase() === 'safari'){
                                // Safari can open it in the same tab, the others need a blank one
                                Utils.force_download_file(file, downloaded_file.blob_url, null, false, false)
                                setTimeout(function(){ if(!URL || !URL.revokeObjectURL){ return } URL.revokeObjectURL(downloaded_file.blob_url) }, 10E3) // 10s
                            }
                            else{
                                Utils.force_download_file(file, downloaded_file.blob_url, null, true, true)
                                
                                // Chrome, FF, ...
                                const preview_url = this.get_preview_url(
                                    downloaded_file.blob_url,
                                    file.viewer.name,
                                    file.name,
                                    file.mime_type
                                )
                                this.$router.push(preview_url)
                            }
                        }
                        else{
                            // This file cannot be previewed by the browser, has to be downloaded

                            if(platform && platform.manufacturer && platform.manufacturer.toLowerCase() === 'apple'){
                                // No file system, we need to be creative
                                // Read the file contents and Base64 encode it.
                                var reader = new FileReader();
                                reader.onload = function(e) {
                                    var bdata = btoa(reader.result);
                                    var datauri = 'data:' + file.mime_type + ';base64,' + bdata;
                                    Utils.force_download_file(datauri, file.name)
                                };
                                reader.readAsBinaryString(downloaded_file.blob_url);
                                reader.onerror = function(){
                                    Utils.show_error("This file is too large to download in the browser!")
                                }

                            }
                            else{
                                // Just click a download link and the browser will behave properly
                                Utils.force_download_file(file, downloaded_file.blob_url)
                            }
                        }
                        // Revoke the blob url after saving
                        setTimeout(function(){ if(!URL || !URL.revokeObjectURL){ return } URL.revokeObjectURL(downloaded_file.blob_url) }, 10E3) // 10s

                    } catch(e){
                        console.error("Error downloading file: ", e)
                        Utils.show_error("Error downloading file " + file.name)
                    }
                    */
                }
            }, file => {

                if('cancel' in file){
                    delete file.cancel
                    this.cache_namespace()
                }

                if(this.file_download_succeeded){
                    this.file_download_succeeded(file)
                }
                
                // Unselect (if download is from multiselect)
                file.selected = false
                
                // Download ready, remove from queue
                const queue_index = this.download_queue.findIndex(item => { return item.file.id == file.id })
                if(queue_index >= 0){
                    // File found in the current upload queue, remove
                    this.download_queue.splice(queue_index, 1)
                }
                

            }, () => {
                // Download cancelled
                this.cache_namespace()
            }, (err) => {
                // Download error
                if(this.file_download_error){
                    this.file_download_error(file, err)
                }
            })
        },

        new_folder_form(is_open){
            if(this.disable_namespace_ops){ return }

            if(is_open){
                this.new_folder_mode = true
                this.new_folder_name = ""
                this.$nextTick(()=> {
                    document.getElementById("new_folder_name_input").focus()
                })
            }

            else{
                this.new_folder_mode = false
                this.new_folder_name = ""
            }
        },

        create_folder(folder_name){
            if(this.disable_namespace_ops){ return }
            if(this.new_folder_loading){ return }
            this.new_folder_loading = true
            NamespaceService.create_folder(folder_name, this.current_dir.id).then(res => {

                this.new_folder_name = ""
                this.new_folder_mode = false;
                let new_folder = res.body
                this.init_folder(new_folder)

                this.namespace.push(new_folder)
                this.new_folder_loading = false

                Utils.show_success("Folder created successfully")
                this.cache_namespace()
                // console.log('create folder', new_folder)
            }).catch(err_resp => {
                this.new_folder_loading = false
                Utils.show_error("Error creating folder '"+folder_name+"': " + err_resp.body.message)
                console.error(err_resp)
            })
        },

        open_share_form: function(entities){
            if(this.disable_share){ return }
            if(!Array.isArray(entities)){ entities = [ entities ] }

            for(var i=0 ; i<entities.length ; ++i){
                if(entities[i].entity_type !== entities[0].entity_type){
                    Utils.show_error("Creating a share with files and folders mixed is not supported!")
                    return;
                }
            }

            this.entities_to_share = entities
            var self = this
            $("#share-modal").modal('show')
            $("#share-modal").on('hidden.bs.modal', ()=>{
                self.entities_to_share = null
            })
        },

        create_share_ready(){
            this.close_modal('share-modal')
            this.entities_to_share = null
        },

        share_created(share){
            // Callback from the Share Form when a file or folder share is created
            share.contents.forEach(entity_id => {
                const ns_entity = this.contents.find(c => { return c.id === entity_id})
                if(!ns_entity){
                    console.error("Cannot find namespace entity: ", entity_id)
                    return
                }

                if(!('shares_num' in ns_entity)){
                    this.$set(ns_entity, 'shares_num', 0)
                }

                ns_entity.shares_num++
            })
        },

        open_file_info_modal(file){
            this.info_modal_file = file
            $("#file-info-modal").modal('show')

            var self = this
            $("#file-info-modal").on('hidden.bs.modal', function(){
                self.info_modal_file = null
            })
        }

    },

    filters: {
        extension: function(filename){
            return Utils.get_file_extension(filename) || "-"
        },
    },

}

</script>

<style lang="css" scoped>

    @import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');

    .folder-box{ cursor: pointer; transition: all .3s ease; }
    .folder-box:hover .cardbox{ box-shadow: 0px 3px 16px 0px rgba(123, 123, 123, 0.35) }
    .folder-box .cardbox .cardbox-body{ padding: 8px 16px }

    /* Put these back when folder box caret is added
    .folder-box .cardbox-body{ padding-right: 0px }
    .folder-box a{ padding: 16px }
    */
    .folder-box.add_folder .cardbox-body{ padding-right: 16px; /* Set the padding back in the new folder box */ }
    .folder-box .foldername{ white-space: nowrap; overflow: hidden; text-overflow: ellipsis }
    /* In Dark mode folder names should be white */
    .theme-dark .foldername{
        color: white !important
    }
    .folder-box.dragover{
        opacity: .6;
    }

    .folder-box.dragover .cardbox{
        background: #03A9F4;
        color: white;
        font-weight: bold
    }
    /*.folder-box.dragover .cardbox.color-indigo-400{ background: #5c6bc0 }*/

    .breadcrumb .icon{
        font-size: 16pt
    }

    .icon{
        font-size: 14pt;
        line-height: 1;
    }

    button.btn, a.btn{
        cursor: pointer;
    }

    .file-details-row{
        display: none;
        width: 100%;
    }
    .file-details-row td.file-details-cell{
        border-top: 0px;
    }
    .file-details-row.open{
        /* Without this */
        display: table-row;
    }

    .file-details-row td{
        padding-left: 15px;
        padding-right: 15px;
    }
    .file-details-row .file-details-table th{
        text-align: right
    }
    .file-details-row .file-details-table td{
        text-align: left
    }
    .file-details-row .nav-item{ margin: 0px; padding: 0px; }
    .file-details-row .nav-link{ color: inherit; padding: .75em 1.5em; }
    .file-details-row .nav-link.active{
        /* background: #1E88E5 */
        color: white;
        background: linear-gradient(135deg, #009688 0, #42A5F5 100%)
    }

    tr.highlighted td{
        /*
        background: repeating-linear-gradient(45deg,#eaeaea,#eaeaea 10px,#efefef 10px,#efefef 20px)
        */
        background: #1E88E5;
        color: white
    }

    tbody.open{
        /* The open file details get a shadow */
        box-shadow: 0 0 30px 0 #BABABA;
    }
    tbody.open tr:hover td{
        /* Disable hover highlight for open files (since they consist of 2 rows, it looks weird) */
        background: white
    }
    .theme-dark tbody.open tr:hover td{
        background: transparent
    }

    .cardbox-body.folder-details{
        cursor: default
    }

    .file_download_link:hover{
        text-decoration: underline;
        cursor: pointer;
    }
    .file-download-icon{
        margin-left: 5px;
        color:transparent;
    }
    .file_download_link:hover > .file-download-icon{
        color: inherit
    }
    .badge{
        margin: 0px 2px;
        padding: 3px;
        font-size: 10pt;
        background: #ebebeb
    }
    .file-list thead th{
        font-size: 12pt;
        text-transform: uppercase;
        border: 0px
    }

    .file-list .filename{
        white-space: pre-wrap; /* css-3 */
        white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
        white-space: -pre-wrap; /* Opera 4-6 */
        white-space: -o-pre-wrap; /* Opera 7 */
        word-break: break-all;
        word-wrap: break-word;
        overflow-wrap:break-word;
        display: flex
     }

    .file-list th,
    .file-list tr.file-row td{ text-align: center }
    .file-list thead .sort_header{ cursor: pointer; }
    .text-center{ text-align: center }
    .file-row tbody{ border: 0px }

    tr.file-loading,
    tr.file-loading td { padding: 0px; margin: 0px; border: 0px }
    tr.file-loading td .progress{ padding: 0px; height: 3px }
    tr.file-loading td .progress-bar { height: 3px; margin: 0px }


    .modal-dialog{
        box-shadow: 0;
    }


    .dropdown-item i{ /* Align the labels nicely */ width: 2em }
    .filter-modal .modal-dialog{
        box-shadow: 0 0 30px 0 #BABABA;
    }

    .filter-modal .multi_select_btn{
        margin: 5px;
        text-transform: uppercase;
        cursor: pointer;
        box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.098), 0 1px 5px 0 rgba(0, 0, 0, 0.014)
    }

    .filters span i{ opacity: .75; cursor: pointer; }
    .filters span i:hover{ opacity: 1;  }

    .drag_valid{
        background: #eceff1;
    }

    .drag_valid #file_list{
        display: none
    }

    #dropzone{
        display: none
    }
    .drag_valid #dropzone{
        display: flex;
        height: 100%;
        width: 100%;
        padding-top: 15em
    }

    #folders .ion-folder,
    #folders .ion-plus{
        font-size: 25px
    }
    #breadcrumb .ion-folder{
        font-size: 20px
    }
    .folder-overflow-btn{
        border: none
    }

    @media (max-width: 992px) {

        .breadcrumb{
            padding: 10px;
            margin-bottom: 1em;
        }

        .breadcrumb .btn{
            font-size: 1em
        }

        #folders .ion-folder,
        #folders .ion-plus,
        #breadcrumb .ion-folder{
            /* Remove extra vertical spacing in the folder boxes */
            line-height: 0px;
        }

        /* Make dropdown menus thinner */
        .dropdown-menu{
            padding: 0px;
            font-size: 1em;
            min-width: auto
        }
        .dropdown-item{
            padding: 1em
        }

        /* Make file rows thinner */

        table tr.file-row td{
            padding: .5em
        }

        /* Less padding between the action buttons and edge */
        #files .cardbox-heading{
            padding: 8px
        }
        /* Smaller action buttons in file list header */
        #files .action-buttons button.btn{
            padding: .4em .9em;
            font-size: .9em;
        }

        /* Decrease download icon size */
        .file-download-icon{
            font-size: .8em;
        }

        /* Rules for touch devices */
        @media (any-pointer: coarse) {

            /* Show download icon */
            .file-download-icon{
                color: #a2a2a2
            }
        }

    }

</style>
