<template>
<ol class="dd-list list-unstyled">
    <li v-for="folder in children" :key="folder.name" class="dd-item">
        <div class="cardbox b0 dd-handle" :class="{'text-white bg-info': folder.selected}">
            <div class="cardbox-body c-pointer namespace-folder-box"  @click.stop="clicked(folder)">
                <div class="d-flex align-items-center" :class="'color-' + ( folder.color ) + '-400'">
                    <div class="mr-2"><i class="ion-folder" :class="{'text-white': folder.selected }"></i></div>
                    <div>
                        <div :class="{'text-white text-bold': folder.selected}">{{ folder.name }}</div>
                    </div>
                </div>
            </div>
        </div>

        <namespace-tree-row v-if="folder.children && folder.children.length > 0" :children="folder.children" :clicked="clicked"></namespace-tree-row>
    </li>
</ol>
</template>
<script>

// Self-importing this component:
// https://v2.vuejs.org/v2/guide/components-edge-cases.html#Recursive-Components
export default {
    name: "namespace-tree-row",
    props: ["children",  "clicked"],
    beforeCreate: function () {
       this.$options.components.namespaceTreeRow = require('./namespace-tree-row.vue').default
    },
}
</script>
<style scoped>

.dd-item{
    font-size: inherit;
    line-height: inherit;
}

.ion-folder{
    font-size: 2em;
    line-height: 0px;
}

.namespace-folder-box{
    padding: .2em 1em !important
}

@media (max-width: 992px) {
    .ion-folder{
        font-size: 1.5em;
    }
    .namespace-folder-box{
        padding: .2em .8em  !important
    }
}

</style>
