function toRadians(decimal_degrees){
    return decimal_degrees * Math.PI / 180.0
}

function get_distance(p1, p2){
    // Credit: http://www.movable-type.co.uk/scripts/latlong.html
    if(!p1.lat || !p1.lng || !p2.lat || !p2.lng){
        console.error("get_distance wrong points given: ", p1, p2)
        return -1;
    }
    if(p1.lat === p2.lat && p1.lng === p2.lng){ return 0 }

    var φ1 = toRadians(p1.lat), φ2 = toRadians(p2.lat), Δλ = toRadians(p2.lng - p1.lng), R = 6371e3; // gives d in metres
    return Math.acos( Math.sin(φ1)*Math.sin(φ2) + Math.cos(φ1)*Math.cos(φ2) * Math.cos(Δλ) ) * R;
}


function get_best_backends(clouds, users, howmany){

    let best_clouds = [];

    function sort_best_clouds(best_clouds){
        best_clouds.sort(function(a, b){ return a.dist - b.dist })
    }
    function print_best_clouds(best_clouds){
        for(var i=0 ; i<best_clouds.length ; ++i){
            var c = best_clouds[i];
            //console.log(" (" + (i+1) + ") " +c.cloud.provider.name + " " + c.cloud.name + ": " + get_km(c.dist) + "" )
        }
    }
    function try_push_cloud(best_clouds, measurement, howmany){

        if(best_clouds.length < howmany){
            //console.log("Adding " + measurement.cloud.name + " (dist:"+ get_km(measurement.dist) +")")
            // Not full, push without checking
            best_clouds.push(measurement)
            // sort by distance
            sort_best_clouds(best_clouds)
            print_best_clouds(best_clouds)
            //console.log(best_clouds)
            return true
        }

        // Check if the current measurement fits
        if(measurement.dist < get_current_worst(best_clouds, howmany)){
            //const last = best_clouds[ best_clouds.length-1 ]
            //console.log("Replacing " + last.cloud.name + " with " + measurement.cloud.name)
            // Yes, it's better than the current last
            // replace the current last with the new one
            best_clouds[ best_clouds.length-1 ] = measurement
            // sort
            sort_best_clouds(best_clouds)
            print_best_clouds(best_clouds)
            return true
        }

        return false
    }

    function get_current_worst(best_clouds, howmany){
        return best_clouds.length < howmany ? false : best_clouds[best_clouds.length - 1].dist
    }

    function avg(arr){
        let sum = 0.0;
        for(var i=0 ; i < arr.length ; ++i){
            sum += arr[i]
        }
        return sum / arr.length
    }

    function get_variance(arr){
        // Calculates the variance of an array of numbers

        if(arr.length < 2){
            return avg(arr)
        }

        // Step 1: calculate average
        var global_average = avg(arr)

        // Step 2: for each item, calculate diff from global average
        var diffs = []
        for(var i=0 ; i < arr.length ; ++i){
            diffs.push(Math.abs(arr[i] - global_average))
        }

        // Step 3: Return the average diff
        return avg(diffs)
    }

    function get_km(meters){
        return (meters/1000).toFixed(0) + " km"
    }


    // Calculate the precise middle point of all user locations
    var lats = users.map( item => { return item.location.lat } )
    var lngs = users.map( item => { return item.location.lng } )
    const user_midpoint = {
        lat: avg(lats),
        lng: avg(lngs)
    }
    //console.log("Midpoint: ", user_midpoint)

    // Calculate the sum of distances between each cloud location and the midpoint of users
    // Keep the lowest 'howmany' of them in the 'best_clouds' buffer
    let distance_to_mid = 0.0

    for(var j=0 ; j < clouds.length ; ++j){
        let cloud = clouds[j];
        distance_to_mid = get_distance(user_midpoint, cloud)

        const current_worst = get_current_worst(best_clouds, howmany)
        if(current_worst && distance_to_mid > current_worst){
            continue
        }
        //console.log("Distances for " + cloud.name + ": " + get_km(distance_to_mid))
        try_push_cloud(best_clouds, {
            cloud: cloud,
            dist: distance_to_mid
        }, howmany)
    }

    // We have the best combination, calculate average distance to the original locations
    let result = []
    for(var i=0 ; i<best_clouds.length ; ++i){
        const cloud = best_clouds[i].cloud

        let distances = [], distance_objects = []
        for(var j=0 ; j<users.length ; ++j){
            const user = users[j];
            const dist = get_distance(user.location, cloud)
            distances.push(dist)
            distance_objects.push({ loc_id: user.id, dist: dist })
        }

        cloud["distances"] = distance_objects
        cloud["avg_dist"] = avg(distances)
        result.push(cloud)
    }


    return result
}

export { get_distance, get_best_backends }