import { bus, events } from '../helpers/event_bus.js'
import { Utils } from '../helpers/utils.js'
import { TokenCache } from '../helpers/token-cache.js';

import platform from 'platform'


// This is not exported
const base_url = process.env.VUE_APP_client_spaces_service_url
const ClientSpacesService = {

    AUTH_TYPE_SKYFLOK_USER: 1,
    AUTH_TYPE_CLIENT: 2,

    CLIENT_SPACE_APP_PATH: "client_space",

    SPACE_EVENT_FILE_UPLOADED: 1,
    SPACE_EVENT_FILE_DELETED: 2,
    SPACE_EVENT_FOLDER_DELETED: 3,

    ACTOR_SPACE_MANAGER: 1,
    ACTOR_CLIENT: 2,

    is_skyflok_logo(space){
        // Returns True if the logo is a built-in image
        return space && space.client_logo && space.client_logo.search("client_space_logos/") >= 0
    },

    /* Client Space Management (by logged in team member) */
    list(with_mfa_status){
        const url = '/spaces' + (with_mfa_status ? '?with_mfa=1' : '')
        return bus.$http.get(base_url + url, { headers: Utils.get_auth_header() })
    },

    create: function(space){
        if(space.id && space.id !== null){
            console.error("Space.create must be called without ID!")
            return;
        }
        return bus.$http.post(base_url + '/spaces', space, { headers: Utils.get_auth_header() })
    },

    create_batch(form, clients){
        const payload = {
            common: form,
            clients: clients
        }

        return bus.$http.post(base_url + '/spaces_batch', payload, { headers: Utils.get_auth_header() })
    },

    update: function(space){
        if(space.id == undefined || space.id == null){
            console.error("Space.update must be called with ID!")
            return;
        }
        return bus.$http.put(base_url + '/spaces', space, { headers: Utils.get_auth_header() })
    },

    archive(space_id, new_value, schedule_delete){
        if(schedule_delete === undefined){ schedule_delete = false }
        const payload = {
            id: space_id,
            is_archived: new_value ? 1 : 0,
            schedule_delete: schedule_delete ? 1 : 0
        }
        return bus.$http.put(base_url + "/space/archive", payload, { headers: Utils.get_auth_header() })
    },

    add_space_manager(space_id, manager_user_id){
        const payload = {
            id: space_id,
            user_id: manager_user_id
        }

        return bus.$http.post(base_url + "/space/add_manager", payload, { headers: Utils.get_auth_header() })
    },

    remove_space_manager(space_id, manager_user_id){
        const payload = {
            id: space_id,
            user_id: manager_user_id
        }

        return bus.$http.post(base_url + "/space/remove_manager", payload, { headers: Utils.get_auth_header() })
    },

    CHANGE_WATCH_ALL_SPACES: -1,
    watch_managers(space_id, new_watch_value){
        let payload = {
            watch: new_watch_value ? 1 : 0
        }
        if(space_id === ClientSpacesService.CHANGE_WATCH_ALL_SPACES){
            payload["all"] = 1
        }
        else{
            payload["id"] = space_id
        }
        return bus.$http.put(base_url + "/space/watch_managers", payload, { headers: Utils.get_auth_header() })
    },

    get_files_count(){
        return bus.$http.get(base_url + "/files_count", { headers: Utils.get_auth_header() })
    },

    get_space_size(space_id){
        return bus.$http.get(base_url + "/space/"+space_id+"/total_size", { headers: Utils.get_auth_header() })
    },


    /* Endpoints called from the Client Space App (either by a logged in member or the client) */

    /**
     * Logs in a Client Space client
     * You have to pass the hashed password and either the space key or the client email.
     * Device ID and browser information are added to the request automatically.
     *
     * @param {String} password The client's hashed password
     * @param {String} key (opt) Space key
     * @param {String} email (opt) The client's email
     * @param {String} mfa_code (opt) Two-factor authentication code
     * @param {String} mfa_backup_code (opt) Two-factor authentication backup code
     * @return The token in the response body JSON object
     */
    login_client(password, key, email, mfa_code, mfa_backup_code){
        let payload = {
            password: password,
            device_id: Utils.get_device_id(),
            client_os: platform.os ? platform.os.family : 'unknown',
            client_name: platform.name,
            client_version: platform.version
        }

        if(key){
            payload.key = key
        }
        else if(email){
            payload.email = email
        }

        if(mfa_code){
            payload['mfa_code'] = mfa_code
        }
        else if(mfa_backup_code){
            payload['mfa_backup_code'] = mfa_backup_code
        }

        return bus.$http.post(base_url + "/client/login", payload)
    },


    /**
     * Invalidate the client's session
     *
     * @param {Object} auth The client auth object (including the token)
     * @return Nothing besides the HTTP status code
     */
    logout_client(auth){
        if(auth.type != ClientSpacesService.AUTH_TYPE_CLIENT){
            throw "Auth type must be Client!"
        }

        const headers = {
            "Authorization": "ClientToken " + auth.token
        }

        return bus.$http.get(base_url + "/client/logout", { headers: headers } )
    },

    get_space(auth, key, with_team_info, with_locations){
        const url_params = {
            key: key,
            with_team_info: (with_team_info ? 1 : 0),
            with_locations: (with_locations ? 1 : 0)
        }
        let headers = {}, endpoint;

        if(auth.type === this.AUTH_TYPE_SKYFLOK_USER){
            headers = Utils.get_auth_header()
            endpoint = "/space"
        }
        if(auth.type === this.AUTH_TYPE_CLIENT){
            headers = {
                "Authorization": "ClientToken " + auth.token
            }
            endpoint = "/client/space"
        }

        return bus.$http.get(base_url + endpoint, { params: url_params, headers: headers })
    },

    get_team_info(space_key){
        return bus.$http.get(base_url + "/space/"+space_key+"/team_info")
    },

    client_info_edit(space_key, auth, client_info){
        const url_params  = {
            key: space_key
        }
        const headers = {
            "Authorization": "ClientToken " + auth.token
        }
        return bus.$http.put(base_url + '/client/update_info', client_info, { params: url_params, headers: headers })
    },

    watch_client(auth, space_key, new_watch_value){
        const payload  = {
            key: space_key,
            watch: new_watch_value
        }
        const headers = {
            "Authorization": "ClientToken " + auth.token
        }
        return bus.$http.put(base_url + '/client/watch_client', payload, { headers: headers })
    },

    load_valid_invite(invite_code){
        const url_params = {
            code: invite_code
        }
        return bus.$http.get(base_url + '/invites/get_valid', { params: url_params })
    },

    client_join_space(invite_code, password_hash, watch_client, mfa_code, mfa_backup_code){
        let payload = new FormData()
        payload.append("password", password_hash)
        payload.append("invite_code", invite_code)
        payload.append("watch_client", watch_client ? 1 : 0)
        // Additional info for the new session
        payload.append("device_id", Utils.get_device_id())
        payload.append("client_os", platform.os ? platform.os.family : 'unknown')
        payload.append("client_name", platform.name)
        payload.append("client_version", platform.version)

        if(mfa_code){
            payload.append("mfa_code", mfa_code)
        }
        if(mfa_backup_code){
            payload.append("mfa_backup_code", mfa_backup_code)
        }

        return bus.$http.post(base_url + '/invites/client_join', payload)
    },

    resend_invite(space_id){
        const payload = {
            space_id: space_id
        }
        return bus.$http.put(base_url + "/invites/resend", payload, { headers: Utils.get_auth_header() })
    },

    resend_invite_batch(space_ids){
        const payload = {
            space_ids: space_ids
        }
        // Same endpoint as 'resend_invite', just different payload (space_ids instead of space_id)
        return bus.$http.put(base_url + "/invites/resend", payload, { headers: Utils.get_auth_header() })
    },

    reset_password(space_key){
        const payload = {
            key: space_key
        }
        return bus.$http.post(base_url + "/space/reset_password", payload, { headers: Utils.get_auth_header() })
    },

    /**
     * Report an event that happened in a Space and can be subscribed for
     *
     * @param {Number} space_id ID of the Space where the event happened
     * @param {Number} event Code of the event, use one of ClientSpacesService.SPACE_EVENT_xxx constants
     * @param {Number} actor Who initiated the event, use ne of ClientSpacesService.ACTOR_xxx constants
     * @param {Number} file_id (optional) The file or folder involved (if applicable)
     * @return Does not return any info besides the status code and error message
     */
    report_space_event(auth, space_id, event, actor, file_id){
        let payload = {
            space_id: space_id,
            event: event,
            actor: actor
        }
        if(file_id){
            payload["file_id"] = file_id
        }

        let headers = {}, endpoint;

        if(auth.type === this.AUTH_TYPE_SKYFLOK_USER){
            headers = Utils.get_auth_header()
            endpoint = "/space_event"
        }
        if(auth.type === this.AUTH_TYPE_CLIENT){
            headers = {
                "Authorization": "ClientToken " + auth.token
            }
            endpoint = "/client/space_event"
        }

        return bus.$http.post(base_url + endpoint, payload, { headers: headers })
    },

    query_file_uploads_downloads(auth, space_key, desc, howmany, offset){
        const payload = {
            key: space_key,
            desc: desc ? 1 : 0,
            howmany: howmany,
            offset: offset
        }

        let headers = {}
        let endpoint = null

        if(auth.type === this.AUTH_TYPE_SKYFLOK_USER){
            headers = Utils.get_auth_header()
            endpoint = "/space/file_uploads_downloads"
        }
        if(auth.type === this.AUTH_TYPE_CLIENT){
            headers = {
                "Authorization": "ClientToken " + auth.token
            }
            endpoint = "/client/file_uploads_downloads"
        }

        return bus.$http.post(base_url + endpoint, payload, { headers: headers })
    },

    load_client_2fa_backup_codes(space_id){
        return bus.$http.get(base_url + "/space/get_2fa_backup_codes/"+space_id, { headers: Utils.get_auth_header() })
    },
    
    // TODO remove, Clients can generate backup codes with AuthService, proxying the request through Client Space Service is no longer needed
    generate_client_2fa_backup_codes(space_id){
        return bus.$http.put(base_url + "/space/generate_2fa_backup_codes/"+space_id, null, { headers: Utils.get_auth_header() })
    },

    get_client_2fa_events(){
        return bus.$http.get(base_url + "/client/get_2fa_events", { headers: Utils.get_auth_header() })
    }
}

export { ClientSpacesService }




/* **************************************************************************************** */
// Install HTTP interceptor for requests going to FileVersion and StorageBackend endpoints
// when a client space is open and the client (non-user) is interacting with it. In this
// case, we have to proxy the request through ClientSpace Service, which calls the
// originally requested service functionality via an authenticated interservice call
/* **************************************************************************************** */

import Vue from 'vue'
import VueResource from 'vue-resource';
Vue.use(VueResource)

const client_space_url_key = Utils.parse_url_param('key')
const BASE_PATH = process.env.VUE_APP_BASE_PATH + '/'
const full_path = window.location.pathname
const relative_path = full_path.slice(BASE_PATH.length, full_path.length)

// Both the Client and SkyFlok users store their tokens in the token cache, but the client also has a 'client-space-key=[key]' entry
// Note that the key can either be in sessionStorage or localStorage, based on whether the client checked the "keep me logged in" checkbox when logging in
const is_client_space_client = sessionStorage.getItem("client-space-key") == client_space_url_key || localStorage.getItem("client-space-key") === client_space_url_key

if(relative_path === ClientSpacesService.CLIENT_SPACE_APP_PATH && is_client_space_client && client_space_url_key && client_space_url_key.length > 0){
    // Client space

    const requests_to_redirect = [
        {
            /* List all versions of a file */
            base_url: process.env.VUE_APP_fileversion_service_url,
            method: "GET",
            service: "file_version",
            path: "history"
        },
        {
            /* FileVersion latest version info */
            base_url: process.env.VUE_APP_fileversion_service_url,
            method: "GET",
            service: "file_version",
            path: "latest"
        },
        {
            /* FileVersion latest version info, POST version */
            base_url: process.env.VUE_APP_fileversion_service_url,
            method: "POST",
            service: "file_version",
            path: "latest"
        },
        {
            /* FileVersion: create new version */
            base_url: process.env.VUE_APP_fileversion_service_url,
            method: "POST",
            service: "file_version",
            path: "file_version"
        },
        {
            /* Get download links to the file version */
            base_url: process.env.VUE_APP_fileversion_service_url,
            method: "GET",
            service: "file_version",
            path: "download_version"
        },
        {
            /* Get download links to the latest version of the file */
            base_url: process.env.VUE_APP_fileversion_service_url,
            method: "GET",
            service: "file_version",
            path: "download_latest"
        },
        {
            /* Get upload links to create a new file */
            base_url: process.env.VUE_APP_storage_backend_service_url,
            method: "POST",
            service: "storage_backend",
            path: "get_upload_links"
        },
        {
            /* Get upload links to create a new file */
            base_url: process.env.VUE_APP_storage_backend_service_url,
            method: "GET",
            service: "storage_backend",
            path: "buckets",
            block: true
        },
        {
            /* Disable getting storage info */
            base_url: process.env.VUE_APP_fileversion_service_url,
            method: "GET",
            service: "file_version",
            path: "info/total_storage",
            block: true
        },

    ]

    Vue.http.interceptors.push( (request, next) => {
        const request_url = new URL(request.url)
        // Check if the request is one of the ones we want to redirect
        for(var i=0 ; i<requests_to_redirect.length ; ++i){
            const rule = requests_to_redirect[i]
            const is_same_service = rule.base_url.toUpperCase() === request_url.origin.toUpperCase()
            const is_same_method = rule.method.toUpperCase() === request.method.toUpperCase()
            const is_same_endpoint = '/' + rule.path.toUpperCase() === request_url.pathname.toUpperCase()

            if(is_same_service && is_same_endpoint && is_same_method){
                if(rule.block){
                    next({status: 0})
                    return;
                }
                else{


                    // Rewrite to [client space base url]/client/[original method]_[original endpoint]
                    // e.g. [base_url]/client/get_list_files
                    const client_token = TokenCache.get() //sessionStorage["client_space-" + client_space_url_key] || localStorage["client_space-" + client_space_url_key]
                    if(!client_token){
                        throw "Client token not found either session or local storage! Space Key: " + client_space_url_key
                    }
                    request.url = base_url + "/client/" + rule.service.toLowerCase() + "_" + request.method.toLowerCase() + "_" + rule.path
                    // TODO Change ClientToken to Bearer
                    request.headers.set('Authorization', 'ClientToken ' + client_token)
                    // TODO remove sending the Space key in a header, clients are authenticated via a bearer token
                    request.headers.set('X-Client-Space-Key', client_space_url_key)
                }
            }
        }

        // return response callback
        next();
    })
}