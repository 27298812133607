<template>
    <div>
        <p>
            <a :href="blob_url" :download="file_name" class="btn btn-gradient btn-secondary ml-2">
                <i class="ion-arrow-down-a mr-2"></i> Save <b>{{ file_name }}</b>
            </a>
        </p>
        <div v-if="viewer_name == 'image'">
            <img :src="blob_url" class="preview d-inline-block" />
        </div>
        <div v-else-if="viewer_name == 'video'">
            <video :src="blob_url" class="fw d-block" controls="true" autoplay="true"></video>
        </div>
        <div v-else-if="viewer_name == 'audio'">
            <audio :src="blob_url" class="fw d-block" controls="true" autoplay="true"></audio>
        </div>
        <div v-else-if="viewer_name == 'text'">
            <pre>{{ text_file_contents }}</pre>
        </div>
        <div v-else class="d-block embedded-object">
            <object :data="blob_url" :type="mime_type" :title="file_name" :name="file_name" height="800" class="fw d-block embedded-object">
                <p>Your browser cannot preview {{ file_name }}. Please try downloading.</p>
            </object>
        </div>
    </div>
</template>
<script>
export default {
    props: ["file_name", "blob_url", "viewer_name", "mime_type"],

    data(){
        return {
            text_file_contents: null
        }
    },

    created(){
        if(this.viewer_name === 'text' && !this.text_file_contents){
            // Read contents of text file
            this.read_text_blob()
        }
    },

    methods: {
        read_text_blob(){

            this.text_file_contents = "Loading..."
            // Load the blob contents
            var xhr = new XMLHttpRequest();
            xhr.open('GET', this.blob_url, true);
            xhr.responseType = 'blob';
            var self = this
            xhr.onload = function() {
                if (this.status == 200) {
                    var myBlob = this.response;

                    // Read the blob as a text file
                    var reader = new FileReader()

                    reader.onloadend = function(evt){
                        self.text_file_contents = evt.target.result
                    }
                    reader.onerror = function(){
                        self.file_contents_text = "Error reading file contents."
                    }
                    reader.readAsText(myBlob)
                }
            };
            xhr.send();
        },

    }
}
</script>
<style scoped>
pre{
    max-width: 80%;
    display: block !important;
    margin:0px auto;
    padding: 10px;
    text-align: left;
    background: #F2F4F4;
    border: 2px solid #D5DBDB;
    border-radius: 3px
}

.embedded-object{
    min-height: 800px
}

img.preview{
    max-width: 100%
}

</style>
