<template>
    <section class="fw">
        <div>When a Space is archived:</div>
        <p class="mt-3 mb-1">
            <i class="ion-checkmark mr-2 ml-4"></i>
            The Client cannot access it any more.
        </p>
        <p class="mb-1">
            <i class="ion-checkmark mr-2 ml-4"></i>
            You can open it, but cannot download and upload files or change anything.
        </p>
        <p class="mb-1">
            <i class="ion-checkmark mr-2 ml-4"></i>
            Can be re-activated any time, but it might take a few minutes.
        </p>
        <p class="mb-1">
            <i class="ion-checkmark mr-2 ml-4"></i>
            Optionally, you can <b>delete the Space and files in it after 30 days</b> mandatory time period.
        </p>

        <div class="mt-2 text-right">
            <a
                href="#"
                data-container="body"
                data-toggle="popover"
                data-placement="top"
                data-html="true"
                data-trigger="hover"
                data-content="To protect you and your Clients from malware, ransomware and viruses, SkyFlok doesn't allow deleting data immediately. <br/><br/>If you need to delete the Space and its contents sooner, please contact us via email at <i>support@skyflok.com</i>"
                >Can I delete the Space immediately?</a>
        </div>

        <div class="mt-3 fw d-flex justify-content-center">
            <button class="btn btn-lg btn-warning btn-gradient text-bold mr-1" @click="archive_space(false)" :disabled="loading">
                Archive
                <i class="" :class="{'ion-load-c spin': action=='archive' && loading, 'ion-archive': !loading}"></i>
            </button>
            <button class="btn btn-lg btn-danger btn-gradient text-bold ml-1" @click="archive_space(true)" :disabled="loading">
                Delete
                <i class="" :class="{'ion-load-c spin': action=='delete' && loading, 'ion-trash-b': !loading}"></i>
            </button>
        </div>
        <div class="text-danger" v-if="error">{{ error }}</div>

    </section>
</template>

<script>

import { ClientSpacesService } from '@/services/client_spaces-service.js'
import { Utils } from '@/helpers/utils.js'

export default {
    props: ["space", 'space_updated', 'close_modal'],

    data(){
        return {
            loading: false,
            error: false,
            action: null
        }
    },

    methods: {

        archive_space(schedule_delete){
            this.loading = true
            this.error = false
            this.action = schedule_delete ? 'delete' : 'archive'

            ClientSpacesService.archive(this.space.id, true, schedule_delete).then(res => {
                const updated_space = res.body


                // update the space
                if(this.space_updated){
                    this.space_updated(updated_space)
                }
                this.loading = false
                let success_msg = "Space archived successfully."
                if(schedule_delete){
                    success_msg += " It will be deleted in 30 days."
                }
                Utils.show_success(success_msg)

                if(this.close_modal){
                    this.close_modal()
                }

            }).catch(err => {
                console.error(err)
                this.error = "Failed to archive Space: " + err.body.message
                this.loading = false
            })
        },
    }
}
</script>


<style scoped>

.btn{
    white-space: normal
}

@media(max-width: 576px){

    i.ion-checkmark{
        margin-left: 0px !important
    }
}
</style>
