<template>
<section >
<div class="fw" v-if="file_to_preview">
    <table v-if="file" id="container-table">
        <tr>
            <td v-if="prev_file" @click="file_to_preview = prev_file" class="pager fh text-center c-pointer mr-3 no-text-select d-none d-md-table-cell" :title="prev_file.name">
                <i class="ion-chevron-left" ></i>
                <br/><small>{{ prev_file.name.substr(0,11) }}...</small>
            </td>

            <td class="fw text-center" style="min-height: 500px">
                <div v-if="!file_to_preview.blob_url">
                    Downloading <b>{{ file_to_preview ? file_to_preview.name : '-' }}</b>: {{ Math.round(file_to_preview.loading) }}%
                    <div class="progress mt-2 mx-5">
                        <div class="progress-bar progress-bar-info progress-bar-striped progress-bar-animated" role="progressbar" :aria-valuenow="file_to_preview.loading" aria-valuemin="0" aria-valuemax="100" :style="{'width': Math.round(file_to_preview.loading)+'%'}">
                        <span class="sr-only">{{Math.round(file_to_preview.loading)}}% Complete</span></div>
                    </div>
                </div>

                <file-preview
                    v-else
                    :blob_url="file_to_preview.blob_url"
                    :file_name="file_to_preview.name"
                    :mime_type="file_to_preview.mime_type"
                    :viewer_name="file_to_preview.viewer.name">
                </file-preview>
            </td>

            <td v-if="next_file" @click="file_to_preview = next_file" class="pager fh text-center c-pointer ml-3 no-text-select d-none d-md-table-cell" :title="next_file.name">
                <i class="ion-chevron-right"></i>
                <br/><small>{{ next_file.name.substr(0, 11) }}...</small>
            </td>
        </tr>
    </table>

</div>

</section>

</template>

<script type="text/javascript">
import { Utils } from '@/helpers/utils.js'
import { FileDownloader } from '@/helpers/file-downloader.js';
import filePreview from './file-preview.vue'

export default {

    props: ["file", "version_id", "file_list", "file_downloaded_callback"],

    components: { filePreview },

    data(){
        return {
            text: null,
            file_to_preview: null,
        }

    },

    computed: {

        total_files(){ return this.file_list.length },
        prev_file(){
            if(!this.file_to_preview){ return null }

            let curr_idx = this.file_list.findIndex(f => { return f.id === this.file_to_preview.id })
            return curr_idx <= 0 ? null : this.file_list[--curr_idx]
        },
        next_file(){
            if(!this.file_to_preview){ return null }

            let curr_idx = this.file_list.findIndex(f => { return f.id === this.file_to_preview.id })
            return curr_idx >= this.file_list.length-1 ? null : this.file_list[++curr_idx]
        },
        file_id(){ return this.file_to_preview ? this.file_to_preview.id : null }
    },

    watch: {
        file_id(){

            if(this.prev_file && this.prev_file.cancel){
                this.prev_file.cancel()
            }

            if(this.file_to_preview.blob_url){
                // already downloaded
                return;
            }

            this.$set(this.file_to_preview, 'cancel', {})
            let downloader = new FileDownloader()
            downloader.downloadFile(this.file_to_preview).then(res => {
                let file = res.ns_file

                file.loading = false
                delete file.cancel

                file.blob_url = res.blob_url
                file.mime_type = res.mime_type

                if(this.file_downloaded_callback){
                    this.file_downloaded_callback(this.file_to_preview)
                }

            }).catch(err => {
                this.$emit('close')
                if(!err || !err.cancelled){
                    Utils.show_error("Error downloading file.")
                }
                
            })
        }
    },

    created: function(){
        this.file_to_preview = this.file
    },


    methods: {

        jump_prev(){
            if(this.prev_file){ this.file_to_preview = this.prev_file }
        },
        jump_next(){
            if(this.next_file){ this.file_to_preview = this.next_file }
        }
    },

    mounted(){
        var self = this
        $(document).keyup(function(event) {
            if(event.which == 37) {
                event.preventDefault();
                self.jump_prev()
            }
            if(event.which === 39){
                event.preventDefault();
                self.jump_next()
            }
        });
    },

    destroyed(){
        $(document).unbind('keyup')
    }

}
</script>

<style lang="css" scoped>

    #container-table{
        table-layout: fixed;
        width: 100%
    }
    #container-table td{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .pager{
        width: 100px
    }
    .pager:hover{
        background: #ebebeb
    }
</style>
