import { bus } from '../helpers/event_bus.js'
import { Utils } from '../helpers/utils.js'

// This is not exported
const base_url = process.env.VUE_APP_namespace_service_url

const NamespaceService = {

    ENTITY_TYPE_FOLDER: "FOLDER",
    ENTITY_TYPE_FILE: "FILE",

    NS_TYPE_ADMIN_FOLDER: 'admin_folder',
    NS_TYPE_DEFAULT: 'default',
    NS_TYPE_CLIENT_SPACE: 'client-space',

    ns_type: null,
    ns_key: null,

    // Lists contents of a folder
    list(folderId){
        let url_params = {}
        if(folderId && folderId > 0){ url_params["folder_id"] = folderId }
        if(this.ns_type !== NamespaceService.NS_TYPE_DEFAULT){ url_params["ns_type"] = this.ns_type }

        let headers = Utils.get_auth_header()
        if(this.ns_key){ headers["ns_key"] = this.ns_key }

        return bus.$http.get(base_url + "/list", { params: url_params, headers: headers })
    },

    // Lists deleted files of a given folder
	list_deleted(folder_id){
		let url_params = {
            deleted: '1',
            all: '1',
            with_path: '1'
        };
        if(folder_id){ url_params.folder_id = folder_id}
        if(this.ns_type !== NamespaceService.NS_TYPE_DEFAULT){ url_params["ns_type"] = this.ns_type }

        let headers = Utils.get_auth_header()
        if(this.ns_key){ headers["ns_key"] = this.ns_key }

		return bus.$http.get(base_url + "/list", { params: url_params, headers: headers })
	},

    /**
     * Lists the complete contents of a namespace. By default it's listing
     * the current namespace, but you can override the ns type and key for
     * just this one request.
     * 
     * @param {boolean} folders_only When set to True, only folders of the ns are returned
     * @param {string} ns_type_override Set a custom namespace type
     * @param {string} ns_key_override Set a custom namespace key
     * @returns {array} The requested contents, or error if the caller doesn't have access to the requested ns
     */
    get_all_namespace(folders_only, ns_type_override, ns_key_override){
        if(folders_only === undefined){
            folders_only = false
        }

        let url_params = {
            all: '1',
            deleted: '0',
            folders_only: folders_only ? '1' : '0'
        }

        if(ns_type_override !== undefined){
            if(ns_type_override){
                url_params["ns_type"] = ns_type_override
            }
        }
        else if(this.ns_type !== NamespaceService.NS_TYPE_DEFAULT){ 
            url_params["ns_type"] = this.ns_type 
        }

        
        let headers = Utils.get_auth_header()
        if(ns_key_override !== undefined){
            headers["ns_key"] = ns_key_override
        }
        else if(this.ns_key){ 
            headers["ns_key"] = this.ns_key 
        }

        return bus.$http.get(base_url + "/list", { params: url_params, headers: headers })
    },

    // List files by ID.
    get_files_by_id: function(file_ids, with_path){
        let url_params = {
            with_path: with_path === true ? '1' : '0'
        }
        // Send file IDs in the POST payload
        const payload = {
            id: file_ids
        }
        if(this.ns_type && this.ns_type !== NamespaceService.NS_TYPE_DEFAULT){ url_params["ns_type"] = this.ns_type }

        let headers = Utils.get_auth_header()
        if(this.ns_key){ headers["ns_key"] = this.ns_key }
        

        return bus.$http.post(base_url + "/details", payload, { params: url_params, headers: headers })
    },

    resolve_path: function(path_string){
        let url_params = {
            path: path_string
        }
        if(this.ns_type !== NamespaceService.NS_TYPE_DEFAULT){ url_params["ns_type"] = this.ns_type }

        let headers = Utils.get_auth_header()
        if(this.ns_key){ headers["ns_key"] = this.ns_key }

        return bus.$http.get(base_url + "/parse_path", { params: url_params, headers: headers })
    },

    sync_activity(folder_id, is_active){
        let url_params = {
            folder_id: folder_id ? folder_id : -1
        }
        if(is_active !== undefined){
            url_params['is_active'] = is_active
        }

        let headers = Utils.get_auth_header()
        return bus.$http.get(base_url + "/sync_activity", { params: url_params, headers: headers })
    },

    remove_activity(){
        const headers = Utils.get_auth_header()
        return bus.$http.get(base_url + "/sync_activity/gone", { headers: headers })
    },

    // Creates a new file
    create_file: function(filename, parent_id, mime_type){
        let payload = {
            "name": filename,
            "mime_type": mime_type
        }
        if(parent_id && parent_id >= 0){
            payload["parent_id"] = parent_id
        }
        let url_params = {}
        if(this.ns_type !== NamespaceService.NS_TYPE_DEFAULT){ url_params["ns_type"] = this.ns_type }

        let headers = Utils.get_auth_header()
        if(this.ns_key){ headers["ns_key"] = this.ns_key }

        return bus.$http.post(base_url + "/file", payload, { params: url_params, headers: headers })
    },

    delete_entity(id){
        const payload = {
            "id": id
        }
        let url_params = {}
        if(this.ns_type !== NamespaceService.NS_TYPE_DEFAULT){ url_params["ns_type"] = this.ns_type }

        let headers = Utils.get_auth_header()
        if(this.ns_key){ headers["ns_key"] = this.ns_key }

		return bus.$http.put(base_url + "/delete", payload, { params: url_params, headers: headers })
    },

	delete_file: function(file_id){
		return NamespaceService.delete_entity(file_id)
    },
    delete_folder: function(folder_id){
        return NamespaceService.delete_entity(folder_id)
    },

    undelete_entity(id, target_id){
        let payload = { "id": id }
        if(target_id){ payload["target_id"] = target_id }

        let url_params = {}
        if(this.ns_type !== NamespaceService.NS_TYPE_DEFAULT){ url_params["ns_type"] = this.ns_type }

        let headers = Utils.get_auth_header()
        if(this.ns_key){ headers["ns_key"] = this.ns_key }

        return bus.$http.put(base_url + "/undelete", payload, { params: url_params, headers: headers })
    },
    undelete_file: function(file_id){
        return NamespaceService.undelete_entity(file_id)
    },
    undelete_folder: function(folder_id){
        return NamespaceService.undelete_entity(folder_id)
    },

    create_folder: function(foldername, parent_id){
        let payload = {
            "name": foldername
        }
        if(parent_id){
            payload["parent_id"] = parent_id
        }

        let url_params = {}
        if(this.ns_type !== NamespaceService.NS_TYPE_DEFAULT){ url_params["ns_type"] = this.ns_type }

        let headers = Utils.get_auth_header()
        if(this.ns_key){ headers["ns_key"] = this.ns_key }

        return bus.$http.post(base_url + "/folder", payload, { params: url_params, headers: headers })
    },

    create_folders: function(folder_paths, parent_id){
        let payload = {
            "paths": folder_paths
        }
        if(parent_id){
            payload["parent_id"] = parent_id
        }
        let url_params = {}
        if(this.ns_type !== NamespaceService.NS_TYPE_DEFAULT){ url_params["ns_type"] = this.ns_type }

        let headers = Utils.get_auth_header()
        if(this.ns_key){ headers["ns_key"] = this.ns_key }

        return bus.$http.post(base_url + "/folder/batch", payload, { params: url_params, headers: headers })
    },

    rename_file: function(file_id, new_name){
        const payload = {
            "id": file_id,
            "name": new_name
        }

        let url_params = {}
        if(this.ns_type !== NamespaceService.NS_TYPE_DEFAULT){ url_params["ns_type"] = this.ns_type }

        let headers = Utils.get_auth_header()
        if(this.ns_key){ headers["ns_key"] = this.ns_key }

		return bus.$http.put(base_url + "/rename", payload, { params: url_params, headers: headers })
    },

    move_entity(id, target_folder_id, target_ns_type, target_ns_key){
        let payload = {
            "id": id,
            "target_id": target_folder_id
        }
        if(target_ns_type !== undefined){
            payload["target_ns_type"] = target_ns_type
        }
        if(target_ns_key !== undefined){
            payload["target_ns_key"] = target_ns_key
        }

        let url_params = {}
        if(this.ns_type !== NamespaceService.NS_TYPE_DEFAULT){ url_params["ns_type"] = this.ns_type }

        let headers = Utils.get_auth_header()
        if(this.ns_key){ headers["ns_key"] = this.ns_key }

        return bus.$http.put(base_url + "/move", payload, { params: url_params, headers: headers })
    },
    move_file(file_id, target_folder_id, target_ns_type, target_ns_key){
        return NamespaceService.move_entity(file_id, target_folder_id, target_ns_type, target_ns_key)
    },

    move_folder(folder_id, target_folder_id, target_ns_type, target_ns_key){
        return NamespaceService.move_entity(folder_id, target_folder_id, target_ns_type, target_ns_key)
    },

    copy_file: function(file_id, new_parent_id){

        let payload = {
            "id": file_id,
            "target_id": new_parent_id
        }

        let url_params = {}
        if(this.ns_type !== NamespaceService.NS_TYPE_DEFAULT){ url_params["ns_type"] = this.ns_type }

        let headers = Utils.get_auth_header()
        if(this.ns_key){ headers["ns_key"] = this.ns_key }

		return bus.$http.put(base_url + "/copy", payload, { params: url_params, headers: headers })
    },


    search: function(search_string){
        const url_params = {
            query: search_string
        }

        if(this.ns_type !== NamespaceService.NS_TYPE_DEFAULT){ url_params["ns_type"] = this.ns_type }

        let headers = Utils.get_auth_header()
        if(this.ns_key){ headers["ns_key"] = this.ns_key }

        return bus.$http.get(base_url + "/search", { params: url_params, headers: headers })
    }


}

export { NamespaceService }