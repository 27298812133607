<template>
<div class="layout-container" style="overflow: overlay" :class="{'bg-gradient-danger': error !== false, 'bg-gradient-info': error === false}">
<div class="page-container">

    <div class="container" :class="{'narrow': !share}">
        <div class="row">
            <div class="col-1"></div>
            <div class="col-12 col-lg-10">
                <div class="cardbox mt-5" id="main-box">
                    <div class="cardbox-heading">
                        <div class="cardbox-title" v-if="share && share.team_info">
                            <div class="d-flex justify-content-center align-items-center">
                                <div class="text-md">Your shared files from {{ share.team_info.name }}</div>
                                <img v-if="share.team_info.logo_url && share.team_info.logo_url != ''" :src="share.team_info.logo_url" style="max-height: 48px" class="ml-2">
                            </div>
                            
                            <div style="clear:both"></div>
                        </div>
                    </div>
                    <div class="cardbox-body" id="main-box-body">

                        <!-- Error message -->
                        <div v-if="error" class="text-center">
                            <div class="alert alert-danger" style="display:inline-block;min-width: 60%" v-html="error"></div>
                        </div>

                        <div v-if="share_loading">
                            <i class="ion-load-c icon-lg spin text-info"></i>
                        </div>

                        <!-- Password input -->
                        <div v-if="share_password_needed && !share" class="text-center" style="width: 450px; margin: 0px auto; display:grid; grid-template-columns: 140px 1fr">
                            <div>
                                <i class="ion-lock-combination lock-icon"></i>
                            </div>
                            <div class="text-left" style="display:flex;flex-direction:column; justify-content: center">
                                <div>
                                    <form v-on:submit.prevent="unlock_share()">
                                        <label for="share_password">Share password:</label>
                                        <div class="input-group">
                                            <input type="password" id="share_password" class="form-control" v-model.trim="share_password">
                                            <button type="submit" class="btn btn-gradient btn-success ml-2" :disabled="login_loading">Unlock <i v-if="login_loading" class="ion-load-c spin ml-1"></i></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <!-- Share contents -->
                        <div v-if="share">
                            <div class="share-header text-center" v-if="share.message && share.message != ''">
                                <p class="bl pl-3" style="display:inline-block"><i>{{ share.message }}</i></p>
                            </div>

                            <div class="share-contents">

                                <div v-if="nav_stack && nav_stack.length > 1" class="d-flex align-items-center justify-content-middle mb-3">
                                    <div class="d-inline-block" v-for="(folder, index) in nav_stack" :key="folder.id">
                                        <button class="btn btn-flat btn-info" type="button" @click="navigate_to(folder, false)">
                                            <i v-if="folder.is_root" class="icon ion-home"></i>
                                            <span v-else>{{folder.name}}</span>
                                        </button>
                                        <span  v-if="index < nav_stack.length-1">&gt;</span>
                                    </div>
                                </div>

                                <!-- Folders -->
                                <div class="row" v-if="folders && folders.length > 0 && !share.contents_loading">
                                    <div class="col-12 col-md-6 col-lg-4 folder-box" v-for="folder in folders" :key="folder.id" @click="navigate_to(folder, true)">
                                        <div class="cardbox" :class="[ 'color-' + folder.color + '-400' ]" style="border-left: 4px solid;">
                                            <div class="cardbox-body" :title="folder.name">
                                                <div class="d-flex justify-content-start align-items-center">
                                                    <i class="ion-folder mr-2" style="font-size: 25px; line-height: 25px"></i>
                                                    <span class="nowrap" style="overflow: hidden">{{ folder.name }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Files table -->
                                <div v-if="!share.contents_loading">
                                    <table class="table table-hover" v-if="share.contents.length > 0">
                                        <thead>
                                            <th style="width: 10px"></th>
                                            <th class="text-left">Filename</th>
                                            <th class="d-none d-md-table-cell">Type</th>
                                            <th class="d-none d-md-table-cell">Size</th>
                                            <th v-if="share.download_limit" class="d-none d-md-table-cell">Downloads</th>
                                            <th class="d-none d-lg-table-cell">Uploaded</th>
                                            <th></th>
                                        </thead>
                                        <template v-if="files && files.length > 0" >
                                        <tbody v-for="file in files" :key="file.id">
                                            <tr :class="{'text-gray-light': file.loading, 'text-muted': share.download_limit && file.downloads_num >= share.download_limit }">
                                                <td><img :src="file.extension | filetype_img_src" /></td>
                                                <td class="text-left text-bold table-cell cell-full cell-ellipsis">
                                                    <div v-if="share.download_limit && file.downloads_num >= share.download_limit" title="Cannot download this file any more, the download limit has been reached." class="c-not-allowed">
                                                        {{ file.name }} <i class="ion-alert-circled ml-1"></i>
                                                    </div>
                                                    <div v-else>
                                                        <a href="#" @click="download_file(file)" class="file_download_link">{{ file.name }}</a>
                                                        <span v-if="file.loading" class="text-muted ml-2">({{ file.loading.toFixed(1) }}%)</span>
                                                    </div>
                                                </td>
                                                <td class="nowrap text-gray-light text-sm d-none d-md-table-cell">
                                                    <span style="text-transform: uppercase">{{ file.extension }}</span>
                                                </td>
                                                <td class="nowrap text-gray-light text-sm d-none d-md-table-cell">
                                                    {{ file.size | size }}
                                                </td>
                                                <td v-if="share.download_limit" class="text-gray-light text-sm d-none d-md-table-cell text-center">
                                                    {{ file.downloads_num }} / {{ share.download_limit }}
                                                </td>
                                                <td class="nowrap text-gray-light text-sm d-none d-lg-table-cell">
                                                    <timestamp :timestamp="file.timestamp"></timestamp>
                                                </td>
                                                <td class="nowrap text-right">
                                                    <button v-if="file.cancel" @click="file.cancel()" class="btn text-sm btn-danger btn-flat" >CANCEL <i class="ion-close-round ml-1"></i></button>
                                                    <section v-else>
                                                        <button v-if="file.viewer" :disabled="share.download_limit && file.downloads_num >= share.download_limit" class="btn text-sm btn-flat" :class="[file.viewer.btn_class ]" @click.prevent="open_file(file)" :title="'Open with ' + file.viewer.label">{{ file.viewer.verb }} <i class="icon-lg ml-2" :class="file.viewer.icon_class"></i></button>
                                                        <button class="btn btn-info btn-gradient text-white ml-0" :disabled="share.download_limit && file.downloads_num >= share.download_limit" @click="download_file(file)"><i class="ion-arrow-down-a"></i><span class="ml-2">Download</span></button>
                                                    </section>
                                                </td>
                                            </tr>
                                            <tr class="file-loading" v-if="file.loading">
                                                <td colspan="7">
                                                    <div class="progress">
                                                        <div class="progress-bar progress-bar-striped progress-bar-animated"
                                                            role="progressbar" :aria-valuenow="Math.round(file.loading)" aria-valuemin="0" aria-valuemax="100" :style="{'width': Math.round(file.loading)+'%'}"></div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                        </template>
                                        <tbody v-if="files && files.length === 0">
                                            <tr>
                                                <td colspan="7" class="text-center">
                                                    <i>No files in this folder</i>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div v-else class="row">
                                        <div class="col-3"></div>
                                        <div class="col-6">
                                            <div class="cardbox text-white bg-gradient-warning b0">
                                                <div class="cardbox-body text-center">
                                                    <div class="text-bold display-3"><i class="ion-qr-scanner"></i></div>
                                                    <p class="text-bold text-md">Empty folder</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else>
                                    Loading... <i class="ion-load-c spin"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cardbox-footer d-flex align-items-center justify-content-between">
                        <div class="">
                            <locations
                                v-if="share && share.locations"
                                :locations="share.locations"
                                placement="top"
                                :team-name="share.team_info.name"
                                :circles="true"
                                :text-start="locations_text"></locations>
                        </div>
                        <a href="https://www.skyflok.com" target="_BLANK" rel="noopener"><img src="@/assets/img/skyflok_logo_black.png" height="32" /></a>
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>

<div class="modal" id="viewer-modal">
    <div class="modal-dialog modal-lg" style="max-width: 80%" v-if="view_file">
        <div class="modal-content">
            <div class="modal-header bg-gradient-primary text-white">
                <h5 class="mt-0 modal-title"><b>{{view_file.file.name}}</b></h5>
                <button class="close" type="button" @click="view_file = null; close_modal('viewer-modal')" aria-label="Close"><span>&times;</span></button>
            </div>
            <div class="modal-body">
                <file-preview
                    :file="view_file.file"
                    :file_list="view_file.file_list"
                    :file_downloaded_callback="(file) => { if(file.downloads_num !== undefined){ ++file.downloads_num } }"></file-preview>
            </div>
        </div>
    </div>
</div>

</div>
</template>

<script>

import { ShareService } from '@/services/share-service.js'
import { Utils } from '@/helpers/utils.js'
import { NamespaceService } from '@/services/namespace-service';
import { FileDownloader } from '@/helpers/file-downloader.js';
import filePreview from '@/components/file-viewer.vue'
import timestamp from '@/components/timestamp.vue'
import locations from '@/components/data-location-flags.vue'

export default {

    components: {
        filePreview,
        timestamp,
        locations
    },

    data(){
        return {
            error: false,
            login_loading: false,
            share_key: null,
            share_password: null,

            share_password_needed: false,

            share: null,

            view_file: null,

            list_sort: { attr: 'name', asc: true },

            nav_stack: null
        }
    },

    computed: {
        files(){
            return this.share && this.share.contents && this.share.contents.filter(en => { return en.entity_type === NamespaceService.ENTITY_TYPE_FILE })
        },

        folders(){
            return this.share && this.share.contents && this.share.contents.filter(en => { return en.entity_type === NamespaceService.ENTITY_TYPE_FOLDER })
        },

        locations_text(){
            if(!this.share){ return "" }
            if(this.share.folder){
                // Default text is good here "this shared folder..."
                return null
            }
            let beginning = this.share.contents.length > 1 ? "These shared files" : "This shared file"
            return beginning + " is encrypted and distributed in the following "+this.share.locations.length+" locations, selected by the administrators of "+this.share.team_info.name+":"
        }
    },

    created: function(){
        // Parse share key
        this.share_key = Utils.parse_url_param('key', false, false)
        if(!this.share_key || this.share_key.length == 0){
            this.error = "Share Key not found in the URL! Please copy & paste the full link that you received into the address bar above!";
            return
        }
        this.share_loading = true

        // Optimistic attempt to get share without password
        ShareService.get_share_by_key(this.share_key, null, true).then(res => {
            this.error = false
            this.share_loading = false
            this.share_received(res.body)
        }, err => {
            // Show error message
            this.parse_error(err)

            if(err.status === 401){
                // This is a password protected share, show "password needed" UI
                this.error = false
                this.share_password_needed = true
            }

            this.share_loading = false
        })
    },

    mounted(){
        document.title = "SkyFlok Share"

        // Delete the share password from local storage when the tab is closed
        var self = this
        window.onbeforeunload = function(){
            localStorage.removeItem('share-'+self.share_key)
        }
    },

    methods: {

        unlock_share: function(){
            this.share_loading = true
            this.login_loading = true
            ShareService.get_share_by_key(this.share_key, this.share_password, true).then(res => {
                this.error = false
                // Cache the share password so it can be injected by share-service
                localStorage['share-'+this.share_key] = this.share_password
                this.share_loading = false
                this.login_loading = false
                this.share_received(res.body)
            }).catch(err => {
                console.error(err);
                this.parse_error(err)
                this.login_loading = false
                this.share_loading = false
            })
        },

        share_received: function(share){
            // Reset error
            this.error = false;

            // Show error for empty shares
            if(!share.contents || (share.folder === undefined && share.contents.length == 0) ){
                this.error = "Every file has been deleted from this share. Please contact the person who sent you the share link!"
                return
            }

            // If folder share, start nav stack
            if(share.folder && !this.nav_stack){
                this.nav_stack = [{
                    name: 'Home',
                    is_root: true,
                    id: share.folder
                }]
            }

            this.share = share
            this.init_folder_contents()
        },

        init_folder_contents(){
            // Set extension and viewer of files
            this.share.contents
                .filter(e => { return e.entity_type === NamespaceService.ENTITY_TYPE_FILE })
                .forEach(file => {
                    file.extension = Utils.get_file_extension(file.name)
                    file.mime_type = Utils.get_mime_type(file.extension)
                    file.viewer = Utils.get_file_viewer(file)
                    this.$set(file, 'loading', false)
                });

            // Set folder color
            this.share.contents
                .filter(e => { return e.entity_type === NamespaceService.ENTITY_TYPE_FOLDER })
                .forEach(folder => {
                    folder.color = Utils.string_to_color(folder.name)
                });

            // Sort contents by name
            this.share.contents.sort( (_a,_b) => {
                let a = _a.name.toLowerCase();
                let b = _b.name.toLowerCase();
                if(a < b){ return -1; }
                if(a > b){ return 1 }
                return 0;
            })
        },

        navigate_to(folder, is_subfolder){

            // Maintain Nav Stack
            const i = this.nav_stack.findIndex(el => { return el.id === folder.id })
            if(i >= 0){
                // navigating up the stack
                this.nav_stack.splice(i+1)
            }
            else{
                // navigating down
                this.nav_stack.push({
                    name: folder.name,
                    id: folder.id
                })
            }

            this.$set(this.share, 'contents_loading', true)
            ShareService.get_subfolder_by_key(this.share_key, this.share_password, folder.id).then(res => {
                this.error = false
                this.share.contents_loading = false
                this.share.contents = res.body.contents
                this.init_folder_contents()

            }).catch(err => {
                console.error(err)
                this.share.contents_loading = false
                this.parse_error(err)
            })
        },

        open_file: function(file, version_id){
            if(this.disable_download){ return }
            this.view_file = {
                file: file,
                version_id: version_id,
                file_list: this.files.filter(f => { return f.viewer })
            }

            // Open modal
            $("#viewer-modal").modal('show')

            // Destroy file blob when the modal is hidden or closed
            var self = this;
            $("#viewer-modal").on('hidden.bs.modal', function (e) {
                self.view_file = null
            })
        },

        download_file(file){
            this.$set(file, 'loading', 0.1)

            var self = this;
            ShareService.download_file_by_key(file.id, this.share_key, this.share_password).then(res => {
                // Construct a 'version' object
                const version = {
                    size: res.body.size,
                    generations: res.body.generations
                }

                let downloader = new FileDownloader()
                this.$set(file, 'cancel', {})
                this.$set(file, 'is_mobile_download', Utils.is_mobile())
                downloader.downloadFile(file, null, version).then(res => {
                    file.loading = false
                    delete file.cancel

                    if(file.downloads_num !== undefined){
                        file.downloads_num++
                    }

                    if(res.download_url){
                        Utils.force_download_file(file, res.download_url, null, true, true)
                        return
                    }

                    if(file.open_when_ready){
                        // Open viewer
                        this.view_file = {
                            name: file.name,
                            viewer: file.viewer,
                            mime_type: res.mime_type,
                            blob_url: res.blob_url
                        }
                        $("#viewer-modal").modal('show')
                        $("#viewer-modal").on('hidden.bs.modal', function (e) {
                            // Destroy the blob when the modal is hidden/closed
                            if(this.view_file && this.view_file.blob_url){
                                this.view_file.blob_url = null
                            }
                        })
                        file.open_when_ready = false
                    }
                    else{
                        // Download
                        Utils.force_download_file(file, res.blob_url)
                    }
                }).catch(err => {
                    file.loading = false
                    delete file.cancel

                    if(err.cancelled){
                        // Download was cancelled
                        return
                    }
                    throw err
                })

            }).catch(err => {
                file.loading = false
                if('cancel' in file){
                    delete file.cancel
                }

                if(err.cancelled){
                    // Download was cancelled
                    return
                }

                // Download failed
                console.error("Failed to get info to download file:", err)
                this.parse_error(err)

                if(err.status && err.status === 404){
                    // Reload the share
                    ShareService.get_share_by_key(this.share_key, this.share_password, true).then(res => {
                        this.share_received(res.body)
                    })
                }
            })
        },

        close_modal: function(el_id){
            $("#"+el_id).modal("hide")
        },

        parse_error(err){
            if(!err || !err.status){
                this.error = "Share App error :("
            }
            if(err.status === 404){
                this.error = "We couldn't find the share you're looking for. Please copy & paste the full link that you received into the address bar above!"
            }
            else if(err.status === 403){
                this.error = "Sharing has been disabled for the Team who owns this Share."
            }
            else if(err.status === 410){
                this.error = "The share you're trying to access has expired."
            }
            else if(err.status === 401){
                this.error = "Incorrect password"
            }
            else if(err.status === 429){
                this.error = "This share is temporarily disabled because it generated too much traffic."
            }
            else{
                this.error = "Temporary server error, please try again later. Sorry for the inconvenience."
            }
        },

        sort_by: function(attr){
            if(attr === this.list_sort.attr){ this.list_sort.asc = !this.list_sort.asc }
            else{ this.list_sort.attr = attr; this.list_sort.asc = true }
        },

    },

    filters: {
        size: function(bytes){
            return Utils.format_bytes(bytes)
        }
    }

}
</script>

<style scoped>
.container{
    transition: max-width .3s ease-out
}
.container.narrow{
    max-width: 950px
}

@media(max-width: 992px){
    .cardbox#main-box{
        margin-top: 0px !important
    }
}

.share-contents{
    margin: auto 5%
}

@media(max-width: 768px){
    #main-box-body{
        padding: 8px
    }

    .share-contents{
        margin: auto 0px
    }

    .cardbox-title .text-md{
        font-size: 1.5rem !important
    }
}

.lock-icon{
    font-size: 100px;
    margin-right: 100px;
}

.filename{
    text-overflow: ellipsis

}
/*
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-break: break-all;
    word-wrap: break-word;
    overflow-wrap: break-word;
    */


.table { display:table; width:100%; border-collapse:collapse; box-sizing:border-box; }
.table-cell { display:table-cell; }

.cell-full { max-width:1px; width:100%; }
.cell-ellipsis { overflow:hidden; white-space:nowrap; text-overflow:ellipsis; }
.cell-noWrap { white-space:nowrap; }


.file_download_link:not(.disabled):hover{
    text-decoration: underline;
    cursor: pointer;
}
.file-download-icon{
    margin-left: 5px;
    color:transparent;
}
.file_download_link{
    color: #59676b
}
.file_download_link.disabled{
    color: #a2a2a2
}
.table thead .sort_header{ cursor: pointer; }
.table thead th{
    font-size: 12pt;
    text-transform: uppercase;
    border: 0px
}

tr.file-loading,
tr.file-loading td { padding: 0px; margin: 0px; border: 0px }
tr.file-loading td .progress{ padding: 0px; height: 3px }
tr.file-loading td .progress-bar { height: 3px; margin: 0px; transition: width .2s linear }


.folderbox{
    cursor: pointer
}

.folder-box{ cursor: pointer; transition: all .3s ease; }
.folder-box:hover .cardbox{ box-shadow: 0px 5px 25px 0px rgba(123, 123, 123, 0.35) }

.flex-fill {
    -ms-flex: 1 1 auto!important;
    flex: 1 1 auto!important;
}
</style>
