import { render, staticRenderFns } from "./archive-space.vue?vue&type=template&id=1d9a8b30&scoped=true"
import script from "./archive-space.vue?vue&type=script&lang=js"
export * from "./archive-space.vue?vue&type=script&lang=js"
import style0 from "./archive-space.vue?vue&type=style&index=0&id=1d9a8b30&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d9a8b30",
  null
  
)

export default component.exports