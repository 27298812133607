import { bus } from '../helpers/event_bus.js'
import { Utils } from '../helpers/utils.js'


// This is not exported
const base_url = process.env.VUE_APP_auth_service_url


const AuthService = {

    ROLE_TEAM_ADMIN: "OA",
    ROLE_TEAM_MEMBER: "EM",
    ROLE_OPERATOR: "OP",

    MFA_MODE_APP: 1,
    MFA_MODE_SMS: 2,


    login(credentials, device_id, os, client_name, client_version){

        const payload = new FormData()
        if(credentials.email){
            payload.append("email_address", credentials.email)
        }
        if(credentials.password){
            payload.append("password", credentials.password)
        }
        if(credentials.google_id_token){
            payload.append("google_id_token", credentials.google_id_token)
        }
        if(credentials.mfa_backup_code){
            payload.append("mfa_backup_code", credentials.mfa_backup_code)
        }
        if(credentials.mfa_code){
            payload.append("mfa_code", credentials.mfa_code)
        }
        
        payload.append("device_id", device_id)
        payload.append("client_os", os)
        payload.append("client_name", client_name)
        payload.append("client_version", client_version)

        return bus.$http.post(base_url + "/login", payload)
    },

    authenticate_token: function(token){
        const headers = {
            "Authorization": "Bearer " + token
        }
        return bus.$http.get(base_url + "/authenticate", { headers: headers })
    },

    logout: function(){
        return bus.$http.get(base_url + "/logout", { headers: Utils.get_auth_header() })
	},

    get_pending_registration(challenge){
        const url_params = {
            challenge: challenge
        }
        return bus.$http.get(base_url + "/get_pending_registration", { params: url_params })
    },

	confirm_primary_email: function(confirm_key){
		const url_params = {
			challenge: confirm_key
		}
		return bus.$http.get(base_url + "/confirm_registration", { params: url_params })
    },
    
    confirm_invited_registration(payload){
        
        return bus.$http.post(base_url + "/confirm_invited_registration", payload)
    },

    change_password: function(current_password, new_password){
        let payload = new FormData()
        payload.append("current_password", current_password)
        payload.append("new_password", new_password)


        return bus.$http.post(base_url + "/change_password", payload, { headers: Utils.get_auth_header() })
    },

    change_password_admin: function(user_id, password){
        // OA is allowed to change the password of any user under the company account
        let payload = new FormData()
        payload.append("user_id", user_id)
        payload.append("new_password", password)

        return bus.$http.post(base_url + "/change_password/admin", payload, { headers: Utils.get_auth_header() })
    },

    resend_confirmation_email(user_id, user_name=null){
        let payload = new FormData()
        payload.append("user_id", user_id)
        if(user_name){ payload.append("user_name", user_name) }

        return bus.$http.post(base_url + "/resend_confirmation_email", payload, { headers: Utils.get_auth_header() })
    },

    get_active_sessions: function(){
        return bus.$http.get(base_url + "/session/list", { headers: Utils.get_auth_header() })
    },

    kill_session: function(session_id){
        let payload = new FormData()
        payload.append("session_id", session_id)

        return bus.$http.put(base_url + "/session/invalidate", payload, { headers: Utils.get_auth_header() })
    },

    get_user_roles_batch: function(user_ids){
        return bus.$http.get(base_url + "/roles_of_users?ids="+user_ids.join(","), { headers: Utils.get_auth_header() })
    },

    suspend_user: function(user_id, new_value){
        let payload = new FormData()
        payload.append("user_id", user_id)
        payload.append("new_value", new_value)
        return bus.$http.post(base_url + "/user/is_suspended", payload, { headers: Utils.get_auth_header() })
    },

    grant_role: function(user_id, role_code){
        let payload = new FormData()
        payload.append("user_id", user_id)
        payload.append("role", role_code)
        return bus.$http.post(base_url + "/grant_role", payload, { headers: Utils.get_auth_header() })
    },

    revoke_role: function(user_id, role_code){
        let url_params = {
            user_id: user_id,
            role: role_code
        }
        return bus.$http.delete(base_url + "/revoke_role", { params: url_params, headers: Utils.get_auth_header() })
    },

    delete_user: function(user_id){
        return bus.$http.delete(base_url + "/user?user_id="+user_id, { headers: Utils.get_auth_header() })
    },

    get_ip_location(){
        return bus.$http.get(base_url + "/ip_location", { headers: Utils.get_auth_header() })
    },

    get_2fa_status(){
        return bus.$http.get(base_url + "/2fa/status", { headers: Utils.get_auth_header() })
    },

    setup_2fa(config){
        return bus.$http.post(base_url + "/2fa/setup", config, { headers: Utils.get_auth_header() })
    },

    verify_2fa_code(code){
        const payload = {
            code: code
        }
        return bus.$http.post(base_url + "/2fa/verify_code", payload, { headers: Utils.get_auth_header() })
    },

    get_2fa_url(){
        return bus.$http.get(base_url + "/2fa/get_app_url", { headers: Utils.get_auth_header() })
    },

    get_2fa_recovery_codes(){
        return bus.$http.get(base_url + "/2fa/get_recovery_codes", { headers: Utils.get_auth_header() })
    },
    
    generate_2fa_recovery_codes(){
        return bus.$http.get(base_url + "/2fa/generate_recovery_codes", { headers: Utils.get_auth_header() })
    },

    disable_2fa(password){
        const payload = {
            password: password,
            chaos: Utils.random_string(Utils.random_between(10, 30))
        }

        return bus.$http.post(base_url + "/2fa/delete", payload, { headers: Utils.get_auth_header() })
    },


    
}


export { AuthService }